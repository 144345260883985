import React, { useState, useEffect } from 'react'
import CarouselAlbums from '../../components/carouselAlbums'
import Title from '../../components/title'
import { albums } from '../../data/albums'
import AmirDesktop from '../../assets/img/amir-desktop.png'
import AmirMobile from '../../assets/img/amir-mobile.png'
import './index.scss'
import Header from '../../components/header'
import { sendData } from '../../utils'
import Footer from '../../components/footer'
import LogoAmir from '../../assets/img/mainLogo.png'
import { Helmet } from 'react-helmet'

const Homepage = ({ setIsOpenMenu }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    useEffect(() => {
        const objectDigitalData = {				
            settings:{			
                reportSuites:"wmg,wmgfr"		
            },			
            page:{			
                pageInfo:{		
                    pageName:"Amir:Music",	
                    server:"Amir:Site",	
                    platform:"Static HTML",	
                    devTeam:"Warner Music France"	
                },		
                category:{		
                    primaryCategory:"Amir:Music",	
                    pageType:"music:landing"	
                }		
            },			
            content:{			
                artist:"Amir",		
                label:"Warner Music International",		
                sublabel:"Warner Music France"		
            }			
        }						
        sendData(objectDigitalData)
    }, [])

    return (
        <main className="homepage">
            <Helmet>
                <title>Amir-Paroles</title>
            </Helmet>
            <Header setIsOpenMenu={setIsOpenMenu} />
            <Title text={`<img src='${LogoAmir}' alt='' /> <span>paroles</span>`} />

            <div className="bottomHomepage">
                <picture>
                    <source srcSet={AmirDesktop} media="(min-width: 1024px)" />
                    <img  className="mainVisuel" src={AmirMobile} alt="" />
                </picture>

                <CarouselAlbums albums={albums} />
                <Footer />
            </div>
        </main>
    )
}

export default Homepage