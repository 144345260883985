import React, { useState } from 'react'
import Homepage from './pages/Homepage';
import { Switch, Route } from 'react-router';
import Track from './pages/Track';
import Album from './pages/Album';
import './style/main.scss';
import Menu from './components/menu';
import Footer from './components/footer';

const App = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  return (
    <div className={`App ${isOpenMenu ? 'noscroll' : ''}`}>
      <Menu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
      <Switch>
        <Route path="/album/:id">
          <Album setIsOpenMenu={setIsOpenMenu} />
        </Route>
        <Route path="/track/:id">
          <Track setIsOpenMenu={setIsOpenMenu} />
        </Route>
        <Route path="/">
          <Homepage setIsOpenMenu={setIsOpenMenu} />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
