export const albums = [
    {
        id: "r3ssources",
        title: "R3ssources",
        subtitle: "2022",
        slug: "r3ssources",
        pochette: "/assets/pochettes/r3ssources.jpg",
        tracklist: [{
            id: 1,
            title: "J'ai cherché",
            slug: "j-ai-cherche-r3ssources"
        },{
            id: 2,
            title: "Sors de ma tête",
            slug: "sors-de-ma-tete-r3ssources"
        },{
            id: 3,
            title: "Les rues de ma peine",
            slug: "les-rues-de-ma-peine-r3ssources"
        },{
            id: 4,
            title: "À ta manière",
            slug: "a-ta-maniere-r3ssources"
        },{
            id: 5,
            title: "Carrousel",
            slug: "carrousel-r3ssources"
        },{
            id: 6,
            title: "Au coeur de moi",
            slug: "au-coeur-de-moi-r3ssources"
        },{
            id: 7,
            title: "À l'envers",
            slug: "a-l-envers-r3ssources"
        },{
            id: 8,
            title: "Lune",
            slug: "lune-r3ssources"
        }]
    },
    {
        id: "ressources-reedition",
        title: "Ressources (nouvelle édition)",
        subtitle: "2021",
        slug: "ressources-reedition",
        pochette: "/assets/pochettes/ressources-reedition.png",
        tracklist: [{
            id: 1,
            title: "Rétine",
            slug: "retine"
        },{
            id: 2,
            title: "Ce soir",
            slug: "ce-soir"
        },{
            id: 3,
            title: "1 + 1 (Ft Sia)",
            slug: "1-plus-1"
        },{
            id: 4,
            title: "Malgré moi",
            slug: "malgre-moi"
        },{
            id: 5,
            title: "Mec normal",
            slug: "mec-normal"
        },{
            id: 6,
            title: "Si tu rentrais ",
            slug: "si-tu-rentrais"
        },{
            id: 7,
            title: "Oubliée",
            slug: "oubliee"
        }]
    },
    {
        id: "ressources-standard",
        title: "Ressources",
        subtitle: "Version Standard (2020)",
        slug: "ressources-standard",
        pochette: "/assets/pochettes/ressources.jpeg",
        tracklist: [{
            id: 1,
            title: "Soi-disant",
            slug: "soi-disant"
        },
        {
            id: 2,
            title: "Toi",
            slug: "toi"
        },
        {
            id: 3,
            title: "La fête",
            slug: "la-fete"
        },
        {
            id: 4,
            title: "Carrousel <span>(Ft. Indila)</span>",
            slug: "carrousel"
        },
        {
            id: 5,
            title: "On verra bien",
            slug: "on-verra-bien"
        },
        {
            id: 6,
            title: "À l'envers",
            slug: "a-l-envers"
        },
        {
            id: 7,
            title: "Ma lumière",
            slug: "ma-lumiere"
        },
        {
            id: 8,
            title: "Douce guerrière",
            slug: "douce-guerriere"
        },
        {
            id: 9,
            title: "Pardonnez-moi",
            slug: "pardonnez-moi"
        },
        {
            id: 10,
            title: "Entre gifle et caresse",
            slug: "entre-gifle-et-caresse"
        },
        {
            id: 11,
            title: "Passer <span>(Ft. The Idan Raichel Project)</span>",
            slug: "passer"
        },
        {
            id: 12,
            title: "Le C",
            slug: "le-c"
        },
        {
            id: 13,
            title: "Daphné",
            slug: "daphne"
        },
        {
            id: 14,
            title: "Paradis",
            slug: "paradis"
        },
        {
            id: 15,
            title: "Comme il faut <span>(Ft. Céphaz)</span>",
            slug: "comme-il-faut"
        },
        {
            id: 16,
            title: "Fous <span>(Ft. 7 Jaws & Bambino)</span>",
            slug: "fous"
        },
        {
            id: 17,
            title: "Cet homme",
            slug: "cet-homme"
        },
        {
            id: 18,
            title: "À la maison",
            slug: "a-la-maison"
        }]
    },
    {
        id: "addictions-reedition",
        title: "Addictions (Deluxe)",
        subtitle: "2017",
        slug: "addictions-reedition",
        pochette: "/assets/pochettes/addictions-reedition.png",
        tracklist: [
            {
                id: 1,
                title: "Longtemps",
                slug: "longtemps"
            },
            {
                id: 2,
                title: "Smile",
                slug: "smile"
            },
            {
                id: 3,
                title: "French Kiss",
                slug: "french-kiss"
            },
            {
                id: 4,
                title: "Parfait déséquilibre",
                slug: "parfait-desequilibre"
            },
            {
                id: 5,
                title: "5 minutes avec toi",
                slug: "cinq-minutes-avec-toi"
            },
            {
                id: 6,
                title: "Imagine",
                slug: "imagine"
            },
            {
                id: 7,
                title: "Réflexions",
                slug: "reflexions"
            },
            {
                id: 8,
                title: "Autour de moi",
                slug: "autour-de-moi"
            },
            {
                id: 9,
                title: "Qu'est-ce qu'on gardera",
                slug: "qu-est-ce-qu-on-gardera"
            },
            {
                id: 10,
                title: "Lune",
                slug: "lune"
            },
            {
                id: 11,
                title: "Sal de Mi Mente",
                slug: "sal-de-mi-mente"
            },
            {
                id: 12,
                title: "Si t'as mal",
                slug: "si-t-as-mal"
            },
            {
                id: 13,
                title: "Higher",
                slug: "higher"
            },
            {
                id: 14,
                title: "Imagine... The Answer",
                slug: "imagine-the-answer"
            },
            {
                id: 15,
                title: "100 Years <span>(titre bonus)</span>",
                slug: "100-years"
            }]
    },  
    {
        id: "addictions-standard",
        title: "Addictions",
        subtitle: "Edition collector (2017)",
        slug: "addictions-standard",
        pochette: "/assets/pochettes/addictions-standard.jpeg",
        tracklist: [
            {
                id: 1,
                title: "Que seront les hommes&nbsp;?",
                slug: "que-seront-les-hommes"
            },
            {
                id: 2,
                title: "États d'amour",
                slug: "etats-damour"
            },
            {
                id: 3,
                title: "Tout passe",
                slug: "tout-passe"
            },
            {
                id: 4,
                title: "Les Rues de ma peine",
                slug: "les-rues-de-ma-peine"
            },
            {
                id: 5,
                title: "Il était une femme",
                slug: "il-etait-une-femme"
            },
            {
                id: 6,
                title: "Le Cœur dans les cordes",
                slug: "le-coeur-dans-les-cordes"
            },
            {
                id: 7,
                title: "Sors de ma tête",
                slug: "sors-de-ma-tete"
            },
            {
                id: 8,
                title: "L'Amourant",
                slug: "l-amourant"
            },
            {
                id: 9,
                title: "Opium",
                slug: "opium"
            },
            {
                id: 10,
                title: "Que le temps s'arrête",
                slug: "que-le-temps-s-arrete"
            },
            {
                id: 11,
                title: "Idéale idylle <span>(avec Lital)</span>",
                slug: "ideale-idylle"
            },
            {
                id: 12,
                title: "Laisse la vie faire",
                slug: "laisse-la-vie-faire"
            },
            {
                id: 13,
                title: "No Vacancy <span>(ft. OneRepublic)</span>",
                slug: "no-vacancy"
            },
            {
                id: 14,
                title: "La Nuit",
                slug: "la-nuit"
            },
            {
                id: 15,
                title: "Anja",
                slug: "anja"
            },
            {
                id: 16,
                title: "Et toi <span>(titre bonus)</span>",
                slug: "et-toi"
            },
            {
                id: 17,
                title: "L'Impasse <span>(titre bonus)</span>",
                slug: "l-impasse"
            },
            {
                id: 18,
                title: "Boréale aurore <span>(titre bonus)</span>",
                slug: "boreale-aurore"
            },
        ]
    },{
        id: "au-coeur-de-moi-deluxe",
        title: "Au coeur de moi (Deluxe)",
        subtitle: "2016",
        slug: "au-coeur-de-moi-deluxe",
        pochette: "/assets/pochettes/au-coeur-de-moi-deluxe.jpeg",
        tracklist: [
            {
                id: 1,
                title: "Yo busque",
                slug: "yo-busque"
            },
            {
                id: 2,
                title: "Looking for you",
                slug: "looking-for-you"
            }
        ]
    },
    {
        id: "au-coeur-de-moi-standard",
        title: "Au coeur de moi",
        subtitle: "2016",
        slug: "au-coeur-de-moi-standard",
        pochette: "/assets/pochettes/au-coeur-de-moi-standard.jpeg",
        tracklist: [
            {
                id: 1,
                title: "J'ai cherché",
                slug: "j-ai-cherche"
            },
            {
                id: 2,
                title: "On dirait",
                slug: "on-dirait"
            },
            {
                id: 3,
                title: "Au cœur de moi",
                slug: "au-coeur-de-moi"
            },
            {
                id: 4,
                title: "Ma vie, ma ville, mon monde",
                slug: "ma-vie-ma-ville-mon-monde"
            },
            {
                id: 5,
                title: "À ta manière",
                slug: "a-ta-maniere"
            },
            {
                id: 6,
                title: "I Know",
                slug: "i-know"
            },
            {
                id: 7,
                title: "Très haut",
                slug: "tres-haut"
            },
            {
                id: 8,
                title: "Je reviendrai",
                slug: "je-reviendrai"
            },
            {
                id: 9,
                title: "Lost",
                slug: "lost"
            },
            {
                id: 10,
                title: "Oasis",
                slug: "oasis"
            },
            {
                id: 11,
                title: "Broken Heart",
                slug: "broken-heart"
            },
            {
                id: 12,
                title: "Il est temps qu'on m'aime",
                slug: "il-est-temps-qu-on-m-aime"
            }
        ]
    }
]

export const tracks = [
    {
        id: "retine",
        ytId: "2KmtSK6HwEw",
        title: "Rétine",
        slug: "retine",
        album: "ressources-reedition",
        lyric: `
            <p>C’est parti de rien / À peine un regard échangé / On était deux, deux étrangers / Qui se connaissaient bien  </p>
            <p>C’est parti de loin / Je n’voulais pas me dévoiler  / Mais ma pudeur tu l’as volée / Le ciel en est témoin </p>
            <p>Combien de jours / Combien de joies / C’est pas très grave si on ne sait pas / Puisqu’une seconde à tes côtés vaut bien des années </p>
            <p>Combien de jours / Combien de gens / Diront qu’on s’aime obstinément / Mais ça m’amuse, ma muse on n’est pas près de faner </p>
            <p>Si dans ta rétine l’amour / Ne supporte plus la lumière du jour / Je rallumerai les étoiles autour / J’apprendrai à compter jusqu’à toujours / Et toi, tu pourras compter sur moi </p>
            <p>Et ça finira jamais, ça finira jamais, ça finira jamais / Et ça finira jamais, ça finira jamais, ça finira jamais </p>
            <p>Je sais qu’on se va bien  / Comme lèvres douces et peau salée  / Comme quand nos corps se laissent aller  / L’océan est témoin  </p>
            <p>J’aime tes manies  / Notre magie / Ta façon d’être mon amie / J’suis pas superstitieux mais t’es la chance de ma vie </p>
            <p>Et si demain / On n’avait plus rien  / Je te dirais pas Adieu mais merci  / D’avoir laissé dans mes tempêtes une belle éclaircie  </p>
            <p>Si dans ta rétine l’amour  / Ne supporte plus la lumière du jour  / Je rallumerai les étoiles autour  / J’apprendrai à compter jusqu’à toujours  / Et toi, tu pourras compter sur moi </p>
            <p>Et ça finira jamais, ça finira jamais, ça finira jamais / Et ça finira jamais, ça finira jamais, ça finira jamais </p>
            <p>Si dans ta rétine l’amour  / Ne supporte plus la lumière du jour  / Je rallumerai les étoiles autour  / J’apprendrai à compter jusqu’à toujours  / Et toi, tu pourras compter sur moi </p>
        `,
        credits: `
            Auteurs : Amir Haddad - Nazim Khaled <br />
            Compositeurs : Amir Haddad - Nazim Khaled – Stav Beger <br />
            Éditions : Bukowski publishing - Mikkee - JessEE - VictorEE - D.R
        `
    },{
        id: "ce-soir",
        title: "Ce soir",
        slug: "ce-soir",
        album: "ressources-reedition",
        lyric: `
            <p>Viens on perd la tête ce soir / Ça vaut la peine juste pour voir / Qu’on donne à la vie des pourboires / Pour qu’elle nous serve un peu d’espoir </p>
            <p>Viens on perd la tête ce soir / On la garde pas sur les épaules / Mais dans les étoiles quelque part / Avec vue sur une vie plus drôle </p>
            <p>On pourrait donner tort à la mélancolie / Pourrait donner raison à nos douces folies / Ta peau et ma peau c’est déjà l’harmonie / J’aime autant ton âme que ton anatomie </p>
            <p>Viens on perd la tête ce soir / On met des soleils dans nos yeux / Parce qu’hier est déjà trop vieux / Parce que demain est illusoire </p>
            <p>Viens on perd la tête ce soir / Ce soir on vit la vie en mieux / Parce qu’hier est déjà trop vieux / Parce que demain est illusoire </p>
            <p>Viens on perd la tête ce soir / On la retrouvera bien assez tôt / On fait tomber tous nos remparts / On part avec un sac à dos </p>
            <p>Dis-moi si t’es prête ce soir / Le corps et le cœur ex-aequo / On oublie que le ciel est beau / A trop regarder les trottoirs </p>
            <p>On pourrait donner tort à la mélancolie / Symphonie des corps, chante jolie folie / Ta peau et ma peau c’est déjà l’harmonie / J’aime autant ton âme que ton anatomie </p>
            <p>Viens on perd la tête ce soir / Viens on s’entête ce soir / Viens on ferme les yeux / Viens on ferme les yeux juste pour voir </p>
            <p>Juste pour voir </p>
            <p>Viens on perd la tête ce soir / On met des soleils dans nos yeux / Parce qu’hier est déjà trop vieux / Parce que demain est illusoire </p>
            <p>Viens on perd la tête ce soir / Ce soir on vit la vie en mieux / Parce qu’hier est déjà trop vieux / Parce que demain est illusoire </p>
            <p>Viens on perd la tête ce soir / On met des soleils dans nos yeux / Parce qu’hier est déjà trop vieux / Parce que demain est illusoire </p>
            <p>Viens on perd la tête ce soir / Ce soir on vit la vie en mieux / Parce qu’hier est déjà trop vieux / Parce que demain est illusoire </p>
        `,
        credits: `
            Auteurs : Amir Haddad - Nazim Khaled <br />
            Compositeurs : Amir Haddad - Nazim Khaled – Stav Beger <br />
            Éditions : Bukowski publishing - Mikkee - JessEE - VictorEE - D.R
        `
    },{
        id: "1-plus-1",
        title: "1 + 1 (Ft Sia)",
        ytId: "617jUxf-yPE",
        slug: "1-plus-1",
        album: "ressources-reedition",
        lyric: `
            <p>Get a girl, or get a boy / 1+1 / Take ‘em right down to the shore / 1+1 / Give your all and give some more / 1+1 / Let the music free your soul / 1+1 </p>
            <p>Sway with me to the light / Hold me for just one night / Hold me for just one night (woah) / We live for the beat / Baby you’re all I need / Baby you’re all I need (ohh) </p>
            <p>Tous les sons qui vont éclore/ 1+1 / De nos âmes multicolores/ 1+1 / Face à l’ironie du sort / Sans effort / Pourraient nous mettre d’accord / 1+1 </p>
            <p>Tu peux danser comme tu veux / Sous le soleil quand il pleut / Sous le soleil quand il pleut / C’est vrai si t’imagines</p>
            <p>Every day’s a holiday / Let the beat take you away / Let the beat take you today / Baby, this is magic </p>
            <p>Si à deux on est plus fort / 1 + 1 / Et si nos yeux brillent encore / 1 + 1 / Je crois bien que j’veux ta voix / 1 + 1 / Je suis sûr que j’veux t’avoir / 1 + 1</p>
            <p>Toutes les coordonnées / De mon cœur sont données / De l’amour sont données / La nuit va s’en aller / Me laisse pas m’en aller / Me laisse pas m’en aller</p>
            <p>So I’m taking you away  / 1+1  / Every day’s a holiday  / 1+1  / Move your body, feel the sway  / Grab someone  / Every day’s a holiday  / 1+1 </p>
            <p>Every day’s a holiday / Let the beat take you away / Let the beat take you today / Baby, this is magic </p>
            <p>Tu peux danser comme tu veux / Sous le soleil quand il pleut / Sous le soleil quand il pleut / C’est vrai si t’imagines</p>
            <p>Every day's a holiday / 1 + 1 / Let the beat take you today / 1 + 1</p>
            <p>Tous les sons qui vont éclore / 1+1  / De nos âmes multicolores / 1+1 / Face à l’ironie du sort / Sans effort / Pourraient nous mettre d’accord / 1+1 </p>
            <p>Tu peux danser comme tu veux / Sous le soleil quand il pleut / Sous le soleil quand il pleut / C’est vrai si t’imagines</p>
            <p>Tu peux danser comme tu veux / Sous le soleil quand il pleut / Sous le soleil quand il pleut / C’est vrai si t’imagines </p>
        `,
        credits: `
            Remixed by Banx & Ranx<br />
            Written by Sia Furler, Jesse Shatkin, Amir Haddad, Zacharie Raymond, Yannick Rastogi, Nazim Khaled <br />
            Published by Pineapple Lasagne - Sony/ATV Music Publishing Ltd. (ASCAP), EMI April Music Inc. obo itself and Aidenjulius Music (ASCAP), Mikkee, VictorEE, Bukowski Publishing
        `
    },{
        id: "malgre-moi",
        title: "Malgré moi",
        slug: "malgre-moi",
        album: "ressources-reedition",
        lyric: `
            <p>Rien dans la discorde ne m’attire mais je marche le poing levé  / Forcé à comprendre que ma place n’est pas réservée  / Même quand la corde raidit je ne cesse de rêver  / Il y a peu de choses que j’m’interdis, sauf tomber, c’est vrai </p>
            <p>Et malgré moi / Je brandis le drapeau dans la nuit / Et malgré moi, / J’assimile mes amis, mes ennemis / Et malgré moi, / J’vais pas mentir, j’ai le cœur qui blêmit  / Et malgré moi  / Malgré moi </p>
            <p>Qu’est-ce qui nous divise ?  / Je me demande au cœur de la mêlée  / Nos bras se défient mais nos mains ne forment qu’un souhait  / J’vous le confie on est tous fait d’amour en vérité  / Il est temps qu’on se le dise, qu’on se le dise, qu’on se le dise </p>
            <p>Et malgré moi / Je brandis le drapeau dans la nuit / Et malgré moi, / J’assimile mes amis, mes ennemis / Et malgré moi, / J’vais pas mentir, j’ai le cœur qui blêmit  / Et malgré moi  / Malgré moi </p>
            <p>Malgré moi, malgré moi, / Malgré moi, malgré moi, </p>
            <p>Malgré moi, / J’vais pas mentir, j’ai le cœur qui blêmit  / Et malgré moi  / Malgré moi </p>
        `,
        credits: `
            Auteur :  Amir Haddad <br />
            Compositeurs : Amir Haddad - Stav Beger  <br />
            Editions : Mikkee - JessEE - VictorEE - D.R
        `
    },{
        id: "mec-normal",
        title: "Mec normal (Session acoustique)",
        slug: "mec-normal",
        ytId: "ng9N9Xtldzs",
        album: "ressources-reedition",
        lyric: `
            <p>Depuis tout petit j’veux être original / J’suis ni beau ni moche ni petit ni grand et ça me gêne / Pas tout à fait bête, même pas demi génial / J’ai pas les moyens de m’échapper de la moyenne / Sur Google Images, si tu tapes mec normal / La première photo devine quoi bah c’est la mienne / J’étais assez fier qu’on me rende hommage / La banalité, j’excelle enfin dans un domaine </p>
            <p>J’ai des tonnes d’idées moi aussi / J’ai envie de faire bouger le monde / Figé comme une statue de cire / J’veux plus qu’on me réponde </p>
            <p>Ça c’est déjà fait ohhh / Ça c’est déjà fait ohhh / Ça c’est déjà fait ohhh / Tout a été fait </p>
            <p>Ça c’est déjà fait ohhh / Ça c’est déjà fait ohhh / T’aimer pour de vrai, / T’aimer comme je t’aime, / On l’a jamais fait </p>
            <p>J’suis pas un mec cool et pourtant j’essaye / J’suis le gars sympa qu’on écoute à peine d’une oreille / Si j’existais pas j’crois que ce serait pareil / Dans le dictionnaire j’suis le contraire d’exceptionnel </p>
            <p>Plus peur de vivre avec des si / Plus peur de m’jeter dans le vide / Ma vie d’avant je la déchire / J’vais suivre mes désirs puisque </p>
            <p>Ça s’est déjà fait ohhh / Ça s’est déjà fait ohhh / Ça s’est déjà fait ohhh / Tout a été fait </p>
            <p>Ça s’est déjà fait ohhh / Ça s’est déjà fait ohhh / T’aimer pour de vrai, / T’aimer comme je t’aime, / On l’a jamais fait </p>
            <p>Depuis que je te tiens la main, / On dirait bien que je suis quelqu’un, / Ça a l’air fou mais maintenant, / J’crois qu’j’me me fous du regard des gens </p>
            <p>Je l’ai déjà fait ohhh / J’pourrais le refaire ohhh / Jamais me défaire ohhh / De tes bras parfaits </p>
            <p>Ça c’est déjà fait ohhh / J’pourrais le refaire ohhh / Même si j’ai rien fait, t’aimer pour de vrai, / Au moins je l’aurai fait </p>
        `,
        credits: `
            Auteurs : Amir Haddad - Nazim Khaled <br />
            Compositeurs : Amir Haddad - Nazim Khaled <br />
            Editions : Bukowski publishing - Mikkee - JessEE - VictorEE 
        `
    },{
        id: "si-tu-rentrais",
        title: "Si tu rentrais",
        slug: "si-tu-rentrais",
        album: "ressources-reedition",
        lyric: `
            <p>Si tu rentrais / Tu verrais la table / Où elle a posé / Ton plat préféré</p>
            <p>Tu verrais ses bras / Qui s’ouvrent pour toi / « Mon enfant te voilà ! »</p>
            <p>Même si / Du bout de ses doigts / Elle touche en tremblant / Les photos d’avant</p>
            <p>Oui si tu rentrais / Tu verrais ta place / Et puis elle qui t’attend</p>
            <p>Comment, mon Dieu / Éteins-tu la lune, quand elle brille de mille feux / Et elle, quand la vie l’appelle / Ne veut rien savoir, ni oubli ni mémoire / Elle ne pense qu’à toi, toi tout seul dans le froid / Elle ne pense qu’à ça : te prendre dans ses bras</p>
            <p>Si tu rentrais / Tu verrais la chambre / Où tu as grandi / Elle a fait ton lit</p>
            <p>Tu entendrais sa voix / Qui prie ton retour / « Viens mon fils, mon amour »</p>
            <p>Et si tu rentrais / Tu pourrais alors / Tout lui raconter / Ta vie pour de vrai</p>
            <p>Il y aurait quelqu’un / Pour lui dire enfin / « Maman, s’il te plaît »</p>
            <p>Comment, mon Dieu / Éteins-tu la lune, quand elle brille de mille feux / Et elle, quand la vie l’appelle / Ne veut rien savoir, ni oubli ni mémoire / Elle ne pense qu’à toi, toi tout seul dans le froid / Elle ne pense qu’à ça : te prendre dans ses bras</p>
            <p>Comment, mon Dieu / Éteins-tu la lune, quand elle brille de mille feux / Et elle, les yeux mouillés de sel / T’attend chaque soir, elle voudrait bien savoir / Comment fais-tu là-bas, d’où l’on ne revient pas / Elle ne pense qu’à ça : toi tout seul dans le froid</p> 
        `,
        credits: `
            Auteurs :  Amir Haddad - Carla Bruni - Igit <br />
            Compositeur : Assaf Tzrouya<br />
            Editions : Mikkee - JessEE - VictorEE - Rossinante - Sony Music Publishing - Les Editions Teorema
        `
    },{
        id: "oubliee",
        title: "Oubliée",
        slug: "oubliee",
        album: "ressources-reedition",
        lyric: `
            <p>Tu as recouvert mes nuits / Moi je découvrais tes failles  / Tour à tour on s’efface / Pour peu qu’on ne se fasse violence  / J’n’avais pas le souci du détail / J’te disais où que t’ailles j’avance </p>
            <p>Pas à pas sans avertir / La routine s’installe / Les remarques font mal  / Ça devenait frontal / Je l’ai, encaissé un peu comme en boxe thaï / T’es sortie pour ne plus rentrer</p>
            <p>Ouais fallait qu’on s’en parle / Avec le sourire c’en est presque marrant  / J’l’ai pas assez dit  / Mais je te voyais maman  / J’te voyais maman</p>
            <p>On a fait l’effort de s’oublier  / On a mis les formes pour s’oublier  / J’suis allé si loin  / Pour te reconquérir  / Maintenant que tu reviens  / A mon tour de te dire  / Souviens-toi qu’on devait s’oublier</p>
            <p>Oubliée, oubliée, oubliée / Oubliée, oubliée, oubliée / Souviens-toi qu’on devait s’oublier / Oubliée, oubliée, oubliée </p>
            <p>On s’est laissé tomber  / Tomber comme des feuilles en automne  / Quand le cœur démissionne  / C’est la faute de personne  / C’est vrai qu’on s’est pas quitté de la bonne manière   / Mais revenir en arrière m’effraie</p>
            <p>Ouais j’ai mis mon costard  / Dernier rendez-vous avec ma vie d’avant  / Je te l’ai déjà dit mais je le redis maintenant  / Je te voyais maman</p>
            <p>On a fait l’effort de s’oublier / On a mis les formes pour s’oublier / J’suis allé si loin / Pour te reconquérir / Maintenant que tu reviens / A mon tour de te dire / Souviens-toi qu’on devait s’oublier</p>
            <p>Oubliée, oubliée, oubliée / Oubliée, oubliée, oubliée / Souviens-toi qu’on devait s’oublier / Oubliée, oubliée, oubliée</p>
        `,
        credits: `
            Auteur: Amir Haddad - Nazim Khaled<br />
            Compositeurs: Amir Haddad - Stav Beger - Nathan Goshen<br />
            Editions: Bukowski publishing - Mikkee - JessEE - VictorEE - D.R
        `
    },
    {
        id: "soi-disant",
        title: "Soi-disant",
        slug: "soi-disant",
        album: "ressources-standard",
        lyric: `
            <p>Soi-disant le monde est stone / Soi-disant il a la fièvre / Soi-disant y’a plus d’icônes / Y’a juste des gens célèbres / Soi-disant y’a plus de niveau / Y’a que des mauvais élèves / Soi-disant y’a trop d’infos / On nous impose nos rêves</p>
            <p>Imagine si l’mec du 20h / Arrêtait d’suivre le prompteur / Si on arrêtait d’écouter la voix qui nous berce du chant de la rumeur</p>
            <p>Nanananananana</p>
            <p>La seule évidence c’est / Que ça faut s’en balancer / Comme à la fin on n’sait / Même plus qui a commencé / Si le mensonge avance / On ira dans l’autre sens et / Tous ces mots balancés / On a qu’à les faire danse</p>
            <p>Soi-disant j’ai pas d’avis / Soi-disant je suis tout lisse / Soi-disant j’suis pas gentil / Quand j’suis derrière les coulisses / Soi-disant dans 50 ans / Y’aura des voitures qui volent / Mais elles voleront pas longtemps / Car y’aura plus de pétrole</p>
            <p>Il parait qu’les ordinateurs / Remplaceront bientôt les chanteurs / J’ai entendu dire que Kylie Jenner et ses sœurs achetaient tous leurs followers</p>
            <p>Nanananananana  </p>
            <p>La seule évidence c’est / Que ça faut s’en balancer / Comme à la fin on n’sait / Même plus qui a commencé / Si le mensonge avance / On ira dans l’autre sens et / Tous ces mots balancés / On a qu’à les faire danser</p>
            <p>Elle est passée par ici / Elle est passée par là / (Par-là, par-là) / Elle est passée partout / Elle est passée par moi / (Moi) / Elle peut nous réunir, elle peut nous séparer / C’est à nous de choisir / À ce qu’il parait </p>
            <p>La seule évidence c’est / Que ça faut s’en balancer / Comme à la fin on n’sait / Même plus qui a commencé / Si le mensonge avance / On ira dans l’autre sens et / Tous ces mots balancés / On a qu’à les faire danser</p>
        `,
        credits: `
            Auteurs : Amir Haddad - Nazim Khaled - Silvio Lisbonne <br />
            Compositeurs : Amir Haddad - Nazim Khaled - Silvio Lisbonne - Eddy Pradelles <br />
            Éditions : Enfants terribles - Tick Tone Music - Mikkee - JessEE
        `
    },
    {
        id: "toi",
        ytId: "ksnU6gzzg3E",
        title: "Toi",
        slug: "toi",
        album: "ressources-standard",
        lyric: `
            <p>J’ai plus honte de chialer / Devant la télé / J’me sens jamais saoulé / Jamais quand t’es là / Disons que mon cœur est né / Quand notre amour est né / Ok ok  </p>
            <p>Dix fois tu m’as demandé / C’que je faisais hier / J’avoue je suis sorti / J’l’ai fait dans ton dos / J’ai invité ceux que t’aimes / Ton père et ta mère / J’ai largué mon ego </p>
            <p>C’est vrai que j’suis un peu fier / Pourtant j’le fais sans effort / Poser le genou à terre / Sans métaphore / C’est vrai que j’suis pas Molière / Que j’dis les mots comme ils sortent / Des bébés j’voudrais t’en faire / Semer ta force  </p>
            <p>Toi, ne m’en veux pas si je te questionne / Devant Dieu et tous les hommes / Veux-tu être LA personne ? / J’ai pas d’écrin, juste une promesse / Que j’veux ta main / Jusqu’à la fin  </p>
            <p>Depuis toi je suis beaucoup / Moins complexé / Mais facile à vexer / Quand on parle de toi / Tout s’est inversé / Quand tu m’as renversé / Ok ok / Tous mes potes les plus durs / Tu les as radoucis / Là je parle au futur / Depuis qu’il a ta voix / Tu m’as rendu docile avec diplomatie / Avec toi c’est facile </p>
            <p>Chaque fois que mon cœur se ferme / Ton cœur ouvre une autre porte / Pour squatter mon épiderme / T’es la plus forte / Alors j’me mets à genoux / Et tant mieux si j’les écorche / J’veux te faire des bouts de nous / En quelque sorte  </p>
            <p>Toi, ne m’en veux pas si je te questionne / Devant Dieu et tous les hommes / Veux-tu être LA personne ? / J’ai pas d’écrin, juste une promesse / Que j’veux ta main / Jusqu’à la fin  </p>
            <p>Toi, ne m’en veux pas si je te questionne / Devant Dieu et tous les hommes / Veux-tu être LA personne ? / J’ai pas d’écrin, juste une promesse / Que j’veux ta main / Jusqu’à la fin </p>
            <p>J’ai jamais été aussi stressé / Ni aussi sûr de moi / J’ai jamais été aussi pressé / De le crier sur tous les toits / Si tu m’dis non, je peux mourir / Si tu m’dis oui, j’peux mourir aussi / À toi de me dire si je suis vaincu ou invincible </p>
            <p>Toi, ne m’en veux pas si je te questionne / Devant Dieu et tous les hommes / Veux-tu être LA personne ? / J’ai pas d’écrin, j’ai qu’une promesse / J’’veux ta main / Jusqu’à la fin </p>
            <p>Toi, ne m’en veux pas si je te questionne / Devant Dieu et tous les hommes / Veux-tu être LA personne ? / J’ai pas d’écrin, juste une promesse / Que j’veux ta main / Jusqu’à la fin</p>
        `,
        credits: `
            Auteurs : Amir Haddad - Nazim Khaled<br />
            Compositeurs : Amir Haddad - Nazim Khaled - Assaf Tzrouya <br />
            Éditions : Enfants terribles - Mikkee - JessEE - Lakkee - VictorEE
        `
    },{
        id: "la-fete",
        ytId: "s555TY1dODw",
        title: "La Fête",
        slug: "la-fete",
        album: "ressources-standard",
        lyric: `
            <p>Levons nos verres sans raison / Il suffit de vivre c’est bon / Mais c’est meilleur et c’est moins long / Avec un peu d’ivresse / Viens on se casse la voix / Viens on se casse là-bas / Là où l’amour est toujours roi / S’inventer des princesses </p>
            <p>Le patron te fait la misère ? / -C’est rien / T’as le moral sur une civière ? / -C’est rien / T’es encore fatigué d’hier ? / -C’est rien / On réfléchira demain </p>
            <p>Tant pis pour le cœur à moitié vide / Tant que des potes on en a plein </p>
            <p>On est tous nés pour faire la fête / Comme si on l’avait jamais faite / La fête / On est tous nés pour faire la fête / Au pire on la fait dans nos têtes / Comme si on l’avait jamais faite </p>
            <p>La nuit est notre maison / Tu peux t’inviter sans raison / Sois à l’aise comme dans ton salon / Viens meubler ta tristesse / Viens on s’la joue on s’pavane / On s’prend pour les lions de la savane / On est les rois de La Havane /Havana ounana </p>
            <p>Ton histoire d’amour bat de l’aile ? / -C’est rien / Dans ton café t’as mis du sel ? / -C’est rien / T’es trop petit pour toucher le ciel ? / -C’est rien / Parce qu’on grandira demain </p>
            <p>Tant-pis si on tombe dans le vide / Tant qu’on a le verre à moitié plein </p>
            <p>On est tous nés pour faire la fête / Comme si on l’avait jamais faite / La fête / On est tous nés pour faire la fête / Au pire on la fait dans nos têtes / Comme si on l’avait jamais faite </p>
            <p>On n’a presque plus rien à boire / -Mais on est là / Le bar va fermer tout est noir / -Mais on est là / On continue sur le trottoir / -Mais on est là, on est là, on est là / On va encore rentrer trop tard / -Mais on est là / Je crois que j’ai un œil au beurre noir / -Mais on verra / J’ai encore cassé ma guitare... / J’m’appelle Jimi Hendrix ce soir </p>
            <p>On est tous nés pour faire la fête / Comme si on l’avait jamais faite / La fête / On est tous nés pour faire la fête / Au pire on la fait dans nos têtes / Comme si on l’avait jamais faite </p>
        `,
        credits: `
            Auteurs : Amir Haddad - Nazim Khaled<br />
            Compositeurs : Amir Haddad - Nazim Khaled - Assaf Tzrouya<br />
            Éditions : Lakkee - Mikkee - VictorEE - JessEE - Enfants terribles
        `
    }, {
        id: "carrousel",
        ytId: "_8ppYcu7ZHY",
        title: "Carrousel (feat. Indila)",
        slug: "carrousel",
        album: "ressources-standard",
        lyric: `
            <p>Qu’est ce qui m’arrive / Qu’est ce qui me traîne / J’ai le Vésuve / Au fond des veines</p>
            <p>Ah elle est si belle / La douleur qui m’interpelle / Le plus beau des coups que la vie assène / Le plus beau décor, deux corps qui jouent la scène / Je suis tant étourdi je tiens à peine / Mais ces désirs me retiennent </p>
            <p>Et je cours, j’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé un ticket pour elle / Dans ce carrousel</p>
            <p>Et je cours, j’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé un ticket pour elle / Dans ce carrousel</p>
            <p>Dans ce carrousel, dans ce, dans ce carrousel / Dans ce carrousel, dans ce, dans ce carrousel</p>
            <p>Je ne joue pas / Ce n’est pas un game / Là tu t’engages quand tu m’aimes / Faudra me préserver / Jusqu’au souffle dernier</p>
            <p>Et je cours / J’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé enfin l’essentiel / Dans ce carrousel</p>
            <p>Oui je cours / J’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé enfin l’essentiel / Dans ce carrousel</p>
            <p>T’es belle, rebelle / J’suis immortel / C’est toi qui décèles tout ce que ma peau recèle / J’ai peur de toi comme d’une sublime attraction / Une seconde avant l’action </p>
            <p>Si tu m’aimes / Si je t’ouvre mon cœur / C’est que tu sèmes / Une graine, une fleur / Tu devras me garder / Abîmée ou belle /Dans ce carrousel</p>
            <p>Et je cours, j’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé un ticket pour elle /Dans ce carrousel</p>
            <p>Oui je cours / J’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé enfin l’essentiel / Dans ce carrousel</p>
            <p>Dans ce carrousel, dans ce, dans ce carrousel / Dans ce carrousel, dans ce, dans ce carrousel / Dans ce carrousel, dans ce, dans ce carrousel / Dans ce carrousel, dans ce, dans ce carrousel</p>
        `,
        credits: `
            Auteurs - Compositeurs : Amir Haddad - Nazim Khaled - Jérémy Frérot - Renaud Rebillaud - Indila - Skalpovich.  <br />
            Éditions : Mikkee - JessEE - AS Publishing - Allvich - KDNR Group - Enfants terribles - Gohan Music - La Finca
        `
    }, {
        id: "carrousel-r3ssources",
        ytId: "_8ppYcu7ZHY",
        title: "Carrousel",
        slug: "carrousel-r3ssources",
        album: "r3ssources",
        lyric: `
            <p>Qu’est ce qui m’arrive / Qu’est ce qui me traîne / J’ai le Vésuve / Au fond des veines / Ah elle est si belle / La douleur qui m’interpelle</p>
            <p>Le plus beau des coups que la vie assène / Le plus beau décor, deux corps qui jouent la scène / Je suis tant étourdi je tiens à peine / Mais ces désirs me retiennent </p>
            <p>Et je cours, j’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé un ticket pour elle / Dans ce carrousel / Et je cours, j’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé un ticket pour elle / Dans ce carrousel</p>
            <p>Comme la chrysalide j’étais vide et j’me révèle / J’ai le cœur qui vibre j’me sens vivre, ivre d’elle / Ah ton étincelle / Allume mon ciel  </p>
            <p>Et je cours, j’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé un ticket pour elle / Dans ce carrousel / Et je cours, j’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé un ticket pour elle / Dans ce carrousel</p>
            <p>T’es belle, rebelle / J’suis immortel / C’est toi qui décèles tout ce que ma peau recèle / J’ai peur de toi comme d’une sublime attraction / Une seconde avant l’action </p>
            <p>Et je cours, j’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé un ticket pour elle / Dans ce carrousel / Et je cours, j’ai le cœur en aller-retour / C’est la première fois / J’ai trouvé un ticket pour elle / Dans ce carrousel</p>        
        `,
        credits: `
            Auteurs – Compositeurs : Amir Haddad – Nazim Khaled – Jérémy Frérot – Renaud Rebillaud<br />
            Éditions : Mikkee – JessEE – VictorEE – Bukowski Publishing – Gohan Music – La Finca<br />
            Réalisé & arrangé par Dudu Tassa 
        `
    }, {
        id: "on-verra-bien",
        ytId: "XRi1lhm6qAQ",
        title: "On verra bien",
        slug: "on-verra-bien",
        album: "ressources-standard",
        lyric: `
            <p>J’aimerais ton avis, dis-moi c’que ça fait d’affronter la vie / On peut tout entendre, on peut tout subir / On est fait ainsi / Si c’est difficile, ça nous fortifie on peut s’en servir / Ça doit être le ciel qui nous fait un signe</p>
            <p>Si l’on rit, parfois jusqu’aux larmes / C’est que bien souvent le bien se trouve dans le malheur / Si on hésite c’est qu’on a le choix / J’suis certain qu’on est sûr de rien</p>
            <p>On verra bien / On verra le bien / Le tout dans le rien, l’infini dans chaque fin / On verra bien / On verra demain / Ce que l’on retient fera ce que l’on devient</p>
            <p>Tu sais le cœur est magique / Il recoud ses blessures au fil doré / Cherche la lueur et insiste /T’es encore plus beau rafistolé</p>
            <p>T’as l’impression qu’il t’affronte ? / Rassure-toi, ses plans sont parfaits / Je vois la vie comme un conte / Ou jamais le méchant peut triompher</p>
            <p>C’est qu’un mauvais quart d’heure / Au pire un quart d’année / Ou un quart de vie peu importe c’est bientôt terminé / La lumière est là / Ferme les yeux pour mieux la voir</p>
            <p>On verra bien / On verra le bien / Le tout dans le rien, l’infini dans chaque fin / On verra bien / On verra demain / Ce que l’on retient fera ce que l’on devient / X2</p>
            <p>On verra bien, on verra bien, on verra bien / On verra bien, on verra bien, on verra</p>
            <p>On verra bien / Le bien... Dans le rien / L’infini dans chaque fin / On verra bien / On verra demain / Ce que l’on retient fera ce que l’on devien</p> 
        `,
        credits: `
            Auteurs : Amir Haddad – 7 Jaws - Freddie Marche<br />
            Compositeurs : Amir Haddad – Fred Savio – Freddie Marche – 7 Jaws - Felipe Saldivia<br />
            Éditions :  Mikkee - JessEE - Sony ATV - Warner Chappell Music - Ear print - Universal Music Publishing - Fred.S - Slowfast publishing - Filasco publishing
        `
    },{
        id: "a-l-envers",
        title: "À l'envers",
        slug: "a-l-envers",
        album: "ressources-standard",
        lyric: `
            <p>On n’peut pas refaire l’histoire / Mais on peut la sublimer, disons / Simplement qu’on se sépare / Avant de trop s’abîmer / Il n’y a pas de nouveau départ / Quand on est presque arrivé, eh non / Et pourtant il y a des soirs / Où je me prends à rêver</p>
            <p>D’une dernière nuit qui serait la première / T’effacer de ma mémoire / Pour mieux recommencer / D’une marche-avant d’un retour en arrière / Te perdre comme l’espoir / Et puis te rencontrer</p>
            <p>Et si on tourne la page le livre à l’envers / Effacer notre voyage c’est aussi le refaire / Juste un dérapage, un autre univers / On l’aura peut-être enfin cette chance de tout refaire, à l’envers</p>
            <p>Dis-toi qu’on refait surface / Pour éviter de plonger, et que / Les batailles on les remplace / Par une paix prolongée, c’est mieux / Comme ferait un essuie-glace / Sur le pare-brise du passé / Si les souvenirs nous lassent / On pourra les déplacer</p>
            <p>Loin des hivers qui nous ont éprouvé / Pour une saison des amours / Toujours recommencée / Ne plus savoir comme on s’est ignorés / Un éternel premier jour / À vivre à tes côtés</p>
            <p>Et si on tourne la page le livre à l’envers / Effacer notre voyage c’est aussi le refaire / Juste un dérapage, un autre univers / On l’aura peut-être enfin cette chance de tout refaire, à l’envers</p>
            <p>J’ai le cœur immaculé de toi / Et pourtant je crois, je crois / Qu’on a dû s’aimer dans une autre ville / Comblais-tu mes vides dans une autre vie ? / Une impression de déjà vu, déjà vécu peut-être / Ou juste voir renaître ce qu’on avait déjà</p>
            <p>Et si on tourne la page le livre à l’envers / Effacer notre voyage c’est aussi le refaire / Juste un dérapage, un autre univers / On l’aura peut-être enfin cette chance de tout refaire, à l’envers</p>
        `,
        credits: `
            Auteurs : Amir Haddad - Nazim Khaled <br />
            Compositeurs : Nazim Khaled - Assaf Tzrouya<br />
            Éditions : Enfants terribles - Lakkee - VictorEE - Mikkee - JessEE
        `
    },{
        id: "a-l-envers-r3ssources",
        title: "À l'envers",
        slug: "a-l-envers-r3ssources",
        album: "r3ssources",
        lyric: `
            <p>On n’peut pas refaire l’histoire / Mais on peut la sublimer, disons / Simplement qu’on se sépare / Avant de trop s’abîmer / Il n’y a pas de nouveau départ / Quand on est presque arrivé, eh non / Et pourtant il y a des soirs / Où je me prends à rêver</p>
            <p>D’une dernière nuit qui serait la première / T’effacer de ma mémoire / Pour mieux recommencer / D’une marche-avant d’un retour en arrière / Te perdre comme l’espoir / Et puis te rencontrer</p>
            <p>Et si on tourne la page le livre à l’envers / Effacer notre voyage c’est aussi le refaire / Juste un dérapage, un autre univers / On l’aura peut-être enfin cette chance de tout refaire, à l’envers</p>
            <p>Dis-toi qu’on refait surface / Pour éviter de plonger, et que / Les batailles on les remplace / Par une paix prolongée, c’est mieux / Comme ferait un essuie-glace / Sur le pare-brise du passé / Si les souvenirs nous lassent / On pourra les déplacer</p>
            <p>Loin des hivers qui nous ont éprouvé / Pour une saison des amours / Toujours recommencée / Ne plus savoir comme on s’est ignorés / Un éternel premier jour / À vivre à tes côtés</p>
            <p>Et si on tourne la page le livre à l’envers / Effacer notre voyage c’est aussi le refaire / Juste un dérapage, un autre univers / On l’aura peut-être enfin cette chance de tout refaire, à l’envers</p>
            <p>J’ai le cœur immaculé de toi / Et pourtant je crois, je crois / Qu’on a dû s’aimer dans une autre ville / Comblais-tu mes vides dans une autre vie ? / Une impression de déjà vu, déjà vécu peut-être / Ou juste voir renaître ce qu’on avait déjà</p>
            <p>Et si on tourne la page le livre à l’envers / Effacer notre voyage c’est aussi le refaire / Juste un dérapage, un autre univers / On l’aura peut-être enfin cette chance de tout refaire, à l’envers</p>
        `,
        credits: `
            Auteurs : Amir Haddad – Nazim Khaled<br />
            Compositeurs : Nazim Khaled – Assaf Tzrouya<br />
            Éditions : Bukowski Publishing – Lakkee – VictorEE – Mikkee – JessEE<br />
            Réalisé & arrangé par Dudu Tassa 
        `
    },{
        id: "ma-lumiere",
        ytId: "VMPyLr_wMP0",
        title: "Ma lumière",
        slug: "ma-lumiere",
        album: "ressources-standard",
        lyric: `
            <p>J’te l’dis / Tu m’crois pas, mais j’te l’dis / J’peux faire de ta planète un putain de paradis / Enfin je me dis la vie est belle / Avant elle était juste jolie / Ce nouveau monde j’m’en remets pas / Depuis que t’es là je me connais moi</p>
            <p>Et j’me dis / J’y crois pas mais j’me dis / Que c’est pas dehors mais dans tes bras que je veux passer la nuit   / J’me suis levé avant le réveil / Pourtant deux semaines que j’ai pas dormi / Je ressemble à rien mais j’ai tout / Si tu respires mal j’étouffe </p>
            <p>Mais non, mais non, mais non / Je ne bougerai pas / Même quand t’auras grandi / J’me tiendrai là entre toi et la pluie / On m’avait dit tu verras. Tu verras / J’me contentais de sourire / J’t’avais pas vu venir </p>
            <p>Comment faire ? / Je ne peux défaire mon regard de toi / Non non non / À faire toutes ces choses qui ne s’apprennent pas(pa)  / Comment tu viens de m’appeler, je rêve ou quoi ? / Est-ce que j’ai mal compris ? / Il est déjà l’heure d’éteindre la lumière / Mais je ne peux défaire mon regard de toi / Non non non  / T’es si petit mais je tiens entre tes doigts / Depuis t’t’a l’heure j’suis là, je balbutie / T’as sûrement rien compris / Mais plus rien n’éteindra jamais ma lumière </p>
            <p>Des cris / Sûr qu’il y aura des cris / Tu me mettras dans des états qu’on ne peut même pas décrire / Et puis je fondrais comme neige / Au soleil de tes sourires / Petit t’prends pas trop vite pour Hercule / Y’a ton repas sur mon pull</p>
            <p>Il est pas né celui qui t’enlèvera / J’pourrais faire des folies / J’pourrais braver mille hommes / Le vent la pluie / La seule question qui m’vient quand je te vois / Comment j’ai pu tenir, tout ce temps sans ton sourire ?</p>
            <p>Comment faire ? / Je ne peux défaire mon regard de toi / Non non non / À faire toutes ces choses qui ne s’apprennent pas(pa) / Comment tu viens de m’appeler, je rêve ou quoi ? / Est-ce que j’ai mal compris ? / Il est déjà l’heure d’éteindre la lumière / Mais je ne peux défaire mon regard de toi / Non non non / T’es si petit mais je tiens entre tes doigts / Depuis t’t’a l’heure j’suis là, je balbutie / T’as sûrement rien compris / Mais plus rien n’éteindra jamais ma lumière</p>
        `,
        credits: `
            Auteurs : Amir Haddad - Nazim Khaled - Elad Trabelsi <br />
            Compositeur : Assaf Tzrouya<br />
            Éditions : Enfants terribles - Mikkee - JessEE
        `
    },{
        id: "douce-guerriere",
        title: "Douce guerrière ",
        slug: "douce-guerriere",
        album: "ressources-standard",
        lyric: `
            <p>Elle se bat pour nos vies, pour nos âmes / Seule face au vent / Dans le froid, sous la pluie ou dans les flammes / Sourit aux passants / Elle a lâché son école / Pour nous montrer sa détresse / Tout son monde dégringole / Pauvre déesse </p>
            <p>Si belle quand elle écrit ses maux / Grâce à elle, le monde est plus beau </p>
            <p>Je me demande encore et encore / D’où vient ce cœur de fer / Quand elle se donne au corps à corps / Elle me ramène sur Terre / Et c’est en voyant son sourire / Que tout devient plus clair / Elle est immense dans l’effort / Douce guerrière </p>
            <p>Elle s’allonge et me dit tout ira bien / Moi je tremble encore / Pourtant pâle, ses yeux dans les miens / Je me sens plus fort / Toutes les forces de l’univers / De son corps, descendent, oppressent / Elle lui donne la lumière / Douleur en liesse </p>
            <p>Si belle quand elle crie tout haut / Grâce à elle, mon monde est plus beau </p>
            <p>Je me demande encore et encore / D’où vient ce cœur de fer / Quand elle se donne au corps à corps / Elle me ramène sur Terre / Et c’est en voyant son sourire / Que tout devient plus clair / Elle est immense dans l’effort / Douce guerrière / x2 </p>
            <p>Dis-moi la force qu’il faudrait / Dis-moi comment on pourrait / Donne-moi leur manière d’aimer / x2 </p>
            <p>Je me demande encore et encore / D’où vient ce cœur de fer / Quand elle se donne au corps à corps / Elle me ramène sur Terre / Et c’est en voyant son sourire / Que tout devient plus clair / Elle est immense dans l’effort / Douce guerrière</p>
        `,
        credits: `
            Auteurs : Amir Haddad - Jérémy Frérot<br />
            Compositeurs : Amir Haddad - Jérémy Frérot - Renaud Rebillaud<br />
            Éditions : Mikkee - JessEE - Gohan Music - La Finca
        `
    },{
        id: "pardonnez-moi",
        title: "Pardonnez-moi",
        slug: "pardonnez-moi",
        album: "ressources-standard",
        lyric: `
            <p>À tous ceux qui pensent à moi avec un peu de rancœur / Des fois j’écris pas droit je laisse des traces sur les cœurs / On est tous les mêmes j’ai pas su faire différemment / J’en ai blessé des gens, j’le regrette infiniment  </p>
            <p>À ceux qui ont peut-être attendu des semaines pour me voir / Et moi sans le savoir j’ai même pas donné un regard / J’peux comprendre si vous êtes de ceux que j’ai déçu / J’voudrais soigner vos maux, mettre des mots dessus </p>
            <p>Si t’as des points de suture sur le cœur / A cause de moi / Des mois que tu maudis ta douleur / Simplement dis-toi / Ce qui nous résume c’est pas nos lacunes / C’est ce qu’on en fait je crois </p>
            <p>Si t’as des points de suture sur le cœur / Si malgré moi / J’ai pas su dissoudre ta douleur /Simplement dis-toi / Oui j’ai des lacunes mais je les assume / S’il vous plaît pardonnez moi</p>
            <p>À toutes les fois où j’ai porté des costumes d’imposteur/ Les fois où je m’suis vu trop grand sans être à la hauteur / On blesse tous les gens qu’on aime, je l’ai fait par moment / Quand j’étais pas moi-même, désolé papa maman</p>
            <p>À tout ce que je n’ai pas dit / Ou au contraire / Quand je manquais pas d’air / J’ai été fou / J’ai été tout et son contraire / J’voulais être quelqu’un / Là j’voudrais être quelqu’un de bien / Que mes erreurs d’hier soient des leçons pour demain </p>
            <p>Si t’as des points de suture sur le cœur / A cause de moi / Des mois que tu maudis ta douleur / Simplement dis-toi / Ce qui nous résume c’est pas nos lacunes / C’est ce qu’on en fait je crois </p>
            <p>Si t’as des points de suture sur le cœur / Si malgré moi, j’ai pas su dissoudre ta douleur / Simplement dis-toi / Oui j’ai des lacunes mais je les assume / S’il vous plaît pardonnez moi</p>
            <p>Si t’as des points de suture sur le cœur…/ Des mois que tu maudis ta douleur… / J’ai des lacunes… / S’il vous plaît pardonnez moi</p>
            <p>Si t’as des points de suture sur le cœur / A cause de moi / Des mois que tu maudis ta douleur / Simplement dis-toi / Ce qui nous résume c’est pas nos lacunes / C’est ce qu’on en fait je crois </p>
            <p>Si t’as des points de suture sur le cœur / Si malgré moi, j’ai pas su dissoudre ta douleur / Simplement dis-toi / Oui j’ai des lacunes mais je les assume / S’il vous plaît pardonnez moi 
        `,
        credits: `
            Auteurs : Nazim Khaled - Gurvan Nantel<br />
            Compositeurs : Amir Haddad - Nazim Khaled - Silvio Lisbonne - Pierre-Laurent Faure  <br />
            Éditions : Enfants terribles - Tick Tone Music - Mikkee - JessEE  
        `
    },{
        id: "entre-gifle-et-caresse",
        title: "Entre gifle et caresse",
        slug: "entre-gifle-et-caresse",
        album: "ressources-standard",
        lyric: `
            <p>En résumé, un beau jour je suis né et depuis ce jour j’improvise / Ce n’est pas du ciné, mes erreurs sont innées, c’est pour ça qu’elles se reproduisent  </p>
            <p>J’peux vous faire une confession / J’sais pas, j’sais pas / J’crois que j’ai des problèmes de gestion / J’sais pas, j’sais pas  </p>
            <p>Comment faire pour grandir enfin / Dans ma tête c’est vrai qu’on est plein / J’voudrais être le même/ homme en bien / En bien  </p>
            <p>Mais j’oscille </p>
            <p>Entre gifle et caresse / L’envie de faire de grandes choses et la paresse / Entre le feu de joie et le feu de détresse / Qu’est ce qui nous blesse et qu’est ce qui fait que l’on progresse ? / Combien de temps dure la jeunesse ? </p>
            <p>J’me sens incertain / Entre la gifle et la caresse / Incertain  </p>
            <p>J’me sens incertain / Entre la gifle et la caresse / Incertain </p>
            <p>Sans m’excuser, je suis pas une fusée, mais j’ai beaucoup bossé sur moi / J’ai voulu la comprendre mais je suis pas rusé pour moi la vie c’est du chinois  </p>
            <p>Me trouver une occupation ? / J’sais pas, j’sais pas / Je suis dissipé par passion / Je crois pas, j’sais pas  </p>
            <p>J’ai pas de flair on m’dit qu’j’ressens rien / Comment faire pour changer d’chemin ? / Je marche arrière, j’recule vers demain / J’suis bien </p>
            <p>Quand j’oscille </p>
            <p>Entre gifle et caresse / L’envie de faire de grandes choses et la paresse / Entre le feu de joie et le feu de détresse / Qu’est ce qui nous blesse et qu’est ce qui fait que l’on progresse ? /Combien de temps dure la jeunesse ? </p>
            <p>J’me sens incertain / Entre la gifle et la caresse / Incertain  </p>
            <p>J’me sens incertain / Entre la gifle et la caresse / Incertain </p>
            <p>C’est vrai que l’incertitude est ma maison / Que quelquefois j’entends les murs trembler, plus que de raison / Plus que deux saisons et j’vais changer comme une chambre en bordel bientôt j’vais me ranger / J’y arriverai dans quelques temps/ Même si il me faudra quelques trains / En attendant j’me fais la main </p>
            <p>Entre gifle et caresse/ L’envie de faire de grandes choses et la paresse / Entre le feu de joie et le feu de détresse / Qu’est ce qui nous blesse et qu’est ce qui fait que l’on progresse ? / Combien de temps dure la jeunesse ? </p>
            <p>Entre le feu de joie et le feu de détresse  </p>
            <p>Qu’est ce qui nous blesse et qu’est ce qui fait que l’on progresse ?  </p>
            <p>Combien de temps dure la jeunesse ? </p>
        `,
        credits: `
            Auteur : Nazim Khaled  <br />
            Compositeurs : Udi David - Sharon Avilhak - Nazim Khaled <br />
            Éditions : Enfants terribles - Lakkee - VictorEE
        `
    },{
        id: "passer",
        title: "Passer (Ft. The Idan Raichel Project)  ",
        slug: "passer",
        album: "ressources-standard",
        lyric: `
            <p>Des fois tu me fuis, des fois tu m’entraînes /Je ne sais pas / Si tu me veux comme on veut des / problèmes / Je ne vois pas / Où tu veux en venir ? t’aimes les paradoxes et / Tu remues l’avenir en parlant de passé / Je ne sais quoi te dire / Je ne sais que penser / Penser</p> 
            <p>Quand tu t’sens lib’ c’est là que tu m’enchaînes / Quel est ton but ? / Tu refuses tout de la réalité même / Quand vient la chute / Tout s’envenime et puis tu le regrettes / Même quand tu me cuisines je connais ta recette / Mais là je suis rincé / Bravo tu m’as lassé / Assez</p>
            <p>Par moi tu n’faisais que passer oh / Tu m’as distancé / Par moi tu n’faisais que passer oh / J’ose pas y penser</p>
            <p>Quand tu souris c’est qu’de moi tu te sers, non / Tu ne crois pas / Quand je subis tes soupirs et tes sermons / Tu ne vois pas / Où tout ça va finir, mon cœur est défoncé / Pour ne pas te mentir j’ai voulu renoncer / Mais je n’vais pas tenir / Et me tenir tu sais / Tu sais</p>
            <p>La route est longue, et l’on commence à peine / Peux-tu changer ? / Est-ce que t’attends qu’les ennuis nous reprennent / Pour te ranger ? / Tu devais me chérir, t’aimais trop t’amuser / Est-ce qu’on peut se guérir est-ce que tu vas ruser ? /Parfois c’est abusé / La pilule est passée / Passée </p>
            <p>Par moi tu n’faisais que passer oh / Tu m’as distancé / Par moi tu n’faisais que passer oh / J’ose pas y penser</p>
            <p>T’allumes la mèche / Tu me transperce de flèches / Tu tires à bout portant tu / Tue-moi c’est important </p>
            <p>Soit on s’évite / Soit on se ressuscite / Choisis mais fais-le vite / Car on n’a plus de temps</p>
            <p>Par moi tu n’faisais que passer oh / Tu m’as distancé / Par moi tu n’faisais que passer oh / J’ose pas y penser</p>
        `,
        credits: `
            Auteur : Nazim Khaled <br />
            Compositeurs : Nazim Khaled - Eddy Pradelles - Idan Raichel<br />
            Éditions : Enfants terribles - Tick Tone Music - Mikkee - JessEE
        `
    },{
        id: "le-c",
        title: "Le C",
        slug: "le-c",
        album: "ressources-standard",
        lyric: `
            <p>On a défié les bras de Morphée / On voulait finir dans des draps défaits / Aucun regret même d’en avoir trop fait / Des restos baskets et des voitures empruntées / Tu m’cassais la tête quand t’avais le cœur brisé / Et les nuits blanches qu’on a passé à dégriser  </p>
            <p>On avait tout bien fait pourtant / Des petites erreurs rien de méchant / Tu disais qu’on avait le temps / Mais tout a cessé quand </p>
            <p>Le C t’a embrassé / C, C, C t’a embrassé C, C / Tu disais qu’on avait le temps / Mais tout a cessé quand / Le C t’a embrassé / C, C, C t’a embrassé C, C / Mais son baiser t’a condamné  </p>
            <p>Tu me laisses seul ici / J’me fous de ceux qui disent que c’est ainsi / J’ai de la force, prends en la moitié / J’en peux plus du regard de ces gens qui ont pitié  </p>
            <p>N’attends pas les signes / Si la vie veut plus de toi s’teuplaît insiste /Fais-la changer d’avis, invite là à rester / On n’peut pas en rester là </p>
            <p>On avait des modèles qu’on imitait / À part le ciel rien ne nous limitait / En vérité on s’croyait immortels / Quand il s’agit d’amour y’a pas de dignité / On disait je t’aime à des filles sans hésiter / Et on plongeait, faut croire qu’on aimait s’faire jeter </p>
            <p>On sait qu’tout passe et ouais c’est vrai / Y’a pas grand-chose qui nous effraie / On traçait l’futur à la craie / Sans jamais nuancer, puis </p>
            <p>Le C t’a embrassé / C, C, C t’a embrassé C, C / On traçait l’futur à la craie / Sans jamais nuancer, puis  / Le C t’a embrassé / C, C, C t’a embrassé C, C / Mais son baiser t’a condamné </p>
            <p>Tu me laisses seul ici / J’me fous de ceux qui disent que c’est ainsi / J’ai de la force, prends en la moitié / J’en peux plus du regard de ces gens qui ont pitié </p>
            <p>N’attends pas les signes / Si la vie veut plus de toi s’teuplaît insiste / Fais-la changer d’avis, invite là à rester / On n’peut pas en rester là </p>
            <p>En fait t’es un héros / Tu m’dis d’être heureux / Qu’c’est pas en versant des larmes / Qu’on va être mieux / Allez faut que tu t’accroches jusqu’à être vieux  </p>
            <p>Moi j’suis pas un héros / J’suis pas courageux / Sans toi j’suis seul au monde envie d’être deux / On est toujours en retard fais attendre Dieu  </p>
            <p>Ne me laisse pas seul ici / N’attends pas les signes </p>
            <p>Tu me laisses seul ici / J’me fous de ceux qui disent que c’est ainsi /J’ai de la force, prends en la moitié / J’en peux plus du regard de ces gens qui ont pitié  </p>
            <p>N’attends pas les signes / Si la vie veut plus de toi s’teuplaît insiste / Fais-la changer d’avis, invite là à rester / On n’peut pas en rester là </p>
        `,
        credits: `
            Auteurs : Amir Haddad - Nazim Khaled<br />
            Compositeurs : Amir Haddad - Nazim Khaled<br />
            Éditions : Enfants terribles - Mikkee - JessEE
        `
    },{
        id: "daphne",
        title: "Daphné",
        slug: "daphne",
        album: "ressources-standard",
        lyric: `
            <p>« Malade d’amour je suis, tout bêtement folle de lui / Malade d’amour je suis... Complètement folle » </p>
            <p>Salut, bonjour je / Par où commencer ? /Désolée j’angoisse vraiment / J’veux pas déranger </p>
            <p>J’connais tout de toi, moi / J’peux te raconter / Je sais qu’t’as pas beaucoup d’temps /Mais peux-tu rester ? </p>
            <p>C’est la première fois qu’j’te vois / Enfin j’veux dire en vrai / Avant on s’aimait déjà / Maintenant tu l’sais </p>
            <p>Ne t’en fais pas ce n’est rien / Les larmes sur mes cils / Je suis heureuse ainsi </p>
            <p>Malade d’amour, je suis tout bêtement folle de lui / Complètement oui / Malade d’amour je suis, complètement folle </p>
            <p>Malade d’amour, je suis tout bêtement folle de lui / Complètement oui / Malade d’amour je suis, complètement folle </p>
            <p>Tant crié ton nom oui / J’aimerai qu’tu l’entendes / Crois-tu qu’il y ait des amours que l’on n’peut comprendre ?   </p>
            <p>J’me sens tellement proche de toi / Et avec le temps / T’as décoré mes yeux noirs, les murs de ma chambre </p>
            <p>Je n’veux pas être une ombre / Dans l’ombre de ta hauteur / On m’dit que je n’suis qu’un nombre          <p> / Parmi tant d’autres </p>
            <p>Mais j’ai toujours espoir / Au fond de moi je sais / Le cœur nous définit  </p>
            <p>Malade d’amour, je suis tout bêtement folle de lui / Complètement oui / Malade d’amour je suis, complètement folle </p>
            <p>Malade d’amour, je suis tout bêtement folle de lui / Complètement oui / Malade d’amour je suis, complètement folle </p>
            <p>Tu partiras moi je reste ici / Qu’en garderais-je ? / Un bout de rêve </p>
            <p>Un bout de rêve, pour mieux m’endormir / Je me vois sourire /Pas besoin de guérir  </p>
            <p>Malade d’amour, je suis tout bêtement folle de lui / Malade d’amour je suis, complètement folle </p>
            <p>Malade d’amour, je suis tout bêtement folle de lui / Complètement oui / Malade d’amour je suis, complètement folle </p>
            <p>Malade d’amour, je suis tout bêtement folle de lui / Complètement oui / Malade d’amour je suis, complètement folle </p>
        `,
        credits: `
            Auteurs : Amir Haddad - 7 Jaws - Renaud Rebillaud - Farès El Jazouli<br />
            Compositeurs : Amir Haddad - Renaud Rebillaud - 7 Jaws	<br />
            Éditions :  Mikkee - JessEE - Gohan Music - Sony ATV
        `
    },{
        id: "paradis",
        title: "Paradis",
        slug: "paradis",
        album: "ressources-standard",
        lyric: `
            <p>Est ce qu’il faut se tailler un corps de rêve / Ou batailler avec son esprit ? / Être un as de l’escrime / Ou un génie de l’esquive ? / Est ce qu’il faut se lever quand le jour se lève / Ou rester tranquille dans son lit ? / Avant que l’envie s’achève / Faut-il tuer l’ennui ? </p>
            <p>Je n’sais pas, je n’sais pas s’il vaut mieux / Essayer d’séduire le destin capricieux / Simplement s’en remettre à Dieu / Je n’sais pas, je n’sais pas j’fais au mieux / J’avoue qu’je n’suis pas un ange sous les cieux / Et j’suis pas seul, on est nombreux  </p>
            <p>Qui est le plus près du paradis-moi / Si tu l’sais, comment réussir sa vie ? / Quelle gueule a le bonheur / Qu’est ce qui nous parasite ? / Qui est le plus près du paradis-moi / Si tu l’vois, s’il te plaît demande lui / Où s’cache le bonheur / Et est-ce qu’il a un prix ?  </p>
            <p>Est-ce qu’il faut tenter d’être millionnaire / Ou de gagner un million d’amis ? /Être libre comme l’air    / Ou faire comme on a appris ? / Est ce qu’il faut chercher à être solaire / Où apprendre à danser sous la pluie ? / Dépasser les frontières / Ou se mettre à l’abri  </p>
            <p>On ne peut, on ne peut deviner / Les plans que le destin n’a pas dessinés / On se contente d’essayer    / Dans la chute on n’peut pas léviter / On s’élève pour oublier la gravité / Des jours qui nous ont vu tomber </p>
            <p>Qui est le plus près du paradis-moi / Si tu l’sais, comment réussir sa vie ? / Quelle gueule a le bonheur / Qu’est ce qui nous parasite ? / Qui est le plus près du paradis-moi / Si tu l’vois, s’il te plaît demande lui / Où s’cache le bonheur / Et est-ce qu’il a un prix ?  </p>
            <p>Les gentils, les bandits /Les apprentis repentis / On le cherche tous / On le cherche tous </p>
            <p>Les gens qui n’ont rien accompli / Ceux qui ont tout compris / On le cherche tous / Il est peut-être ici </p>
            <p>Qui est le plus près du paradis-moi / Si tu l’sais, comment réussir sa vie ? / Quelle gueule a le bonheur / Qu’est ce qui nous parasite ? / Qui est le plus près du paradis-moi / Si tu l’vois, s’il te plaît demande lui / Où s’cache le bonheur / Et est-ce qu’il a un prix ? </p>
        `,
        credits: `
            Auteur : Nazim Khaled<br />
            Compositeurs : Nazim Khaled - Benua Nehaisi<br />
            Éditions : Enfants terribles - Mikkee - JessEE
        `
    },{
        id: "comme-il-faut",
        title: "Comme il faut (Ft. Céphaz)  ",
        slug: "comme-il-faut",
        album: "ressources-standard",
        lyric: `
            <p>J’voulais être parfait / Précéder tes moindres pas /Épicer ta vie / Te faire des petits repas / T’emmener en vacances / Dans le pays de ton choix / Sur une plage immense / À me noyer sous ton charme  </p>
            <p>J’parlais plus aux filles, tu choisissais tous les films / Te rendre heureuse chaque jour était mon plus beau défi / Était mon plus grand désir, aujourd’hui on se déchire / J’ai plus la gueule de l’emploi, dis-moi pourquoi tu me vires ?  </p>
            <p>J’avais tout bien fait comme il faut / En partant tu m’as dit t’es trop gentil je / N’savais pas que c’était un défaut / Quand j’t’ai dit je t’aime tu n’as pas senti que / J’avais tout bien fait comme il faut / J’aurais dû mentir et faire de l’antijeu / J’avais envie d’être au niveau / Comment tu peux me dire dans les yeux sans rougir que  </p>
            <p>C’est trop, c’est trop / T’es trop parfait pour moi, na na na na / C’est trop, c’est trop /J’suis peut-être pas fait pour toi, na na na na </p>
            <p>Et si t’avais un délire / Moi j’étais toujours partant / Est-ce que c’est un délit / De vouloir être épatant ? / J’t’amenais le petit dej / Bouquet de proses et de fleurs / J’étais blanc comme neige / Pour que tu vives en couleurs  </p>
            <p>J’l’ai pas vu tout de suite mais aujourd’hui j’en suis sûr / Tu voulais l’gars un peu dur pas celui qui te rassure / Tu veux un bad boy, tu veux celui qui a la cote mais / Quand tu seras en bad sera-t-il à tes côtés ? </p>
            <p>J’avais tout bien fait comme il faut / En partant tu m’as dit t’es trop gentil je / N’savais pas que c’était un défaut / Quand j’t’ai dit je t’aime tu n’as pas senti que / J’avais tout bien fait comme il faut /J’aurais dû mentir et faire de l’antijeu / J’avais envie d’être au niveau / Comment tu peux me dire dans les yeux sans rougir que  </p>
            <p>C’est trop, c’est trop T’es trop parfait pour moi, na na na na / C’est trop, c’est trop / J’suis peut-être pas fait pour toi, na na na na </p>
            <p>Pour faire le ménage dans ma vie j’avais besoin d’un balai / J’ai dû muscler mon cœur de gringalet pour pas m’emballer / J’avais du mal à parler, j’ai mis longtemps à m’dire / Que dans le meilleur et le pire t’avais rien fait comme il fallait / Aujourd’hui je suis soigné, j’vais pas pleurer des années / Je dirais même que tu m’as une fleur en m’laissant faner / T’es revenue à la charge mais j’sais pas, j’ai dit non / J’pensais pas qu’on pouvait tourner la page aussi facilement  </p>
            <p>J’avais tout bien fait comme il faut... / J’savais pas que c’était un défaut... / Quand j’t’ai dit je t’aime /J’avais tout bien fait comme il faut / J’aurais dû mentir et faire de l’antijeu / J’avais envie d’être au niveau / Comment tu peux me dire dans les yeux sans rougir que  </p>
            <p>C’est trop, c’est trop / T’es trop parfait pour moi, na na na na / C’est trop, c’est trop / J’suis peut-être pas fait pour toi, na na na na </p>
            <p>J’suis peut-être pas fait pour toi </p>
            <p>J’suis peut-être pas fait pour toi </p>
        `,
        credits: `
            Auteur : Nazim Khaled<br />
            Compositeurs : Nazim Khaled - Benua Nehaisi<br />
            Éditions : Enfants terribles - Mikkee - JessEE
        `
    },{
        id: "fous",
        title: "Fous (Ft. 7 Jaws & Bambino) ",
        slug: "fous",
        album: "ressources-standard",
        lyric: `
            <p>C’est pour les fadas les génies sans tralalas / Les fêlés qui en ont fini avec les blablas / Où sont les tarés, les égarés, on s’reconnaît /Plus on est fous, plus on est dans le vrai </p>
            <p>Tu les verras pas dans la ronde / Ils sont partout sauf dans les cases / Ils construisent le monde mieux qu’ils construisent leurs phrases </p>
            <p>Les génies sont pas dans les lampes / Et trop rarement dans la lumière / Ils verront demain ce que toi t’as vu hier </p>
            <p>Vous êtes opposés comme des aimants / Tourne-toi regarde les, tout simplement / Faut plus se méfier seulement se comprendre / Et se rapprocher comme des aimants   </p>
            <p>C’est pour les fadas les génies sans tralalas / Les fêlés qui en ont fini avec les blablas / Où sont les tarés, les égarés, on s’reconnaît / Plus on est fous, plus on est dans le vrai </p>
            <p>Pas les mêmes dessins sur les mêmes plages / Pas les mêmes couleurs sur les mêmes pages / Pas les mêmes symboles dans les mêmes tâches / Pas les mêmes sens dans les mêmes phrases </p>
            <p>Faut d’la hauteur faut de l’espace / Moteur qui marche avec l’espoir / Oublie c’qu’ils t’ont dit en classe / Jamais d’la vie faut qu’tu restes à ta place  </p>
            <p>Ça fou le zbeule, Vesski les folas / Si t’aime pas nos gueules baisse le regard / J’suis pas différent j’fais juste la diff / Calmant sur calmant / J’sais pas c’qui m’arrive ! </p>
            <p>DJ coupe le son, et tu chantes solo / Sourire à la cam tu flippe d’vant les chico  / C’est les Avengers grosse équipe de zicos / Z’aurais jamais dû m’laisser d’vant le micro </p>
            <p>C’est pour les fadas les génies sans tralalas / Les fêlés qui en ont fini avec les blablas / Où sont les tarés, les égarés, on s’reconnaît / Plus on est fous, plus on est dans le vrai </p>
            <p>C’est pour les fadas les génies sans tralalas / Les fêlés qui en ont fini avec les blablas / Où sont les tarés, les égarés, on s’reconnaît /Plus on est fous, plus on est dans le vrai </p>
            <p>Tu sais le monde est fragile / Il peut compter sur toi / Sur tes mathématiques  </p>
            <p>Tu peux rater un pas / Mais jamais ta vie / Tu peux parler de toi mieux que n’importe qui, Lee ! / Les laisse pas te pointer du doigt / Sauf si c’est pour montrer le meilleur du moi / Lalalalala </p>
            <p>C’est pour les fadas les génies sans tralalas / Les fêlés qui en ont fini avec les blablas / Où sont les tarés, les égarés, on s’reconnaît / Plus on est fous, plus on est dans le vrai </p>
            <p>C’est pour les fadas les génies sans tralalas / Les fêlés qui en ont fini avec les blablas / Où sont les tarés, les égarés, on s’reconnaît / Plus on est fous, plus on est dans le vrai </p>
        `,
        credits: `
            Auteurs : Amir Haddad - 7 Jaws - Freddie Marche<br />
            Compositeurs : Amir Haddad - Fred Savio – Freddie Marche – 7 Jaws - Felipe Saldivia <br />
            Éditions :  Mikkee - JessEE - Sony ATV - Warner Chappell Music - Ear print - Universal Music Publishing - Fred.S - Slowfast publishing - Filasco publishing     
        `
    },{
        id: "cet-homme",
        title: "Cet homme",
        slug: "cet-homme",
        album: "ressources-standard",
        lyric: `
            <p>Sur un bout de trottoir / Avec des yeux trop noirs / Pour n’avoir pas pleuré / Son âme est une fenêtre /C’est une plaie ouverte / Qu’on ne peut qu’effleurer / C’est juste un être à part / Que les gens ne voient pas / Comme un mot raturé / Les mains comme des pierres / Asséchées par l’hiver / Qui ne fait que durer </p>
            <p>Je suis passé cent fois devant / J’aurais pu m’arrêter, lui donner / Un sourire, un instant / Mais j’étais trop pressé </p>
            <p>J’aurais pu être cet homme / Qui n’a plus rien ni personne / Combien de coups faut-il ? / Combien de coups faut-il ? / Avant que la vie nous assomme / Il a tout perdu cet homme / Mais personne ne s’en étonne / Combien de temps faut-il ? / Combien de temps faut-il ? / Avant qu’une âme abandonne / S’abandonne / S’abandonne </p>
            <p>Sur un bout de trottoir / Juste au bord du trou noir / Le cœur déjà penché / J’l’ai revu l’autre soir /Sous un ciel illusoire / Le bitume pour plancher / Cet arbre sans racine / Dont les branches dessinent / Un parcours déformé / La ville est son dortoir / Il a besoin d’pitié / Moins que d’humanité </p>
            <p>Il est toujours digne et pourtant / J’aurais pu m’arrêter, l’écouter / Même modestement / J’ai même pas essayé </p>
            <p>J’aurais pu être cet homme / Qui n’a plus rien ni personne / Combien de coups faut-il ? / Combien de coups faut-il ? / Avant que la vie nous assomme / Il a tout perdu cet homme / Mais personne ne s’en étonne / Combien de temps faut-il ? / Combien de temps faut-il ? / Avant qu’une âme abandonne / S’abandonne / S’abandonne </p>
            <p>J’l’ai appris d’un passant / Que je croise parfois / Au hasard d’un café / On ne le reverra pas / Demain c’est le printemps / L’hiver l’a emporté </p>
            <p>J’aurais pu être cet homme / Qui n’a plus rien ni personne / Combien de coups faut-il ? / Combien de coups faut-il ? / Avant que la vie nous assomme / Il a tout perdu cet homme / Mais personne ne s’en étonne / Combien de temps faut-il ? / Combien de temps faut-il ? / Avant qu’une âme abandonne / S’abandonne / S’abandonne </p>
        `,
        credits: `
            Auteur : Nazim Khaled <br />
            Compositeurs : Nazim Khaled - Assaf Tzrouya<br />
            Éditions : Enfants terribles - Lakkee - VictorEE - Mikkee - JessEE
        `
    },{
        id: "a-la-maison",
        title: "À la maison ",
        slug: "a-la-maison",
        album: "ressources-standard",
        lyric: `
            <p>Mille murmures l’élèvent / Au-dessus du mur du son /Elle touche presque le ciel / Des fenêtres sur rêves / En sont les fondations / Les racines essentielles </p>
            <p>J’l’ai pas choisie mais j’l’aime ainsi / Avec tous ses défauts de fabrication / J’y ai grandi, j’y ai appris </p>
            <p>/ Sans même faire attention </p>
            <p>J’ai froid quand je n’y suis pas / Même si tout me plaît / Tout me va / Tout ira / Des fois et sans raisons / J’préfère y rester / Pour de bon / À la maison </p>
            <p>À la maison </p>
            <p>Elle connaît les averses / Et quelques pots cassés / Jonchent parfois sa cour / Des amis la traversent /Et ceux qui sont passés / L’habitent pour toujours </p>
            <p>C’est un asile un peu fragile / Pourtant j’en admire toutes les dimensions / Ça me fascine, c’est si facile / Que j’me pose plus de questions </p>
            <p>J’ai froid quand je n’y suis pas / Même si tout me plaît / Tout me va / Tout ira / Des fois et sans raisons / J’préfère y rester / Pour de bon / À la maison </p>
            <p>À la maison </p>
            <p>Quand elle sera en ruine / Abandonnée de tous recouverte de tags / Quand les pluies de la ville auront fait trop de vagues / Je n’serai plus de taille / Déposez à ma porte un mot ou quelques fleurs / Même si c’est pas la saison / Rappelez-moi comme c’était bon </p>
            <p>À la maison </p>
            <p>À la maison </p>
        `,
        credits: `
            Auteurs : Nazim Khaled - Jérémy Frérot<br />
            Compositeurs : Nazim Khaled - Jérémy Frérot - Renaud Rebillaud <br />
            Éditions : Enfants terribles - Gohan Music - Mikkee - JessEE - La Finca
        `
    },
    {
        id: "que-seront-les-hommes",
        title: "Que seront les hommes&nbsp;?",
        slug: "que-seront-les-hommes",
        album: "addictions-standard",
        lyric: `
            <p>Tu t’en vas / Depuis que les gens s’en foutent / Et qu’ils ne veulent plus de toi / Tu parles et personne n’écoute / La haine est sourde je crois / Où est-ce que tu vagabondes / Dans ce monde / Il faut que tu leur pardonnes / Mais si tu les abandonnes</p>
            <p>Que seront les hommes / Sans l’amour que la vie leur donne ? / Que seront les hommes / S’ils n’se donnent pas de l’amour en somme ?</p>
            <p>Tu reviens / Ils te voient mais ils t’ignorent / Puis ils te rejettent au loin / Font danser leurs corps encore / De tristes refrains sans fin / Se répandent et se répondent / Dans ce monde / Il faut que tu leur pardonnes / Juste avant que l’heure ne sonne</p>
            <p>Que seront les hommes / Sans l’amour que la vie leur donne ? / Que seront les hommes / S’ils n’se donnent pas de l’amour en somme ?</p>
            <p>Ce que je crois / C’est peut-être à nous de la faire / La paix sur la Terre / Aucun nuage, même le plus sombre / Pour empêcher le vol de la colombe</p>
            <p>Que seront les hommes ? / Que seront les hommes?</p>
            <p>Que seront les hommes / Sans l’amour que la vie leur donne ? / Que seront les hommes / S’ils n’se donnent pas de l’amour en somme ? / Que seront les hommes ? / Que seront les hommes ? / S’ils n’se donnent pas de l’amour en somme ? / Que seront les hommes ? / Seront les hommes ? </p>
        `,
        credits: `
            Auteurs: Nazim Khaled<br />
            Compositeurs: Nazim Khaled-Amir Haddad-Raphael Nyadjiko<br />
            Editions: Bukowski Publishing - Sash Productions - Universkalp
        `
    },
    {
        id: "etats-damour",
        ytId: "UKcWushnRpQ",
        title: "États d'amour",
        slug: "etats-damour",
        album: "addictions-standard",
        lyric: `
            <p>Point final, t’as semé sans sonner / Des points d’interrogation / Je passe mon temps à les escalader / Toutes ces, montagnes de questions / Toi ma beauté mon addiction / J'rejoue notre partition / J'coupe les refrains / Où tu dis que j'suis coupable / J'mets mes fausses notes sur la table / Si tu reviens</p>
            <p>Oublie au moins / Les mots qui nous freinent / Faisons le vide avant qu'la coupe soit pleine / Reprends la main / Je veux dire la mienne / Faisons le plein pour fuir loin de la peine</p>
            <p>(Si) Ton coeur détale / Rupture brutale / Moi l'animal / J'retiens ma respiration / Je compte à rebours / Je guette ton retour / J’suis dans tous mes... / Tous mes états d'amour</p>
            <p>Par essence, j'suis un peu pyromane / J'me brûle de tant de questions / Je te promets qu'si tu retrouves la flamme / J'éteins tes hésitations / J'me fais des films en noir et blanc / J'aurai l'Oscar de l'amant / De l'âme en peine / J’préfère largement l'histoire / Où tu m'embrasses au hasard / J'rejoue la scène</p>
            <p>Oublie au moins / Les mots qui nous freinent / Faisons le vide avant qu'la coupe soit pleine / Reprends la main / Je veux dire la mienne / Faisons le plein pour fuir loin de la peine</p>
            <p>(Si) Ton coeur détale / Rupture brutale / Moi l'animal / J'retiens ma respiration / Je compte à rebours / Je guette ton retour / J’suis dans tous mes... / Tous mes états d'amour </p>
        `,
        credits: `
            Auteurs: Amir Haddad - Nazim Khaled<br />
            Compositeurs: Amir Haddad - Renaud Rebillaud<br />
            Editions: Sash Productions - Bukowski Publishing - Gohan Music
        `
    },
    {
        id: "tout-passe",
        title: "Tout passe",
        slug: "tout-passe",
        album: "addictions-standard",
        lyric: `
            <p>Qu’importe le bruit de nos larmes / Le temps couvrira le vacarme /  Taira notre douleur /  Question de jours ou d’heures /  Comme un sablier sur nos âmes /  Oublier nos bleus et nos blâmes /  Égrener les bonheurs /  Qu’on cache à l’intérieur</p>
            <p>Tous les refrains qu’on respire /  Les anges n’en retiendront que l’air /  Cessons de chanter le pire /  Au pire laissons-nous faire</p>
            <p>On dessine des aquarelles sur nos coeurs / A l’encre de cil, pour en conserver les couleurs / On dessine les battements qu’on a sur le coeur / Quand on tambourine, dans le souffle intime / De nos heures / Tout passe, tout passe, tout passe</p>
            <p>Qu’importe le ton de nos rêves / La couleur est toujours trop brève / On a la main qui tremble / Nos tableaux nous ressemblent / On part on dérive on revient / Entre la rive et le ravin / On hésite on oscille / Nos envies se faufilent</p>
            <p>Frêles et modestes navires / Nos vies tanguent et parfois s’égarent / Si tu sens que tu chavires / Alors vire à l’espoir</p>
            <p>On dessine des aquarelles sur nos coeurs / A l’encre de cil, pour en conserver les couleurs / On dessine les battements qu’on a sur le coeur / Quand on tambourine, dans le souffle intime / De nos heures / Tout passe, tout passe, tout passe</p>
            <p>Jolis prémices, jolies promesses / Que l’on ne tiendra pas / Le temps qui passe est une ivresse / N’ayons plus jamais la gueule de bois / Si l’on devient aveugle comme l’amour / J’apprendrai à lire en braille</p>
            <p>Sur ton corps chaque mot, chaque jour / Tous les détours, tous les détails / Tous les contours, toutes les failles</p>
            <p>On dessine des aquarelles sur nos coeurs / A l’encre de cil, pour en conserver les couleurs / On dessine les battements qu’on a sur le coeur / Quand on tambourine, dans le souffle intime / De nos heures / Tout passe, tout passe, tout passe</p>
            <p>Tout passe</p>
        `,
        credits: `
            Auteurs: Nazim Khaled-Amir Haddad- Tété<br />
            Compositeurs: Nazim Khaled-Amir Haddad- Tété<br />
            Editions: Bukowski Publishing – Sash Productions – BMG rights management - Fefefarworks
        `
    },
    {
        id: "les-rues-de-ma-peine",
        ytId: "0UEWZHT-w-c",
        title: "Les Rues de ma peine",
        slug: "les-rues-de-ma-peine",
        album: "addictions-standard",
        lyric: `
            <p>Chacun parle sa propre langue / Très souvent elle tangue entre le yin et le yang / Tu m’retiens tu me mets des sangles / Quand tu vas trop loin / Il arrive que je m’étrangle</p>
            <p>Les mots de ma pensée m’ont parfois dépassé / Pour une fois viens on efface / Oubliant nos beautés on s’est mis de côté / J’veux qu’on se regarde bien en face</p>
            <p>Je ne squatterai plus les rues de ma peine / Quand j’suis perdu j’veux qu’tu me ramènes / J’veux qu’on aille bien, j’veux qu’on aille loin / Et qu’on traîne / Je ne squatterai plus les rues de ma peine / L’amour ça tue sauf quand tu te démènes / J’veux qu’on fasse rien, j’veux qu’on s’taille loin / Et qu’on traîne</p>
            <p>Le ton monte on descend la pente / Parfois je me plante entre l’amie et l’amante / Les démons chantent et nous démentent / Pour te préserver / Est-ce qu’il faut que je te mente ?</p>
            <p>Suspendus sans arrêt au fil de nos marées / Une vague à l’âme qui nous dépasse / Collés à nos egos, posés comme des legos / J’veux qu’on se laisse un peu d’espace</p>
            <p>Je ne squatterai plus les rues de ma peine / Quand j’suis perdu j’veux qu’tu me ramènes / J’veux qu’on aille bien, j’veux qu’on aille loin / Et qu’on traîne / Je ne squatterai plus les rues de ma peine / L’amour ça tue sauf quand tu te démènes / J’veux qu’on fasse rien, j’veux qu’on s’taille loin / Et qu’on traîne</p>
            <p>Même si l’vent souffle en rafale / Le temps idéal / C’est quand on s’met à l’abri / Pour mater les étoiles</p>
            <p>Je ne squatterai plus les rues de ma peine / Quand j’suis perdu j’veux qu’tu me ramènes / J’veux qu’on aille bien, j’veux qu’on aille loin / Et qu’on traîne / Je ne squatterai plus les rues de ma peine / L’amour ça tue sauf quand tu te démènes / J’veux qu’on fasse rien, j’veux qu’on s’taille loin / Et qu’on traîne</p>
        `,
        credits: `
            Auteurs: Nazim Khaled – Amir Haddad<br />
            Compositeurs: Nazim Khaled – Amir Haddad – Eddy Pradelles<br />
            Editions: Bukowski Publishing – Tick Tone Music – Sash Productions 
        `
    },
    {
        id: "les-rues-de-ma-peine-r3ssources",
        ytId: "0UEWZHT-w-c",
        title: "Les Rues de ma peine",
        slug: "les-rues-de-ma-peine-r3ssources",
        album: "r3ssources",
        lyric: `
            <p>Chacun parle sa propre langue / Très souvent elle tangue entre le yin et le yang / Tu m’retiens tu me mets des sangles / Quand tu vas trop loin / Il arrive que je m’étrangle</p>
            <p>Les mots de ma pensée m’ont parfois dépassé / Pour une fois viens on efface / Oubliant nos beautés on s’est mis de côté / J’veux qu’on se regarde bien en face</p>
            <p>Je ne squatterai plus les rues de ma peine / Quand j’suis perdu j’veux qu’tu me ramènes / J’veux qu’on aille bien, j’veux qu’on aille loin / Et qu’on traîne / Je ne squatterai plus les rues de ma peine / L’amour ça tue sauf quand tu te démènes / J’veux qu’on fasse rien, j’veux qu’on s’taille loin / Et qu’on traîne</p>
            <p>Le ton monte on descend la pente / Parfois je me plante entre l’amie et l’amante / Les démons chantent et nous démentent / Pour te préserver / Est-ce qu’il faut que je te mente ?</p>
            <p>Suspendus sans arrêt au fil de nos marées / Une vague à l’âme qui nous dépasse / Collés à nos egos, posés comme des legos / J’veux qu’on se laisse un peu d’espace</p>
            <p>Je ne squatterai plus les rues de ma peine / Quand j’suis perdu j’veux qu’tu me ramènes / J’veux qu’on aille bien, j’veux qu’on aille loin / Et qu’on traîne / Je ne squatterai plus les rues de ma peine / L’amour ça tue sauf quand tu te démènes / J’veux qu’on fasse rien, j’veux qu’on s’taille loin / Et qu’on traîne</p>
            <p>Même si l’vent souffle en rafale / Le temps idéal / C’est quand on s’met à l’abri / Pour mater les étoiles</p>
            <p>Je ne squatterai plus les rues de ma peine / Quand j’suis perdu j’veux qu’tu me ramènes / J’veux qu’on aille bien, j’veux qu’on aille loin / Et qu’on traîne / Je ne squatterai plus les rues de ma peine / L’amour ça tue sauf quand tu te démènes / J’veux qu’on fasse rien, j’veux qu’on s’taille loin / Et qu’on traîne</p>
        `,
        credits: `
            Auteurs : Amir Haddad – Nazim Khaled<br />
            Compositeurs : Nazim Khaled – Amir Haddad – Eddy Pradelles<br />
            Éditions : Bukowski Publishing – Tick Tone Music – Lakkee – VictorEE<br />
            Réalisé & arrangé par Dudu Tassa  
        `
    },
    {
        id: "il-etait-une-femme",
        title: "Il était une femme",
        slug: "il-etait-une-femme",
        album: "addictions-standard",
        lyric: `
            <p>Elle le porte comme un roi / A bout de coeur / Elle le berce de chaleur / Quand il fait froid / Aussi simplement qu’elle respire elle le comprend / Et c’est comme ça / C’est dans le lit de ses bras / Qu’il dit tout bas</p>
            <p>Parfois, sans voix / Elle dessine des illusions / Quand la joie s’en va / Avec patience et passion / Parfois sans toi, / J’me sens triste comme une main d’pianiste / Qui aurait paumé son envie de jouer / Faut croire que sans toi / J’suis pas doué</p>
            <p>oh oh oh oh oh oh oh oh / Il était une femme / oh oh oh oh oh oh oh oh / Il était une femme</p>
            <p>Elle a toujours un sourire / Et quelques rêves / Pour que dans les moments les pires / Il se relève / Quand le jour décline elle dépose sur sa joue / Sa magie, ses mots doux / L’amour qui le fera tenir / Parmi les fous</p>
            <p>Paris les loups / Tu me guides comme un berger / Noyé de tout / Mais tu m’apprends à nager / Parfois sans toi, / J’me sens triste comme l’oeil sans l’iris / J’ai peur de passer à côté / De la vie et de ses beautés</p>
            <p>oh oh oh oh oh oh oh oh / Il était une femme / oh oh oh oh oh oh oh oh / Il était une femme</p>
            <p>oh oh oh oh oh oh oh oh / Il était une femme / oh oh oh oh oh oh oh oh / Il était une femme</p>
        `,
        credits: `
            Auteurs: Jeremy Poligné-Nazim Khaled-Guillaume Boscaro- Alexandra Maquet<br />
            Compositeurs: Nazim Khaled-Alexandra Maquet-Jeremy Poligné-Amir Haddad-Guillaume Boscaro<br />
            Editions: Bukowski Publishing – Sash Productions – Tick Tone Music
        `
    },
    {
        id: "le-coeur-dans-les-cordes",
        title: "Le Cœur dans les cordes",
        slug: "le-coeur-dans-les-cordes",
        album: "addictions-standard",
        lyric: `
            <p>La vie ne prend jamais de gants non / La vie te pend au fil des désillusions / Elle a frappé, t’étais pas prêt / Mais redresse toi, avance et on verra après</p>
            <p>Soit tu plonges au prend coup qu’on te donne / Soit t’esquives et tu t’bats même quand t’es sonné / Tu t’en fous mais nous on t’aime comme personne et / Tu t’laisses vivre mais on ne va pas t’laisser tomber</p>
            <p>Tu promènes tes sanglots, tes sanglots, tes sanglots</p>
            <p>T’es KO / Une larme encore et t’as le coeur qui déborde / T’es KO / Faut s’relever même le coeur dans les cordes / Dans les cordes, dans les cordes, dans les cordes</p>
            <p>La vie ne prend jamais le temps non / Quand toi tu traînes / Elle ne freine pas pour autant / T’entends même plus les coups d’klaxons / Y’a pas d’Airbags prévus pour les âmes qui déconnent</p>
            <p>Tu t’soustrais à toutes les chances qu’on te donne / Multiplie les incidents de parcours / Sur la route les heures de pluie s’additionnent / Et divisent toute la beauté de tes jours</p>
            <p>Tu calcules tes sanglots, tes sanglots, tes sanglots</p>
            <p>T’es KO / Une larme encore et t’as le coeur qui déborde / T’es KO / Faut s’relever même le coeur dans les cordes / Dans les cordes, dans les cordes, dans les cordes</p>
            <p>Des hématomes / Colorent mon corps d’une tonne de jolies couleurs / Comme un fantôme / J’veux traverser les murs d’la vie sans douleurs</p>
            <p>T’es KO / Une larme encore et t’as le coeur qui déborde / T’es KO / Faut s’relever même le coeur dans les cordes / Dans les cordes, dans les cordes, dans les cordes</p>
            <p>T’es KO, t’es KO / T’es KO, t’es KO / Faut s’relever même le coeur dans les cordes / Dans les cordes, dans les cordes, dans les cordes </p>
        `,
        credits: `
            Auteurs: Nazim Khaled- Amir Haddad<br />
            Compositeurs: Silvio Lisbonne- Nazim Khaled – Amir Haddad<br />
            Editions: Bukowski Publishing – Sash Productions – Tick Tone Music 
        `
    },
    {
        id: "sors-de-ma-tete",
        title: "Sors de ma tête",
        slug: "sors-de-ma-tete",
        album: "addictions-standard",
        lyric: `
            <p>Je t’appelle, je t’appelle…</p>
            <p>On s’est pris une vague de trop / On a fait tanguer le bateau / On met les voiles / J’suis parti, j’avais les yeux mouillés / Dans le bonheur je t’ai vu nager / Moi le naufragé sans escale</p>
            <p>Les paradis artificiels / Et tous les pseudos septièmes-ciels / J’ai tous voulu les traverser / pour me sentir bercé par l’amour éternel / Que l’on a pas su se donner / Mon coeur est encore étonné / Mais je n’oublie rien de tes mains, de ta voix, de tes pas</p>
            <p>Sors de ma tête / De ma peau sors, j’suis plus qu’un corps / Au bord de l’eau / Sors de ma tête / J’essaie encore, mais j’suis moins fort / Que ton écho / Et je t’appelle, je t’appelle, je t’appelle (comme un écho) / Et je t’appelle, je t’appelle, je t’appelle</p>
            <p>J’ai versé une larme de trop / J’ai failli noyer mon ego / Ca m’est égal / Pour tous les jolis mots qu’tu voulais / Y’a rien à faire je suis rouillé / J’ai trop de clous sous mes écailles</p>
            <p>Les paradis artificiels / Ont la couleur du septième ciel / Mais quand la douleur nous noircit / On finit indécis si loin de l’essentiel / Que l’on a pas su se donner / Ton corps n’a pas su pardonner / Mes ailes se brisent et me disent que tu t’es envolée</p>
            <p>Sors de ma tête / De ma peau sors, j’suis plus qu’un corps / Au bord de l’eau / Sors de ma tête / J’essaie encore, mais j’suis moins fort / Que ton écho / Et je t’appelle, je t’appelle, je t’appelle (comme un écho) / Et je t’appelle, je t’appelle, je t’appelle</p>
            <p>Ton écho, / Qui m’écorche sous l’écorce je m’efforce de l’oublier / Ton écho / N’a même plus de mots</p>
            <p>Sors de ma tête / De ma peau sors, j’suis plus qu’un corps / Au bord de l’eau / Sors de ma tête / J’essaie encore, mais j’suis moins fort / Que ton écho / Et je t’appelle, je t’appelle, je t’appelle (comme un écho) / Et je t’appelle, je t’appelle, je t’appelle</p>
        `,
        credits: `
            Auteurs: Nazim Khaled – Amir Haddad<br />
            Compositeurs: Nazim Khaled – Amir Haddad - Skalpovich<br />
            Editions: Bukowski Publishing – Sash Productions - Universkalp 
        `
    },
    {
        id: "sors-de-ma-tete-r3ssources",
        title: "Sors de ma tête",
        slug: "sors-de-ma-tete-r3ssources",
        album: "r3ssources",
        lyric: `
            <p>Je t’appelle, je t’appelle…</p>
            <p>On s’est pris une vague de trop / On a fait tanguer le bateau / On met les voiles / J’suis parti, j’avais les yeux mouillés / Dans le bonheur je t’ai vu nager / Moi le naufragé sans escale</p>
            <p>Les paradis artificiels / Et tous les pseudos septièmes-ciels / J’ai tous voulu les traverser / pour me sentir bercé par l’amour éternel / Que l’on a pas su se donner / Mon coeur est encore étonné / Mais je n’oublie rien de tes mains, de ta voix, de tes pas</p>
            <p>Sors de ma tête / De ma peau sors, j’suis plus qu’un corps / Au bord de l’eau / Sors de ma tête / J’essaie encore, mais j’suis moins fort / Que ton écho / Et je t’appelle, je t’appelle, je t’appelle (comme un écho) / Et je t’appelle, je t’appelle, je t’appelle</p>
            <p>J’ai versé une larme de trop / J’ai failli noyer mon ego / Ca m’est égal / Pour tous les jolis mots qu’tu voulais / Y’a rien à faire je suis rouillé / J’ai trop de clous sous mes écailles</p>
            <p>Les paradis artificiels / Ont la couleur du septième ciel / Mais quand la douleur nous noircit / On finit indécis si loin de l’essentiel / Que l’on a pas su se donner / Ton corps n’a pas su pardonner / Mes ailes se brisent et me disent que tu t’es envolée</p>
            <p>Sors de ma tête / De ma peau sors, j’suis plus qu’un corps / Au bord de l’eau / Sors de ma tête / J’essaie encore, mais j’suis moins fort / Que ton écho / Et je t’appelle, je t’appelle, je t’appelle (comme un écho) / Et je t’appelle, je t’appelle, je t’appelle</p>
            <p>Ton écho, / Qui m’écorche sous l’écorce je m’efforce de l’oublier / Ton écho / N’a même plus de mots</p>
            <p>Sors de ma tête / De ma peau sors, j’suis plus qu’un corps / Au bord de l’eau / Sors de ma tête / J’essaie encore, mais j’suis moins fort / Que ton écho / Et je t’appelle, je t’appelle, je t’appelle (comme un écho) / Et je t’appelle, je t’appelle, je t’appelle</p>
        `,
        credits: `
            Auteurs : Amir Haddad – Nazim Khaled<br />
            Compositeurs : Nazim Khaled – Amir Haddad – Skalpovich<br />
            Éditions : Bukowski Publishing – Lakkee – VictorEE – Universkalp<br />
            Réalisé & arrangé par Dudu Tassa 
        `
    },
    {
        id: "l-amourant",
        title: "L'Amourant",
        slug: "l-amourant",
        album: "addictions-standard",
        lyric: `
            <p>Extérieur jour par hasard comme toujours / Tu passes et je souris / Je sais par coeur ton parcours / Pourtant je fais comme si / Ton fin sillage, bel appel au voyage / Efface le bruit de fond et les passants / Je reste en silence / Seul avec mon émoi </p>
            <p>Et moi tout ce temps la / Je crie d'amour mais tu n'entends pas / On se croise souvent / Je suis ton amourant… / Qui la nuit fait tout bas / Des rêves qui ne parlent que de ça / Parfois je m’y noie / Ce manque de toi / Mais promis demain je me lance… / Promis demain je me lance</p>
            <p>Intérieur soir en solo au comptoir / Mon restau favori / Là j’ai du mal a y croire… / Et pourtant te voici / Impétueux je me dresse sur le bar / Et clame aux yeux du monde mon tourment / (puis j’) M’éveille en silence, / Seul avec mon émoi </p>
            <p>Et moi tout ce temps la / Je crie d'amour mais tu n'entends pas / On se croise souvent / Je suis ton amourant… / Qui la nuit fait tout bas / Des rêves qui ne parlent que de ça / Parfois je m’y noie / Ce manque de toi / Mais promis demain je me lance… / Promis demain je me lance </p>
        `,
        credits: `
            Auteurs: Amir Haddad - Tété<br />
            Compositeurs: Amir Haddad - Davide Esposito<br />
            ditions: Sash Productions – Bmg rights management – Fefefarworks - Peermusic
        `
    },
    {
        id: "opium",
        title: "Opium",
        slug: "opium",
        album: "addictions-standard",
        lyric: `
            <p>Séparés de toi / Mes yeux commencent à se perdre / C’est l’enfer quand tu m’fais cet effet là / Mes amis cent fois / M’ont dit que j’avais la fièvre / Une chaleur que j’ignorais jusque là</p>
            <p>Toi mon opium la seule qui me donne vie / Quand tu t’imposes sur mes lèvres / J’suis sous l’eau-pium / J’ai les sens en sursis / Suspendu à une dose de rêve</p>
            <p>T’es mon opium / Tu m’as consumé, comment assumer ? / T’es mon opium / Mon amertume est partie en fumée / T’es mon opium</p>
            <p>J’ai dormi longtemps / T’es plus là quand je t’appelle / Je voudrais qu’tu reviennes et de surcroit / Remonter le temps / Mon amnésie se réveille / On s’connait d’où viens-tu ? Tu me tues toi</p>
            <p>J’suis sous l’eau-pium / Quand je fuis l’incendie / Ta peau vient souffler sur mes braises / J’suis sous l’eau comme / Un homme qui a compris / Qu’un jour toutes les envies se taisent</p>
            <p>T’es mon opium / Tu m’as consumé, comment assumer ? / T’es mon opium / Mon amertume est partie en fumée / T’es mon opium</p>
            <p>T’es mon opium</p>
            <p>J’suis sous l’eau-pium / J’ai les sens en sursis / Suspendu à une dose de rêve / T’es mon opium</p>
            <p>Tu m’as consumé, comment assumer ? / T’es mon opium / Mon amertume est partie en fumée / T’es mon opium / T’es mon opium </p>
        `,
        credits: `
            Auteurs: Nazim Khaled – Amir Haddad<br />
            Compositeurs: Amir Haddad – Nazim Khaled – Matthieu Tosi - Laurent Wilthien - Jean-Noël Wilthien<br />
            Editions: Bukowski Publishing – Sash Productions – Warner Chappell Music France
        `
    },
    {
        id: "que-le-temps-s-arrete",
        title: "Que le temps s'arrête",
        slug: "que-le-temps-s-arrete",
        album: "addictions-standard",
        lyric: `
            <p>Y’a tous ces rêves que j’veux vivre éveillé / J’ai 24 heures pour essayer / Demain seront-ils balayés ? / Y’a ces histoires que j’veux vous raconter / Y’a toutes les peurs que j’veux dompter / Avant de rire à leur santé</p>
            <p>Ton corps loge / Dans l’horloge de mes pensées / Je vois les aiguilles danser</p>
            <p>Que le temps s’arrête / Que le temps s’arrête / J’veux traverser le monde ne long en large en travers / Explorer mes années jusqu’à ce qu’elles deviennent lumières / Sans jamais voir faner ni les roses, ni les prières / Que le temps s’arrête / Que le temps s’arrête / J’veux traverser la vie quitte à marcher de travers / La chance est de sortie ce soir elle te paye un verre / Et si on apprécie, on trinque même à nos hivers</p>
            <p>Y’a toutes mes peaux par des flèches traversées / Ces mélodies que j’ai versé / Y’a toutes ces larmes qui m’ont bercé / J’ai marché sur tant de fils barbelés / Entre deux monde écartelé / A mesure que tu m’appelais</p>
            <p>Ton corps loge / Dans l’horloge d’une vie passée / Avant qu’on soit dépassé</p>
            <p>Que le temps s’arrête / Que le temps s’arrête / J’veux traverser le monde ne long en large en travers / Explorer mes années jusqu’à ce qu’elles deviennent lumières / Sans jamais voir faner ni les roses, ni les prières / Que le temps s’arrête / Que le temps s’arrête / J’veux traverser la vie quitte à marcher de travers / La chance est de sortie ce soir elle te paye un verre / Et si on apprécie, on trinque même à nos hivers</p>
            <p>Oh oh oh oh oh / oh oh oh oh oh / Une pente qui nous plante le long des heures errantes et / Oh oh oh oh oh / oh oh oh oh oh / On s’attire, on se tente dans des décors qui mentent / Si on court, si on court / On finira éreinté</p>
            <p>Que le temps s’arrête / Que le temps s’arrête / J’veux traverser le monde ne long en large en travers / Explorer mes années jusqu’à ce qu’elles deviennent lumières / Sans jamais voir faner ni les roses, ni les prières / Que le temps s’arrête / Que le temps s’arrête / J’veux traverser la vie quitte à marcher de travers / La chance est de sortie ce soir elle te paye un verre / Et si on apprécie, on trinque même à nos hivers</p>
            <p>oh oh oh oh oh / oh oh oh oh oh </p>
        `,
        credits: `
            Auteurs: Nazim Khaled – Amir Haddad<br />
            Compositeurs: Nazim Khaled – Amir Haddad - Pierre-Laurent Faure – Adrien Levron<br />
            Editions: Sash Productions – Bukowski Publishing – Tick Tone Music 
        `
    },
    {
        id: "ideale-idylle",
        title: "Idéale idylle <span>(avec Lital)</span>",
        slug: "ideale-idylle",
        album: "addictions-standard",
        lyric: `
            <p>Combien de nuits nous restent / Combien de tendres gestes / Est-ce qu'on a passé la moitié de l'histoire / Sans le savoir peut-être / Il faudra se soumettre / À l'amour qui meurt et largue les amarres</p>
            <p>Si demain elle tombait / De haut, haut / Notre idéale idylle, / Idéale idylle / On marche sur un fil / On marche sur un fil</p>
            <p>Ma belle insolente / Si la nuit est lente / Peut-elle durer toute une vie ? / Mes jours se suspendent / À ton corps qui tremble / Tes yeux couleur insomnie / Ma belle insolente / Quand ta peau me tente / Tu fais renaître l'envie / La nuit nous aimante / Mais les jours nous mentent / Dans mon coeur c'est l'incendie</p>
            <p>Encore un peu d'ivresse / Avant que l'on se laisse / Tout est lourd quand pour l’amour il se fait tard / Le destin nous bouscule / Si demain tout bascule / J’irai chercher une obscure lueur d’espoir</p>
            <p>Je n'veux pas qu'elle tombe de haut, haut / Notre idéale idylle / Idéale idylle / Le bonheur est fragile / Le bonheur est fragile</p>
            <p>Ma belle insolente / Si la nuit est lente / Peut-elle durer toute une vie / Mes jours se suspendent / À ton corps qui tremble / Tes yeux couleur insomnie / Ma belle insolente / Quand ta peau me tente / Tu fais renaître l'envie / La nuit nous aimante / Mais les jours nous mentent / Dans mon coeur c'est l'incendie</p>
            <p>On se donne des coups on se pardonne, assommés mais insoumis. / On s’étonne quand la vie nous questionne sur ce qu’on s’était promis / Il reste un feu qui nous lie, je ne suis plus qu’incendie…</p>
            <p>Ma belle insolente / Si la nuit est lente / Peut-elle durer toute une vie / Mes jours se suspendent / À ton corps qui tremble / Tes yeux couleur insomnie / Je suis insolente / Quand ta peau me tente / Tu fais renaître l'envie / La nuit nous aimante / Mais les jours nous mentent / Dans mon coeur c'est l'incendie </p>
        `,
        credits: `
            Auteurs: Nazim Khaled – Amir Haddad<br />
            Compositeurs: Nazim Khaled – Amir Haddad<br />
            Editions: Bukowski Publishing – Sash Productions
        `
    },
    {
        id: "laisse-la-vie-faire",
        title: "Laisse la vie faire",
        slug: "laisse-la-vie-faire",
        album: "addictions-standard",
        lyric: `
            <p>On vit tous les mêmes scènes / Les poches pleines de nos peines / Et le vide au fond / On s'déchire, on s'démène / On attend qu'la vie vienne / Nous bercer d'illusions / On est comme la rivière / On a peur de l'hiver / Quand nos rires s'en vont / On finit par le voir à moitié plein le verre / Accepter nos saisons</p>
            <p>On compte, on compte on compte / Sur demain / On compte sur le retard du train / Le train du Temps qui va trop vite / On compte, on compte sur soi / Ou sur les autres / On compte les points / On compte les fautes / Mais il n'y a pas vraiment d'arbitre</p>
            <p>Laisse là, laisse la vie faire / Elle te guérit de tout, tout au fond / Laisse là, laisse la vie faire / Elle a, elle a de l'imagination / Laisse là, laisse la vie faire / Ferme les yeux pour avoir la vision / Si parfois ton coeur vocifère / Elle imagine un nouvel horizon</p>
            <p>On s'condamne on s'pardonne / On se damne on se donne / Des sourires d'acteurs / On s'élime, on s'allume / Rendez vous sur la lune / Oublie la pesanteur / Le présent méprisant / Laissera place au mouvement / D’un futur meilleur / Le pendule nous bouscule / On se cherche une bulle / Pour arrêter les heures</p>
            <p>On monte, on monte, / on monte vers les cieux / L'absence plantée comme un pieu / On s'habitue à nos épines / On monte et même aimanté par le bas / Si le bonheur ne parle pas / Il y'a sa voix que l'on devine</p>
            <p>Laisse là, laisse la vie faire / Elle te guérit de tout, tout au fond / Laisse là, laisse la vie faire / Elle a, elle a de l'imagination / Laisse là, laisse la vie faire / Ferme les yeux pour avoir la vision / Si parfois ton coeur vocifère / Elle imagine un nouvel horizon</p>
            <p>Faut qu'on capture, capture, capture / Chaque palpitation / Mettons cap sur, cap sur l'azur / Sans hésitation / Dans la capsule, capsule, capsule / En lévitation / Mettons cap sur cap sur l'azur / De nos vies... brations</p>
            <p>Laisse là, laisse là / Laisse là, laisse là / Laisse là, laisse là / Laisse là, laisse là</p>
            <p>Laisse là, laisse la vie faire / Elle te guérit de tout, tout au fond / Laisse là, laisse la vie faire / Elle a, elle a de l'imagination / Laisse là, laisse la vie faire / Ferme les yeux pour avoir la vision / Si parfois ton coeur vocifère / Elle imagine un nouvel horizon</p>
            <p>Laisse là, laisse la vie faire / Laisse là, laisse la vie faire / Laisse là, laisse la vie faire / Laisse là, laisse la vie faire </p>
        `,
        credits: `
            Auteurs: Nazim Khaled<br />
            Compositeurs: Nazim Khaled – Raphael Nyadjiko<br /> 
            Editions: Bukowski Publishing – Universkalp – Sash Productions 
        `
    },
    {
        id: "no-vacancy",
        title: "No Vacancy <span>(ft. OneRepublic)</span>",
        slug: "no-vacancy",
        album: "addictions-standard",
        lyric: `
            <p>I used to leave the doors unlocked and leave the lights on / I used to stay awake just counting hours all night long / I had so many empty rooms inside the chateau, yeah</p>
            <p>J’ai voulu partager mon espace et mon ego / Et trouver l’invité qui pansera tous mes mots / Tu rempliras toutes les pièces vides de mon château</p>
            <p>But ever since I met you</p>
            <p>No vacancy, tu combles tout / J’te remercie, because of you / There’s no vacancy ever since I met you / No vacancy, because of you</p>
            <p>C’est pour tous ces vides que tu as rempli / Mon coeur est solide, je te remercie / Used to be that I felt so damn empty / Ever since I met you, no vacancy / J’te remercie / No vacancy</p>
            <p>C’est pour tous ces vides que tu as rempli / Mon coeur est solide, je te remercie / Used to be that I felt so damn empty / Ever since I met you, no vacancy / J’te remercie / No vacancy</p>
            <p>Comme la morale qui donne tout le sens à la fable / Le goût est meilleur d’puis qu’tu t’invites à ma table / Tu rempliras toutes les pièces vide de mon château</p>
            <p>But ever since I met you</p>
            <p>No vacancy, tu combles tout / J’te remercie, because of you / There’s no vacancy ever since I met you / No vacancy, because of you</p>
            <p>C’est pour tous ces vides que tu as rempli / Mon coeur est solide, je te remercie / Used to be that I felt so damn empty / Ever since I met you, no vacancy / J’te remercie / No vacancy</p>
            <p>Oh yeah / Donne moi, donne moi le sens / Oh yeah / Donne moi, donne moi le sens / Oh yeah / Le contresens s’arrête où tu commences</p>
            <p>No vacancy, no vacancy</p>
            <p>C’est pour tous ces vides que tu as rempli / Mon coeur est solide, je te remercie / Used to be that I felt so damn empty / Ever since I met you, no vacancy / J’te remercie / No vacancy</p>
            <p>Oh yeah / Donne moi, donne moi le sens / Oh yeah / Donne moi, donne moi le sens / Oh yeah / Le contresens s’arrête où tu commences</p>
            <p>No vacancy</p>
        `,
        credits: `
            MIKKEL SONGS Inc. (ASCAP), EMI April Music Inc (ASCAP) obo EMI Music Publishing LTD (PRS), Write Me A Song Publishing, Administered by Downtown Music Publishing LLC (GMR)<br />
            Written by Ryan Tedder<br />
            Written by Tor Erik Hermansen<br />
            Written by Mikkel Storleer Eriksen<br />
            French lyrics written by Amir Haddad.<br />
            Published By: Downtown Music Publishing LLC / EMI April Music Inc.<br />
            French lyrics published by: Sash Productions
        `
    },
    {
        id: "la-nuit",
        title: "La Nuit",
        slug: "la-nuit",
        album: "addictions-standard",
        lyric: `
            <p>Au coeur de la ville / Désertée comme ses artères / A côté des mégots par terre / Quelques flaques scintillent / Personne dans la file / Juste des amants solaires / Quelques amours rudimentaires / Finissent en battements de cils</p>
            <p>Qu’est-ce qu’elle est belle / Et qu’est-ce qu’elle sourit / C’est elle qui m’éclaire ouais / Quand le jour me nuit / Et quand elle m’appelle / Ma peine s’oublie / C’est elle qui m’éclaire / Quand mes cernes ont le gout de mes insomnies</p>
            <p>I wonder if you see me coming / When you call my name / When you call my name / The land is dark and the moon is calling / I’ll be there at the end of the day</p>
            <p>Au coeur de la ville / Nos instincts s’envoient en l’air / Quand notre amour prend des charters / On se crash avec style / C’est l’heure de la nuit / Le décor et son envers / Nous ont livré tous leurs mystères / Sur l’asphalte fragile</p>
            <p>Mais qu’est ce qu’elle est belle / Sauf quand elle s’enfuit / Quand l’aube m’appelle je me perds / Dans un ralenti / Dernière étincelle, / La lune me suit / Je prends des détours / Comme toujours jusqu’à l’insomnie</p>
            <p>I wonder if you see me coming / When you call my name / When you call my name / The land is dark and the moon is calling / I’ll be there at the end of the day</p>
            <p>Encore tu m’appelles / Vers quoi tu m’amènes / Même les coup qu’tu m’assènes / Je crois que je les aime / Quand j’me promène / Tu noies mes problèmes / Dans toutes tes fontaines</p>
            <p>I wonder if you see me coming / When you call my name / When you call my name / The land is dark and the moon is calling / I’ll be there at the end of the day</p>
            <p>I’ll be there at the end of the day… / I’ll be there at the end…</p>
        `,
        credits: `
            Auteurs: Nazim Khaled- Amir Haddad<br />
            Compositeurs: Nazim Khaled – Amir Haddad – Eddy Pradelles – Silvio Lisbonne<br />
            Editions: Bukowski Publishing – Tick Tone Music – Sash Productions
        `
    },
    {
        id: "anja",
        ytId: "wo1PeQ0vAI8",
        title: "Anja",
        slug: "anja",
        album: "addictions-standard",
        lyric: `
            <p>Anja, / Toi dont les yeux brillent quand même / Petite luciole quand tout s’éteint / Anja, / Cette chanson que tu aimes, / Quand tu me regardes elle me revient / Elle dit que la vie n’est pas cruelle / Que tous les hommes nous sont pareils / Que si on leur donne du bonheur / Ils finiront par devenir meilleurs / Anja pardonne leur</p>
            <p>Anja, / Les enfants naissent lumière / Certains s’assombrissent en chemin / Anja, / La couleur que je préfère, / C’est le rose de la paume de ta main / Prends mon bras serre le encore plus fort / L’amour peut repousser la mort / Cet invisible dictateur / Je vois perler des larmes sur ton coeur / En rivière de pudeur</p>
            <p>Anja, / Nos espoirs faits de dentelles / Ne tiennent qu’au fil de nos destins / Anja, / Garde tes gants demoiselle / Pour essuyer nos peaux de chagrins / Tu dis que le chagrin se traverse / Que la douleur n’est qu’une averse / Qu’il ya un soleil pour demain / Toi qui m'appelle d’un sourire enfantin / Caché dans son écrin</p>
            <p>Anja, / Nous prenons des trains fantômes / Il y’a parfois de tristes voyages / Anja, / Tu bouleverses mes atomes / Toi l’électron libre sans bagage / Petite poupée de porcelaine / Lorsque la folie se déchaine / En mosaïque de douleurs / Petite reine à la couronne de fleurs / Anja pardonne leur</p>
            <p>Anja, / Peut-on s’aimer comme des frères ? / Nos parents étaient si différents / Anja / Tu es des millions sur Terre / Le prénom n’est pas très important / Que tu portes une croix ou une étoile / Un petit point rouge ou un voile / La réalité est la même / Et puisqu’on récolte ce que l’on sème / Je t’offre ce poème</p>
            <p>Anja, / Un oiseau aux ailes blanches / Est venu se poser près de toi / Anja, / La haine a scié la branche / Et le bonheur est tombé bien bas / Pourvu que nos espoirs se relèvent / Que dans nos coeurs coulent la sève / Et les embruns des jours meilleurs / Au fond la barbarie n’est qu’une erreur / Anja pardonne leur</p>
            <p>Anja, / Il pleut sur ton innocence / Et goutte à goutte on se dit adieu / Anja, / Tu gardes une part d’enfance / Comme si la vie n’était qu’un grand jeu / J’aimerais tellement avoir ta force / Avoir ce printemps sous l’écorce / D’un arbre que je sais trop vieux / Marche plus loin moi je m’arrête là / Anja pardonne moi</p>
            <p>Anja / Toi dont les yeux brillent quand même / Petite luciole quand tout s’éteint / Anja, / Cette chanson que tu aimes, / Quand tu me regardes elle me revient / Elle dit que la vie n’est pas cruelle / Que tous les hommes nous sont pareils / Que si on leur donne du bonheur / Ils finiront par devenir meilleurs / Anja pardonne leur…</p>
        `,
        credits: `
            Auteurs: Nazim Khaled – Guillaume Boscaro<br />
            Compositeurs: Nazim Khaled – Guillaume Boscaro<br />
            Editions: Bukowski Publishing – Tick Tone Music – Sash Productions
        `
    },
    {
        id: "et-toi",
        title: "Et toi <span>(titre bonus)</span>",
        slug: "et-toi",
        album: "addictions-standard",
        lyric: `
            <p>On est quoi ?  / Deux victimes d'une histoire qui s'enlise / On veut quoi ?  / Se sevrer de nos corps, mais quelle emprise / On se perd droit dans l'mur des amants trop déçus / On se terre, on vit sous les draps nus, suspendus / C'est pas rien de pleurer à mon âge / J'y peux rien, c'est quand ça m'fait du mal que je vais bien </p>
            <p>Sauve-toi jusqu'à ramper... / Attends / Faut que j'me noie pour te sauver à temps / Allez sauve-toi ou attends-moi / Je sais plus trop où on va / Je sais plus trop c'qui nous va / Et toi ? </p>
            <p>On est quoi... Et toi… On est quoi… On veut quoi... </p>
            <p>On est quoi ?  / Deux victimes dont la morale agonise / On veut quoi ?  / Se sevrer de ces torts qu'on déguise / C'est pas bien de s'mentir à mon âge / J'y peux rien, c'est quand ça m'fait du mal que je vais bien </p>
            <p>Sauve-toi jusqu'à ramper... / Attends / Faut que j'me noie pour te sauver à temps / Allez sauve-toi ou attends-moi / Je sais plus trop où on va / Je sais plus trop c'qui nous va / Et toi ? </p>
            <p>On est quoi... Et toi… On est quoi… On veut quoi... </p>
        `,
        credits: `
            Auteurs: Amir Haddad- Eric Greff<br />
            Compositeurs: Amir Haddad - Eric Greff - Matthieu Tosi - Laurent Wilthien - Jean-Noël Wilthien<br />
            Editions: Bukowski Publishing – Sash Productions - Warner Chappell Music Fran
        `
    },
    {
        id: "l-impasse",
        title: "L'Impasse <span>(titre bonus)</span>",
        slug: "l-impasse",
        album: "addictions-standard",
        lyric: `
            <p>J’ai pas vu vu l’impasse  / Dans laquelle ton coeur s’est caché  / Nos jours qui s’entassent  / M’ont peut-être un peu dépassé  </p>
            <p>Mais si tu me dis que ça fait mal  / J’te répondrai que c’est banal  / Même si c’est triste à en crever  / Peut-être que dans un monde idéal  / On pourrait éviter les balles  / Que la vie s’amuse à tirer  </p>
            <p>Oh oh oh oh  / All along the way  / Looking for the safest distance  / Trying to keep up the pace  / Oh oh oh oh  / Don’t you be afraid  / I’ll be there to save you come what may </p>
            <p>J’ai pas vu la glace  / Dans laquelle ton coeur s’est figé  / Les boulevards que l’on trace  / Parfois nous mènent place des regrets  </p>
            <p>Mais si je te dis que la distance  / Malgré le jeu des apparences  / N’a jamais blanchi nos couleurs  / Réponds-moi que c’est pas perdu d’avance  / Qu’on n’a qu’à mettre un peu d’essence  / Pour relancer le bonheur </p>
            <p>Oh oh oh oh  / All along the way  / Looking for the safest distance  / Trying to keep up the pace  / Oh oh oh oh  / Don’t you be afraid  / I’ll be there to save you come what may  </p>
            <p>Oh oh oh oh  / All along the way  / On a speed that has no limits / You’re the risk I wanna take  / Oh oh oh oh  / Don’t you be afraid  / Making sure our hearts will never brake  </p>
            <p>On n’a plus de roue de secours  / On n’a plus d’autre recours  / Que d’rouler même si c’est bancal  / Vers une histoire pas idéale  / Mais qui a le mérite d’exister  / De subsister, de résister  / Dans notre joli champ de bataille  </p>
            <p>Oh oh oh oh  / Oh oh oh oh  / Oh oh oh oh  / Oh oh oh oh  </p>
            <p>Oh oh oh oh  / All along the way  / Looking for the safest distance  / Trying to keep up the pace  / Oh oh oh oh  / Don’t you be afraid  / I’ll be there to save you come what may  / I’m making sure our hearts will never brake </p>
        `,
        credits: `
            Auteurs: Nazim Khaled – Amir Haddad – Alexandra Maquet – Jeremy Poligné<br />
            Compositeurs: Nazim Khaled –Alexandra Maquet – Jeremy Poligné<br />
            Editions: Bukowski Publishing – Tick tone music – Sash Productions 
        `
    },
    {
        id: "boreale-aurore",
        title: "Boréale aurore <span>(titre bonus)</span>",
        slug: "boreale-aurore",
        album: "addictions-standard",
        lyric: `
            <p>On s’est arrêté  une seconde après qu’la beauté  / Se noie dans la masse  / On est arrivé juste après que l’humanité  / S’abime et se lasse  / Nos nuits se démènent  / On ne sait plus où elles nous mènent  / Peut-être vers le nord  / Lumière d’une boréale aurore  </p>
            <p>Dis-moi  / Faut-il descendre de l’arche / Alors que le monde est en marche  / Sur une route à grande vitesse ? / Sans cesse  / Dis-moi  / Que va-t-on laisser comme trace ? / Juste une empreinte sur la glace  / Tandis que le froid nous blesse  / Sans cesse  </p>
            <p>On s’est habillé du drap de la modernité  / Est-ce que c’est une chance ? / Les forêts brulées qui finissent en bout de papiers  / Pour écrire l’errance  / Nos jours se déchirent  / Entre vérité et désir  / Aveuglés au milieu  / Des cristaux d’espoir dans les yeux  </p>
            <p>Dis-moi  / Faut-il descendre de l’arche  / Alors que le monde est en marche  / Sur une route à grande vitesse ? / Sans cesse  / Dis-moi  / Que va-t-on laisser comme trace ? / Juste une empreinte sur la glace  / Tandis que le froid nous blesse  / Sans cesse  </p>
            <p>Nos nuits, nos nuits se démènent  / On n’sait plus où elles nous mènent  / L’envie, l’envie nous enchaine  / Sur l’autel des jours de peines / Et je cherche en vain le nord  / Et je cherche en vain le nord  / Lumière d’une boréale aurore  / Lumière d’une boréale aurore  </p>
            <p>Dis-moi  / Faut-il descendre de l’arche  / Alors que le monde est en marche  / Sur une route à grande vitesse ? / Sans cesse  / Dis-moi  / Que va-t-on laisser comme trace ? / Juste une empreinte sur la glace  / Tandis que le froid nous blesse  / Sans cesse </p>
        `,
        credits: `
            Auteurs: Nazim Khaled – Amir Haddad<br />
            Compositeurs: Nazim Khaled – Amir Haddad –Skalpovich<br />
            Editions: Bukowski Publishing – Sash Productions - Universkalp 
        `
    },
    {
        id: "longtemps",
        ytId: "t_0C9rQBCSE",
        title: "Longtemps",
        slug: "longtemps",
        album: "addictions-reedition",
        lyric: `
            <p>Je veux des problèmes, / Je veux que tes galères deviennent les miennes, / Je veux que tu me balances au visage tes orages, tes peines / Pour des nuits diluviennes</p>
            <p>Je veux qu'on s'apprenne, / Je veux partager tes joies tes migraines, / Ton corps me donne le vertige et tes mains me mènent / Où rien ne nous gêne</p>
            <p>Je pourrais me tatouer notre histoire sur le bras, / Me mettre dans de beaux draps si t'es avec moi, / C'est toi dans ce monde de fous / Je le sais c'est tout</p>
            <p>Et je voudrais que ça dure longtemps, / User ma peau sur la tienne, / Un jour j'oublierai tout jusqu'à mon nom je saurai simplement / Que t'es là, que t'es belle que t'es mienne</p>
            <p>Je voudrais que ça dure cent ans / Que jamais la raison n'atteigne / Cette voix de la vie qui nous dit de rêver quand même / Jusqu'à ce qu'un jour nos deux yeux s'éteignent</p>
            <p>Mon évidence, t'es mon sourire et ma carte chance / Je veux qu'on déconne / Qu'on décolle, qu'on délire, qu'on danse jusqu'au dernier silence</p>
            <p>J'irai dans la rue si t'as besoin d'un toit / Je pourrais vivre nu pour que t'aies un peu moins froid / C'est toi dans ce monde de fou / Je le sais c'est tout</p>
            <p>Et je voudrais que ça dure longtemps / User ma peau sur la tienne / Un jour j'oublierai tout jusqu'à mon nom je saurai simplement / Que t'es là, que t'es belle que t'es mienne</p>
            <p>Je voudrais que ça dure cent ans / Que jamais la raison n'atteigne / Cette voix de la vie qui nous dit de rêver quand même / Jusqu'à ce qu'un jour nos deux yeux s'éteignent</p>
            <p>Et je voudrais que ça dure longtemps / User ma peau sur la tienne / Un jour j'oublierai tout jusqu'à mon nom je saurai simplement que t'es là / Que t'es belle que t'es mienne</p>
            <p>Je voudrais que ça dure cent ans / Que jamais la raison n'atteigne / Cette voix de la vie qui nous dit de rêver quand même / Jusqu'à ce qu'un jour nos deux yeux s'éteignent</p>
        `,
        credits: `
            Auteur: Nazim Khaled<br />
            Compositeur : Assaf Tzrouya<br />
            Editeurs: Lakkee - Victoree - Bukowski Publishing 
        `
    },
    {
        id: "smile",
        title: "Smile",
        slug: "smile",
        album: "addictions-reedition",
        lyric: `
            <p>Ça m’donne l’air un peu naïf / Cette façon de me marrer / Ce bonheur communicatif / Ma peine, peine à démarrer / C’est pas que je souris bêtement / Mais le bonheur c’est maintenant / Et si l’amour est un vêtement / J’avoue je le porte fièrement </p>
            <p>Faut se rendre à l’évidence / Devant nous le monde immense / Faut qu’on y joue, qu’on y danse / Laisser de côté les mots </p>
            <p>Smile, smile / Don’t you ever doubt or wonder why, why </p>
            <p>It works like a wonder, fool, fool / Be a fool, it happens to be cool, cool / Bend your lips, bend the rules / Bend your lips, bend the rules / Bend your lips / Bend your lips, bend the rules / Bend your lips</p>
            <p>Faire la gueule ça me fatigue / J’fais des grimaces inversées / En eaux douces je navigue / Mon sourire n’est pas forcé / Je suis peut être illuminé / Mais j’laisse entrer la lumière / Je n’veux pas me laisser miner / Par les barreaux, les barrières </p>
            <p>Faut aimer la beauté des choses / Tout embrasser même ses défauts / Dessiner la vie en rose / Au bonheur il faut céder </p>
            <p>Smile, smile / Don’t you ever doubt or wonder why, why </p>
            <p>It works like a wonder, fool, fool / Be a fool, it happens to be cool, cool / Bend your lips, bend the rules / Bend your lips, bend the rules / Bend your lips / Bend your lips, bend the rules / Bend your lips</p>
            <p>Rien ne sert de sourire / N’écoute pas ce refrain / Et danse et danse et danse / Et dans ces moments pense / À tout ce qu’on a de chance / Et retiens / Rien ne sert de courir / Il faut sourire à demain / La peur évanouie / Le cœur épanoui / Et ça ne coûte rien </p>
            <p>Smile, smile / Don’t you ever doubt or wonder why, why </p>
            <p>It works like a wonder, fool, fool / Be a fool, it happens to be cool, cool / Bend your lips, bend the rules / Bend your lips, bend the rules / Bend your lips / Bend your lips, bend the rules / Bend your lips</p>
            <p>Smile, smile</p>
            <p>Smile, smile…</p>
            <p>Smile, smile…</p>
            <p>Smile, smile…</p>
        `,
        credits: `
            Auteurs: Nazim Khaled-Amir Haddad<br />
            Compositeurs: Amir Haddad-Nazim Khaled-Raphael Koua<br />
            Editeurs: Lakkee - Victoree - Universkalp - Bukowski Publishing
        `
    },
    {
        id: "french-kiss",
        title: "French Kiss",
        slug: "french-kiss",
        album: "addictions-reedition",
        lyric: `
            <p>French kiss, / (French French fries / French French fries, French French French fries) / French kiss </p>
            <p>Paris danse sous la lune / Chacun cherche sa chacune / Dans le nocturne tapage / Les amants tournent la page </p>
            <p>Sur les plages de Saint-Trop’ / Des histoires se télescopent / Au matin quelques fêtards / Se racontent leurs faits d’armes  </p>
            <p>Pas de route 66 / Rien qu’un grand feu d’artifice... </p>
            <p>Pour faire fondre la banquise /Et dépasser les balises /C’est la langue qui voyage /Le bagout pour seul bagage / L’océan du grand ouest / Qui emporte tout le reste / C’est ainsi qu’on se pardonne / Ici qu’on s’abandonne </p>
            <p>Très très loin de l’Amérique / Dans un baiser amnésique... </p>
            <p>French kiss, / (French French fries / French French fries, French French French fries) </p>
            <p>French kiss, / (French French fries / French French fries, French French French fries) </p>
            <p>Très très loin de l’Amérique / Parait que paris est magique  </p>
            <p>Perdu dans Los Angeles /Les anges se reconnaissent /S’auréolent de caprice /Entre Venus et Venice / Les rêves se vendent au gramme /Ont un goût de paradis /Mais tel Icare ils se crament /Quand le soleil est parti  </p>
            <p>Le vent souffle sur la plaine / Transatlantique est la peine  </p>
            <p>Sur les ponts, sur les boulevards / Les filles allument le fard / Pour écraser le cafard / Des garçons qui rentrent tard / Les poètes poétisent / Déjà les lèvres s’aiguisent / Et transpercent d’ironie / Le ventre de l’insomnie </p>
            <p>Ils se parlent d’Amérique / Dans un baiser électrique  </p>
            <p>French kiss, / (French French fries / French French fries, French French French fries) </p>
            <p>French kiss, / (French French fries / French French fries, French French French fries) </p>
            <p>Très très loin de l’Amérique / Parait que Paris est magique  </p>
            <p>Dans les rues de Big Apple / Les âmes sont souvent seules / Embrassent le souvenir / Du baiser qui fait sourire, qui fait souffrir  </p>
            <p>French kiss, / (French French fries / French French fries, French French French fries) </p>
            <p>French kiss, / (French French fries / French French fries, French French French fries) </p>
            <p>Très très loin de l’Amérique / Parait que Paris est magique / French kiss… </p>
            <p>French kiss </p>
        `,
        credits: `
            Auteurs: Amir Haddad-Nazim Khaled<br />
            Compositeurs: Amir Haddad-Nazim Khaled - Pierre Laurent Faure<br />
            Editeurs: Lakkee - Victoree - Tick Tone - Bukowski Publishing
        `
    },
    {
        id: "parfait-desequilibre",
        title: "Parfait déséquilibre",
        slug: "parfait-desequilibre",
        album: "addictions-reedition",
        lyric: `
            <p>Les gens s’aiment oui mais les gens s’évitent, ouh / Génération qui hésite  / Les gens rêvent mais suivent le même phare, ouh / Comme s’ils avaient peur du noir </p>
            <p>En parfait déséquilibre / Sur la corde où nos cœurs vibrent, on s’accorde / Une seconde avant de tomber dans le vide / On se cache comme on se livre / On s’attache comme on est libre, on s’accorde / Une seconde avant de tomber dans le vide </p>
            <p>Laissez-nous le temps de choisir où on s’égare / Laissez nous le temps d’écrire nous mêmes notre histoire,  / De la vivre et de l’écrire comme on l’entend   / Comme on l’entend, comme on l’entend  / En parfait déséquilibre / Comme on l’entend / Comme on l’entend / En parfait déséquilibre  </p>
            <p>Les gens crèvent le ciel qui les abrite, ouh / Génération sans limite  / Les gens s’aiment surtout dans le miroir, ouh / Reflets d’amour illusoire </p>
            <p>En parfait déséquilibre / Sur la corde on se sent cible, on s’accorde / Une seconde pour se poser faire le vide / Le cœur collé au calibre / On oublie qu’on n’est pas libre, on s’accorde / Une seconde avant de tirer dans le vide </p>
            <p>Laissez-nous le temps de choisir où on s’égare   / Laissez nous le temps d’écrire nous mêmes notre histoire,  / De la vivre et de l’écrire comme on l’entend   / Comme on l’entend, comme on l’entend  / En parfait déséquilibre   / Comme on l’entend   / Comme on l’entend  / En parfait déséquilibre </p>
            <p>Comme on l’entend / Comme on l’entend / En parfait déséquilibre </p>
            <p>Comme on l’entend / Comme on l’entend / En parfait déséquilibre </p>
            <p>Parfait déséquilibre, parfait déséquilibre  / Parfait déséquilibre, parfait déséquilibre  </p>
        `,
        credits: `
            Auteur: Nazim Khaled<br />
            Compositeurs: Eddy Pradelles- Nazim Khaled<br />
            Editeurs: Lakkee - Victoree - Tick Tone - Bukowski Publishing
        `
    },
    {
        id: "cinq-minutes-avec-toi",
        ytId: "2Y5XyZotfk0",
        title: "5 minutes avec toi",
        slug: "cinq-minutes-avec-toi",
        album: "addictions-reedition",
        lyric: `
            <p>Je n’serai jamais le gendre idéal / Mais je ne suis pas de ceux qui se taillent / Pas de projets, pas de promesses / Juste qu’on mette en commun nos caresses </p>
            <p>Je suis pressé mais je prendrai le temps / Si t’es contre moi je ferai tourner le vent / J’veux pas me faire l’avocat du diable / Mais de me plaire tu es coupable </p>
            <p>Pourquoi pourquoi tu t’prends pour le centre du monde ? / Franchement ne me dis pas que t’as pas une seconde / On se retrouvera puisque la terre est ronde / Allez, oh... </p>
            <p>Je n’vais quand même pas me mettre à genoux ouh ouh…  / Avoue qu’y a déjà un petit truc entre nous ouh ouh… / On n’le voit pas mais l’amour est partout ouh ouh… / Traite moi de fou mais j’donnerais presque tout ouh ouh… </p>
            <p>Pour 5 minutes avec toi / 5 minutes avec toi </p>
            <p>T’en as marre de tous ces gars qui sont lourds / T’aimes pas les disquettes encore moins les discours / Je n’ferai pas de commentaires / Invite moi même si tu m’invites à me taire </p>
            <p>Pourquoi pourquoi tu n’me laisses pas l’ombre d’une chance / J’en connais qu’un mais j’peux t’montrer mon pas de danse / Je trouve que t’exagères avec tes exigences / Allez, oh... </p>
            <p>Je n’vais quand même pas me mettre à genoux ouh ouh…   / Avoue qu’y a déjà un petit truc entre nous ouh ouh…  / On n’le voit pas mais l’amour est partout ouh ouh…  / Traite moi de fou mais j’donnerais presque tout ouh ouh… </p>
            <p>Pour  / 5 minutes avec toi / 4 minutes avec toi / 3 minutes ? Allez.. / 2 minutes ? / 1 minute ? </p>
            <p>Je n’vais quand même pas me mettre à genoux ouh ouh…  / Avoue qu’y a déjà un petit truc entre nous ouh ouh… / On n’le voit pas mais l’amour est partout ouh ouh… / Traite moi de fou mais j’donnerais presque tout ouh ouh… </p>
            <p>Pour 5 minutes avec toi / 5 minutes avec toi </p>
        `,
        credits: `
            Auteur: Nazim Khaled<br />
            Compositeurs: Nazim Khaled-Eddy Pradelles<br />
            Editeurs: Lakkee - Victoree - Bukowski Publishing - Tick Tone
        `
    },
    {
        id: "imagine",
        title: "Imagine",
        slug: "imagine",
        album: "addictions-reedition",
        lyric: `
            <p>Comme un menteur tout seul face au miroir / Dans la moiteur dans les songes du soir / Ce n’était rien qu’un feu qu’une fois / J’étais loin de tes yeux de ta voix / Une sirène qui me tendait les bras </p>
            <p>Dans la machine du temps qui m’assassine souvent je redessine à l’heure où tu dors </p>
            <p>Ce monde ou j’imagine  / Que nos corps et nos cœurs sont d’accord / Chaque jour je m’abime, plus tu m’aimes et moins je me sens fort / J’ai traversé mes torts, les effluves et les fleuves de remords / Ce monde ou j’imagine que tu pardonnes ce que tu ignores / Quand tu dors, quand tu dors </p>
            <p>Evidemment la vérité fait mal  / En attendant le mensonge s’installe / J’ai voulu trouver mieux sous son aile  / Et je me suis crashé sous ton ciel  / Le saut de l’ange pour des nuits infernales </p>
            <p>Dans la machine, la douleur me fascine, alors je m’y destine, je pleure et j’implore </p>
            <p>Ce monde ou j’imagine  / Que nos corps et nos cœurs sont d’accord / Chaque jour je m’abime, plus tu m’aimes et moins je me sens fort / J’ai traversé mes torts, les effluves et les fleuves de remords / Ce monde ou j’imagine que tu pardonnes ce que tu ignores / Quand tu dors, quand tu dors </p>
            <p>Ce monde ou j’imagine  / Que nos corps et nos cœurs sont d’accord / Chaque jour je m’abime, plus tu m’aimes et moins je me sens fort / J’ai traversé mes torts, les effluves et les fleuves de remords / Ce monde ou j’imagine que tu pardonnes ce que tu ignores / Quand tu dors, quand tu dors </p>
        `,
        credits: `
            Auteur: Nazim Khaled<br />
            Compositeurs: Amir Haddad- Renaud Rebillaud<br />
            Lakkee - Victoree - Gohan Music - Bukowski Publishing 
        `
    },
    {
        id: "reflexions",
        title: "Réflexions",
        slug: "reflexions",
        album: "addictions-reedition",
        lyric: `
            <p>J’ai chanté à de tristes bals / J’ai dessiné de vaines toiles / Remué ciel pour une étincelle / C’est peut être anormal  </p>
            <p>Ces maudits doutes pour un seul but / Ces nuits sans route où l’âme lutte  / Là devant vous je me tiens debout  / J’ai refusé la chute  </p>
            <p>Qu’est-ce que ça vaut, et pourquoi ça brille ? / Est-ce que ça vaut, que ma vie vrille ? / Tout pour un mot, une mélodie / J’écris pour pas perdre la main </p>
            <p>Qu’est-ce que ça vaut, et pourquoi ça file ? / Pour un seul mot, on en brule mille / Est-ce que c’est faux chaque fois que ça brille ? / Je plane pour retarder la fin </p>
            <p>Quand rien ne m’éclaire à l’ombre des doutes / Je n’ai qu’un repère sur mes déroutes / Du vide j’ai peur, je vis de vos chœurs  / qui m’invitent et m’envoûtent </p>
            <p>Qu’est-ce que ça vaut, et pourquoi ça brille ? / Est-ce que ça vaut, que ma vie vrille ? / Tout pour un mot, une mélodie / J’écris pour pas perdre la main </p>
            <p>Qu’est-ce que ça vaut, et pourquoi ça file ? / Pour un seul mot, on en brule mille / Est-ce que c’est faux chaque fois que ça brille ? / Je plane pour retarder la fin </p>
            <p>Je donnerais ma vie cent fois, / Pour que tu sois </p>
            <p>Qu’est-ce que ça vaut, et pourquoi ça brille ? / Est-ce que ça vaut, que ma vie vrille ? / Tout pour un mot, une mélodie / J’écris pour pas perdre la main </p>
            <p>Qu’est-ce que ça vaut, et pourquoi ça file ? / Pour un seul mot, on en brule mille / Est-ce que c’est faux chaque fois que ça brille ? / Je plane pour retarder la fin </p>
            <p>Qu’est-ce que ça vaut, et pourquoi ça file / Pour un seul mot, on en brule mille  / Est-ce que c’est faux chaque fois que ça brille ? / Je plane pour retarder la fin </p>
        `,
        credits: `
            Auteur: Amir Haddad<br />
            Compositeur: Refael Nitzan Kaikov<br />
            Editeurs: Lakkee - Victoree - Striking Coin Music LLC - Kobalt music Admin
        `
    },
    {
        id: "autour-de-moi",
        title: "Autour de moi",
        slug: "autour-de-moi",
        album: "addictions-reedition",
        lyric: `
            <p>T'es le poison dans mes veines / Tu me tues j'ai besoin de toi / Par mes envies tu me mènes / C'est tordu mais je vais tout droit / Trop souvent la coupe est pleine / Tout ça finit en gueule de bois / Que je t'aime que je t'aime / On a pris du plomb dans l'aile / Envole-toi envole-toi </p>
            <p>Loin de moi / Et ne reviens jamais / Tu as tous les atouts que j'aime / Atouts que j'aime / Et tu as aussi tout c'qui m'gêne toi </p>
            <p>Mais loin de toi / Je suis seul comme jamais / Car tu déchaines tout de moi tout de moi / Rien n'existe autour de moi </p>
            <p>Rien n'existe autour de moi autour de moi / Ton ombre est autour de moi autour de moi / Rien ne me détourne moi détourne-moi / Pour que je te revienne </p>
            <p>Autour de moi autour de moi / Autour de moi autour de moi / Détourne-moi détourne-moi / Pour que je te revienne </p>
            <p>Parfois je t'idéalise / Tu me scandalises parfois / J'ai mis mes doigts dans la prise / Tu m'électrises à chaque fois / Je n'ai gagné dans l’arène / que la couronne du désarroi / Que je t'aime que je t'aime / Si tu t'sens pousser des ailes / Envole-toi envole-toi </p>
            <p>Loin de moi / Et ne reviens jamais / Tu as tous les atouts que j'aime  / Atouts que j'aime / Et tu as aussi tout c'qui m'gêne toi </p>
            <p>Mais loin de toi / Je suis seul comme jamais / Car tu déchaines tout de moi tout de moi / Rien n'existe autour de moi </p>
            <p>Rien n'existe autour de moi autour de moi / Ton ombre est autour de moi autour de moi / Rien ne me détourne moi détourne-moi / Pour que je te revienne </p>
            <p>Autour de moi autour de moi / Autour de moi autour de moi / Détourne-moi détourne-moi / Pour que je te revienne </p>
            <p>Il y a tant d'âmes sans tendresse / Nous on roule en tandem / Vers la mauvaise adresse / J’ai pas envie qu'on s'plante / J'ai juste envie qu'on s'aime / Les routes et les tendances / Peuvent se prendre à l'inverse </p>
            <p>Il n'y a pas d'âme sans dilemme / De l'amour on dit même / Qu'il adore les problèmes / Soit on trace et on tente / Soit on stagne et on stresse / Les routes et les tendances / Peuvent se prendre à l'inverse </p>
            <p>Autour de moi autour de moi / Ton ombre est autour de moi autour de moi / Rien ne me détourne moi détourne-moi / Pour que je te revienne </p>
            <p>Autour de moi autour de moi / Autour de moi autour de moi / Détourne-moi détourne-moi / Pour que je te revienne </p>
        `,
        credits: `
            Auteur: Nazim Khaled<br />
            Compositeurs: Silvio Lisbonne- Nazim Khaled<br />
            Editeurs: Lakkee - Victoree - Tick Tone - Bukowski Publishing
        `
    },
    {
        id: "qu-est-ce-qu-on-gardera",
        title: "Qu'est-ce qu'on gardera",
        slug: "qu-est-ce-qu-on-gardera",
        album: "addictions-reedition",
        lyric: `
            <p>À très bientôt ma belle / Je t’en veux pas promis je te le jure / Si l’aventure t’appelle / Si pour ton pied j’suis pas la bonne chaussure / Je voulais te faire des gosses mais / J’ai déconné t’as bien fait de me gommer / Je slalome entre les larmes / Dans tes yeux la tristesse à son charme  </p>
            <p>On aurait pu se tenir la main longtemps / Mais à deux doigts du bonheur on s’est lâchés / Et si on doit se fâcher  </p>
            <p>Qu’est-ce qu’on gardera ? / J’sais pas mais là j’suis touché coulé  / Saoulé, sous les draps  / J’repense à toi comme t’étais roulée  / Rouler loin d’ici, j’suis indécis  / En dent de scie j’veux me défouler  / Fouler la terre d’un nouveau jour qui passe  / Et me dire que tout s’efface  </p>
            <p>À très bientôt j’espère / La vie c’est pas de la littérature / J’étais ton livre ouvert / Tourne la page reste la couverture / J’Voulais qu’on vive en paire mais / J’t’ai enfermée, la routine m’a berné / Ma défaite sur le visage / Dans tes yeux, ma folie semble sage / On aurait pu courir encore longtemps / Mais à deux pas du bonheur on est tombés / Si ce soir on doit sombrer  </p>
            <p>Qu’est-ce qu’on gardera ? / J’sais pas mais là j’suis touche coulé  / Saoulé, sous les draps  / J’repense à toi comme t’étais roulée  / Rouler loin d’ici, j’suis indécis  / En dent de scie j’veux me défouler  / Fouler la terre d’un nouveau jour qui passe  / Et me dire que tout s’efface  / Oh oh oh  </p>
            <p>Et me dire que tout s’efface / Oh oh oh / Et me dire que tout s’efface / Oh oh oh / Et me dire que tout s’efface / Oh oh oh  </p>
            <p>Et se dire que tout est facile  </p>
            <p>Qu’est-ce qu’on gardera ?  </p>
            <p>Qu’est-ce qu’on gardera ? / J’repense à toi comme t’étais roulée  / Rouler loin d’ici, j’suis indécis  / En dent de scie j’veux me défouler  / Fouler la terre d’un nouveau jour qui passe  / Et me dire que tout s’efface  / Oh oh oh  / Et me dire que tout s’efface  / Oh oh oh  </p>
            <p>Et se dire que tout est facile  </p>
        `,
        credits: `
            Auteur : Nazim Khaled<br />
            Compositeurs: Amir Haddad-Nazim Khaled<br />
            Editeurs: Bukowski Publishing - Lakkee - Victoree 
        `
    },
    {
        id: "lune",
        title: "Lune",
        slug: "lune",
        album: "addictions-reedition",
        lyric: `
            <p>Je serai l’esclave et toi la reine / Moi le ridicule me blesse à peine  / Toutes les éraflures et les griffures / On s’en fout si c’est moi qui saigne </p>
            <p>T’aimes le mâle et tu sais comment faire / Avec toi je voudrais qu’on m’enferme / Quand t’es maléfique t’es magnifique  / Et tu m’fais même aimer l’enfer </p>
            <p>Je peux sans ambages, être fou ou sage, / Tout et son contraire, pour que tu m’espères / Puisqu’un cœur sauvage, ne peut vivre en cage, / J’attends que tu le libères  </p>
            <p>Lune, lorsque tu m’allumes, / Tes yeux me réveillent, révèlent, / Comme une nuit rebelle, / Belle, t’es pas seulement belle,  / T’es tout ce que mes vœux appellent,  / De mes cieux la prunelle </p>
            <p>J’veux que tu m’étales que tu m’étonnes / Quand ta peau vestale en fait des tonnes / Qu’on envoie en l’air dans l’éthanol / Nos envies de frapper le sol </p>
            <p>J’veux que tu m’apprennes à être un homme / J’veux que tu m’enchaînes que tu me donnes / Des coups des caresses qui s’additionnent / Pour un plaisir au maximum </p>
            <p>Je t’envoie sans cesse, mes feux de détresse, / Mon radeau se brise, contre ta banquise, / Tous ces vagues gestes, semblent bien modestes, / Sous les eaux de ton emprise </p>
            <p>Lune, lorsque tu m’allumes, / Tes yeux me réveillent, révèlent, / Comme une nuit rebelle, / Belle, t’es pas seulement belle,  / T’es tout ce que mes vœux appellent,  / De mes cieux la prunelle </p>
            <p>Lune, lorsque tu m’allumes, / Tes yeux me réveillent, révèlent, / Comme une nuit rebelle, / Belle, t’es pas seulement belle,  / T’es tout ce que mes vœux appellent,  / De mes cieux la prunelle </p>
        `,
        credits: `
            Auteurs: Amir Haddad-Nazim Khaled<br />
            Compositeurs: Amir Haddad, Renaud Rebillaud - Doron Medalie<br />
            Editeurs: Lakkee - Victoree - Gohan Music - Bukowski Publishing
        `
    },
    {
        id: "lune",
        title: "Lune",
        slug: "lune-r3ssources",
        album: "r3ssources",
        lyric: `
            <p>Je serai l’esclave et toi la reine / Moi le ridicule me blesse à peine  / Toutes les éraflures et les griffures / On s’en fout si c’est moi qui saigne </p>
            <p>T’aimes le mâle et tu sais comment faire / Avec toi je voudrais qu’on m’enferme / Quand t’es maléfique t’es magnifique  / Et tu m’fais même aimer l’enfer </p>
            <p>Je peux sans ambages, être fou ou sage, / Tout et son contraire, pour que tu m’espères / Puisqu’un cœur sauvage, ne peut vivre en cage, / J’attends que tu le libères  </p>
            <p>Lune, lorsque tu m’allumes, / Tes yeux me réveillent, révèlent, / Comme une nuit rebelle, / Belle, t’es pas seulement belle,  / T’es tout ce que mes vœux appellent,  / De mes cieux la prunelle </p>
            <p>J’veux que tu m’étales que tu m’étonnes / Quand ta peau vestale en fait des tonnes / Qu’on envoie en l’air dans l’éthanol / Nos envies de frapper le sol </p>
            <p>J’veux que tu m’apprennes à être un homme / J’veux que tu m’enchaînes que tu me donnes / Des coups des caresses qui s’additionnent / Pour un plaisir au maximum </p>
            <p>Je t’envoie sans cesse, mes feux de détresse, / Mon radeau se brise, contre ta banquise, / Tous ces vagues gestes, semblent bien modestes, / Sous les eaux de ton emprise </p>
            <p>Lune, lorsque tu m’allumes, / Tes yeux me réveillent, révèlent, / Comme une nuit rebelle, / Belle, t’es pas seulement belle,  / T’es tout ce que mes vœux appellent,  / De mes cieux la prunelle </p>
            <p>Lune, lorsque tu m’allumes, / Tes yeux me réveillent, révèlent, / Comme une nuit rebelle, / Belle, t’es pas seulement belle,  / T’es tout ce que mes vœux appellent,  / De mes cieux la prunelle </p>
        `,
        credits: `
            Auteurs : Amir Haddad – Nazim Khaled<br />
            Compositeurs : Amir Haddad – Renaud Rebillaud – Doron Medalie<br />
            Éditions : Lakkee – VictorEE – Gohan Music – Bukowski Publishing<br />
            Réalisé & arrangé par Dudu Tass
        `
    },
    {
        id: "sal-de-mi-mente",
        title: "Sal de Mi Mente",
        slug: "sal-de-mi-mente",
        album: "addictions-reedition",
        lyric: `
            <p>Dime cómo es que puedes seguir / Allí sonriendo tan feliz / Y yo aquí </p>
            <p>Buscando cómo poderte olvidar / Estoy hundido en un mar / Y ya no puedo respirar </p>
            <p>Acaso fue artificial / Tu amor que juraste real / Te burlaste de mi corazón, probe de tu amor y me tragade el dolor / Te encanta ser mala mujer / Mientras qué puedo hacer / Con el recuerdo de tus labios, tus besos, tu ser </p>
            <p>Sors de ma tête / De ma peau sors, j’suis plus qu’un corps / Au bord de l’eau / Sors de ma tête / J’essaie encore, mais j’suis moins fort  </p>
            <p>Que ton écho / Et je t’appelle, je t’appelle, je t’appelle (comme un écho) / Et je t’appelle, je t’appelle, je t’appelle </p>
            <p>Mi orgullo se ahogó al querer / Recuperarte yo sé bien / Me equivoque </p>
            <p>De este amor ya no hay solución / Yo solo fui tu diversion / Y todo esto una ilusión </p>
            <p>Acaso fue artificial / Tu amor que juraste real / Te burlaste de mi corazón, probe de tu amor y me tragade el dolor / Te encanta ser mala mujer / Mientras qué puedo hacer / Con el recuerdo de tus labios, tus besos, tu ser </p>
            <p>Sors de ma tête / De ma peau sors, j’suis plus qu’un corps / Au bord de l’eau / Sors de ma tête / J’essaie encore, mais j’suis moins fort / Que ton écho / Et je t’appelle, je t’appelle, je t’appelle (comme un écho) / Et je t’appelle, je t’appelle, je t’appelle </p>
            <p>La pasión, de este amor ya se acabo entre tu y yo, se termino / Ya no quiero, saber nada de ti. </p>
            <p>Sors de ma tête / De ma peau sors, j’suis plus qu’un corps / Au bord de l’eau / Sors de ma tête / J’essaie encore, mais j’suis moins fort / Que ton écho / Et je t’appelle, je t’appelle, je t’appelle (comme un écho) / Et je t’appelle, je t’appelle, je t’appelle </p>
        `,
        credits: `
            Auteurs: Amir Haddad-Nazim Khaled<br />
            Compositeurs: Skalpovich-Amir Haddad-Nazim Khaled (Adaptation Anthony Cruz & Michelle Estrada)<br />
            Editeurs: Lakkee - Victoree - Universkalp - Bukowski Publishing
        `
    },
    {
        id: "si-t-as-mal",
        title: "Si t'as mal",
        slug: "si-t-as-mal",
        album: "addictions-reedition",
        lyric: `
            <p>Depuis qu’elle ta piqué / C’est mortel / Tu peux plus respirer / T’es fou d’elle / Tu dis qu’plus rien ne compte / Et même ton ombre / Tu voudrais la traiter d’infidèle </p>
            <p>J’connais par cœur / Tes conneries tes erreurs / Si tu tombes n’aie pas peur / On s’relève de tout / De tout / J’connais par cœur / C’que tu caches de douleur / C'est ton tour, c'est ton heure / Souris et c’est tout / C’est tout </p>
            <p>N’aie pas honte si t’as mal / Dis-toi que c’est normal / Je te sais sans dire un mot </p>
            <p>N’aie pas honte si t’as mal / Chaque fois que tu t’emballes / Je te suis sans dire un mot </p>
            <p>La peur a remplacé l’innocence / C’est déjà l’heure de trouver du sens / À cette vie qui hésite / Va pas trop vite / Tu es déjà plus loin que tu penses </p>
            <p>Qu’est-ce que tu crois / J’ai douté avant toi / J’ai pleuré plus d’une fois / Ça ne vaut pas le coup, le coup / Pour l’voyage / Pas besoin d’être sage / Si t’es mon passager / J’t’emmène partout ? partout… </p>
            <p>N’aie pas honte si t’as mal / Dis-toi que c’est normal / Je te sais sans dire un mot </p>
            <p>N’aie pas honte si t’as mal / Chaque fois que tu t’emballes / Je te suis sans dire un mot </p>
            <p>N’aie pas honte si t’as mal / Dis-toi que c’est normal / Je te sais sans dire un mot </p>
            <p>N’aie pas honte si t’as mal / Chaque fois que tu t’emballes / Je te suis sans dire un mot </p>
        `,
        credits: `
            Auteur: Nazim Khaled<br />
            Compositeurs: Amir Haddad - Raphael Koua - Doron Medalie<br />
            Editeurs: Lakkee - Victoree - Universkalp - Bukowski Publishing      
        `
    },
    {
        id: "higher",
        ytId: "B8G20c95-No",
        title: "Higher",
        slug: "higher",
        album: "addictions-reedition",
        lyric: `
            <p>Chaque matin je me réveille / Mon coeur entend le vent chanter l'espoir / J'veux pas rater l'essentiel / Je sais que je s'rai ce héros ce soir	 </p>
            <p>Et moi comme tout le monde / Je n'sais pas ce qui m'attend  / Ce soir mes rêves sont grands  / Alors j'attends que la nuit tombe  / Je veux sauver le monde  / Pendu au fil du temps  / À chaque instant, chaque seconde  </p>
            <p>High and higher, touch the sky like a ninja fighter / High and higher, unleash the hero, feel its power / Unleash the hero, feel its power </p>
            <p>La douleur c'est comme l'hiver / Elle ne peut rien contre le printemps / Je vais un peu mieux qu'hier / Mais demain j'irai mieux assurément  </p>
            <p>Et moi comme Tout le monde / Dans cette course folle / Je cherche ma boussole / Mais il paraît qu'la terre est ronde / J'suis pas comme Tout le monde / Et si la peine se colle / J'attends que l'amour lui réponde  </p>
            <p>High and higher, touch the sky like a ninja fighter  / High and higher, unleash the hero, feel its power  / Unleash the hero, feel its power </p>
            <p>Et toi comme Tout le monde / T'as des rêves comme Tout le monde / Si t'y crois comme tout le monde / Tu peux aussi sauver le monde / Fais pas comme tout le monde / N'attends pas une seconde / Rêve et prends ton envol / En attendant que l’amour t'inonde  </p>
            <p>High and higher, touche the sky like a ninja fighter / High and higher, unleash the hero, feel its power / High and higher  / High and higher </p>
        `,
        credits: `
            Auteurs: Amir Haddad-Nazim Khaled<br />
            Compositeurs: Amir Haddad-Nazim Khaled- Raphael Koua<br />
            Editeurs: Lakkee - Victoree - Bukowski Publishing - Universkalp
        `
    },
    {
        id: "imagine-the-answer",
        title: "Imagine... The Answer",
        slug: "imagine-the-answer",
        album: "addictions-reedition",
        lyric: `
            <p>Seraphims cry when we lie in our bed / It’s not that I’m blind, I’m just turning my head / Don’t know if to forgive or to dive, our love is wanted dead or alive / Tears may have dried but the heart ain’t yet </p>
            <p>We were holding  / To our cards no folding / Although I was all in / You doubled your game </p>
            <p>And if you imagine silence is excusing your lies / Peace and love were magic but now I have a reason to fight / Cause I can see it all, and it gives me, it gives me power / Knowing you are fragile, and I just want to save my life. / Oh my life, oh my life </p>
            <p>Salvation is close and it’s ringing my phone / A million roads are departing from home / I’ve had my share of  being prey, I‘m sure that God is hearing my prayers / Now it’s my time I am setting the tone </p>
            <p>You were claiming  / That you needed taming / As you were just paving / The way to the end </p>
            <p>And if you imagine silence is excusing your lies / Peace and love were magic but now I have a reason to fight / Cause I can see it all, and it gives me, it gives me power / Knowing you are fragile, and I just want to save my life. / Oh my life, oh my life </p>
            <p>And if you imagine silence is excusing your lies / Peace and love were magic but now I have a reason to fight / Cause I can see it all, and it gives me, it gives me power / Knowing you are fragile, and I just want to save my life. / Oh my life, oh my life </p>
        `,
        credits: `
            Auteur : Amir Haddad<br />
            Compositeurs: Amir Haddad- Renaud Rebillaud<br />
            Editeurs: Lakkee - Victoree - Gohan Music
        `
    },
    {
        id: "100-years",
        title: "100 Years",
        slug: "100-years",
        album: "addictions-reedition",
        lyric: `
            <p>I want you to rant / to let down your guard and be frank / I want you to share all the good and the bad with me / from now on till the end</p>
            <p>I want us to fight / I ll protect you, I ll make it alright / i want you to know that I ll always be on your side / through the darkest of nights</p>
            <p>I could try to deny that you stirred up my life / disarmed me completely without even trying / but the second I look at you / I know that it’s true</p>
            <p>And I hope in one hundred years / you’ll still be laying next to me / when I’ve grown old and forgot all but my own name I ll still know / that you’re all that matters to me</p>
            <p>I just want us to last / wearing our hearts on our sleeve / living our lives on a high and fulfil our dreams / til one day both our eyes close in peace</p>
            <p>You own my heart / you’re my smile and my fortune card / I want us to dance in the rain and to count the stars / til death do us part</p>
            <p>I’d go sleep on the street if you needed a roof / I’d give it all up if it meant saving you / after everything we’ve been through / I know that its true</p>
        
        `,
        credits: `
            Auteur: Nazim Khaled<br />
            Adaptation : Ina Reni Alexandrow<br />
            Compositeur : Assaf Tzrouya<br />
            Editeurs: Lakkee - Victoree - Bukowski Publishing - Warner Chappell Music France
        `
    },
    {
        title: "J'ai Cherché",
        ytId: "kQysGibXphE",
        slug: "j-ai-cherche",
        album: "au-coeur-de-moi-standard",
        lyric: `
              <p>J’ai cherché / un sens à mon existence / J’y ai laissé mon innocence / J’ai fini le coeur sans défense / J’ai cherché / L’amour et la reconnaissance / J’ai payé / Le prix du silence / Je me blesse et je recommence</p> 
              <p>Tu m’as / Comme donné l’envie d’être moi / Donné un sens à mes pourquoi / Tu as tué la peur / Qui dormait là / Qui dormait là / Dans mes bras</p> 
              <p>You / You're the one that's making me strong / I'll be looking looking for / You / Like the melody of my song / You / You're the one that's making me strong / I'll be looking looking for / You / Like the melody of my song</p> 
              <p>J’ai cherché / Un sens, un point de repère / Partagé en deux hémisphères / Comme une erreur de l’univers / J’ai jeté / Tellement de bouteilles à la mer / J’ai bu tant de liqueurs amères / Que j’en ai les lèvres de pierre</p> 
              <p>Tu m’as / Comme donné l’envie d’être moi / Donné un sens à mes pourquoi / Tu as tué la peur / Qui dormait là / Qui dormait là / Dans mes bras</p> 
              <p>You / You're the one that's making me strong / I'll be looking looking for / You / Like the melody of my song / You / You're the one that's making me strong / I'll be looking looking for / You / Like the melody of my song</p> 
              <p>Au gré de nos blessures / Et de nos désinvoltures / C’est quand on n’y croit plus du tout / Qu’on trouve un paradis perdu / En nous / Oh you you you you</p> 
              <p>You / You're the one that's making me strong / I'll be looking looking for / You / Like the melody of my song / Like the melody of my song...</p> 
              <p>Tu m’as / Comme donné l’envie d’être moi / Donné un sens à mes pourquoi / Tu as tué la peur / Qui dormait là / Qui dormait là / Dans mes bras</p> 
              <p>You / You're the one that's making me strong / I'll be looking looking looking for / You / Like the melody of my song / You</p>
            `,
            credits: `
                Auteurs : Amir Haddad – Nazim Khaled<br />
                Compositeurs: Amir Haddad – Nazim Khaled – Johan Errami<br />
                Editeurs : Universal Music Publishing - Sash Productions - Tick Tone Music - Universkalp
            `
      },
      {
          title: "J'ai Cherché",
          ytId: "kQysGibXphE",
          slug: "j-ai-cherche-r3ssources",
          album: "r3ssources",
          lyric: `
                <p>J’ai cherché / un sens à mon existence / J’y ai laissé mon innocence / J’ai fini le coeur sans défense / J’ai cherché / L’amour et la reconnaissance / J’ai payé / Le prix du silence / Je me blesse et je recommence</p> 
                <p>Tu m’as / Comme donné l’envie d’être moi / Donné un sens à mes pourquoi / Tu as tué la peur / Qui dormait là / Qui dormait là / Dans mes bras</p> 
                <p>You / You're the one that's making me strong / I'll be looking looking for / You / Like the melody of my song / You / You're the one that's making me strong / I'll be looking looking for / You / Like the melody of my song</p> 
                <p>J’ai cherché / Un sens, un point de repère / Partagé en deux hémisphères / Comme une erreur de l’univers / J’ai jeté / Tellement de bouteilles à la mer / J’ai bu tant de liqueurs amères / Que j’en ai les lèvres de pierre</p> 
                <p>Tu m’as / Comme donné l’envie d’être moi / Donné un sens à mes pourquoi / Tu as tué la peur / Qui dormait là / Qui dormait là / Dans mes bras</p> 
                <p>You / You're the one that's making me strong / I'll be looking looking for / You / Like the melody of my song / You / You're the one that's making me strong / I'll be looking looking for / You / Like the melody of my song</p> 
                <p>Au gré de nos blessures / Et de nos désinvoltures / C’est quand on n’y croit plus du tout / Qu’on trouve un paradis perdu / En nous / Oh you you you you</p> 
                <p>You / You're the one that's making me strong / I'll be looking looking for / You / Like the melody of my song / Like the melody of my song...</p> 
                <p>Tu m’as / Comme donné l’envie d’être moi / Donné un sens à mes pourquoi / Tu as tué la peur / Qui dormait là / Qui dormait là / Dans mes bras</p> 
                <p>You / You're the one that's making me strong / I'll be looking looking looking for / You / Like the melody of my song / You</p>
              `,
              credits: `
                Auteurs : Amir Haddad – Nazim Khaled<br />
                Compositeurs : Amir Haddad – Nazim Khaled – Johan Errami<br />
                Éditions : Universal Music Publishing – Sash Productions – Tick Tone Music – Universkalp<br />
                Réalisé & arrangé par Dudu Tassa
              `
        }, {
        title: "On Dirait",
        ytId: "MBFQ0NK-xF8",
        slug: "on-dirait",
        album: "au-coeur-de-moi-standard",
        lyric: `
              <p>Ah ah ah ah ah ah ah\nAh ah ah ah ah ah ah\nAh ah ah ah ah ah ah ah</p>
              <p>Tu me dis\nDe regarder la vie en couleurs\nQuand il fait noir autour de moi\nSur le dos j'ai trainé pas mal de douleurs \nToi tu m'portais à bout de bras </p>
              <p>Non, non, non\nJe n'ai pas toujours été sûr de moi \nJ'ai douté tellement de fois \nNon, non, non\nJe n'sais pas c'que je ferais sans toi</p>
              <p>Oïééée</p>
              <p>On dirait\nQu'on a tous un ange \nOn dirait, on dirait bien que c'est toi \nOn dirait\nQue dans ce monde étrange\nOn dirait\nQue t'as toujours été là </p>
              <p>Ah ah ah ah ah ah ah\nAh ah ah ah ah ah ah\nAh ah ah ah ah ah ah ah</p>
              <p>Tu me dis\nQue mon rêve est juste à côté\nQue j'ai juste à tendre la main\nToute ma vie\nJ'peux la passer à t'écouter\nLa douceur est ton seul refrain</p>
              <p>Non, non, non\nCe n'est pas toujours facile pour moi\nJ'ai plié tellement de fois \nNon, non, non\nJe n'sais pas ce que je ferais sans toi </p>
              <p>Oïééée</p>
              <p>On dirait\nQu'on a tous un ange \nOn dirait, on dirait bien que c'est toi \nOn dirait\nQue dans ce monde étrange\nOn dirait\nQue t'as toujours été là </p>
              <p>Tout tout tourne autour de toi\nToi, t'es la seule qui vois\nLa beauté bien cachée, derrière les visages\nTout tout, tourne autour de toi,\nToi, tu sais lire en moi\nPromets-moi de n'jamais tourner la page\n \nOn dirait\nQu'on a tous un ange \nOn dirait, on dirait bien que c'est toi \nOn dirait\nQue dans ce monde étrange\nOn dirait\nQue t'as toujours été là </p>
              <p>On dirait\nQu'on a tous un ange \nOn dirait, on dirait bien que c'est toi \nOn dirait\nQue dans ce monde étrange\nOn dirait\nQue t'as toujours été là </p>
              <p>Ah ah ah ah ah ah ah\nAh ah ah ah ah ah ah\nAh ah ah ah ah ah ah ah\nOn dirait\nAh ah ah ah ah ah ah\nAh ah ah ah ah ah ah\nAh ah ah ah ah ah ah ah\nOn dirait\n</p>
            `,
            credits: `
                Auteur: Nazim Khaled<br />
                Compositeurs: Nazim Khaled – Jerôme Quériaud<br />
                Editeurs : Bukowski publishing - Sash Productions
            `
      }, {
        title: "Au coeur de moi",
        ytId: "QvsQJdXnbtw",
        slug: "au-coeur-de-moi",
        album: "au-coeur-de-moi-standard",
        lyric: `
              <p>Un jour j’suis prédateur / Un jour je suis la proie / Un jour j’ai chaud au coeur / Un jour j’ai tellement froid / Parfois je n’vois plus clair / Le miroir me déforme / Je me lève sans lumière / Et mes rêves s’endorment</p>
              <p>Plonge au coeur de moi / Au fond de mes abîmes / De mes abîmes / Dis-moi ce que tu vois / Plonge au coeur de moi / Et quand tout me décime / Tout me décime / Je veux renaitre en toi</p>
              <p>Viens, écris sur ma peau / Les mots que je n’ose pas / ouh oh oh oh oh / oh ouh oh oh oh oh oh / Même au bord du chaos / Si je tombe, retiens-moi</p>
              <p>Un jour je suis l’acteur / Qui fait son cinema / Un jour c’est le destin / Qui tient la caméra / Mon esprit solitaire / Se perd au fond des bois / J’vois le monde à l’envers / Mes rêves sont à l’étroit</p>
              <p>Plonge au coeur de moi / Au fond de mes racines / De mes racines / Où personne ne va / Plonge au coeur de moi / Le temps nous laisse en ruines / Nous laisse en ruines / Je veux renaitre en toi</p>
              <p>Viens, écris sur ma peau / Les mots que je n’ose pas / ouh oh oh oh oh / oh ouh oh oh oh oh oh oh / Même au bord du chaos / Si je tombe, retiens-moi</p>
              <p>Et j’entends ton écho / Oh oh oh oh oh / C’est la voix d’un jour nouveau / Le plus beau des flambeaux</p>
              <p>
              Viens, écris sur ma peau / Les mots que je n’ose pas / Même au bord du chaos / Si je tombe, retiens-moi</p>
              <p>Viens, écris sur ma peau / Les mots que je n’ose pas / ouh oh oh oh oh / oh ouh oh oh oh oh oh oh / Même au bord du chaos / Si je tombe, retiens-moi</p>
              <p>Viens, écris sur ma peau / Les mots que je n’ose pas / ouh oh oh oh oh / oh ouh oh oh oh oh oh oh / Même au bord du chaos / Si je tombe, retiens-moi</p>
              `,
              credits: `
                Auteurs: Nazim Khaled – Manon Romiti<br />
                Compositeurs: Amir Haddad – Nazim Khaled – Manon Romiti – Silvio Lisbonne<br />
                Editeurs : Bukowski publishing - Tick Tone Music - Sash Productions
              `
      }, {
        title: "Au coeur de moi",
        ytId: "QvsQJdXnbtw",
        slug: "au-coeur-de-moi-r3ssources",
        album: "r3ssources",
        lyric: `
              <p>Un jour j’suis prédateur / Un jour je suis la proie / Un jour j’ai chaud au coeur / Un jour j’ai tellement froid / Parfois je n’vois plus clair / Le miroir me déforme / Je me lève sans lumière / Et mes rêves s’endorment</p>
              <p>Plonge au coeur de moi / Au fond de mes abîmes / De mes abîmes / Dis-moi ce que tu vois / Plonge au coeur de moi / Et quand tout me décime / Tout me décime / Je veux renaitre en toi</p>
              <p>Viens, écris sur ma peau / Les mots que je n’ose pas / ouh oh oh oh oh / oh ouh oh oh oh oh oh / Même au bord du chaos / Si je tombe, retiens-moi</p>
              <p>Un jour je suis l’acteur / Qui fait son cinema / Un jour c’est le destin / Qui tient la caméra / Mon esprit solitaire / Se perd au fond des bois / J’vois le monde à l’envers / Mes rêves sont à l’étroit</p>
              <p>Plonge au coeur de moi / Au fond de mes racines / De mes racines / Où personne ne va / Plonge au coeur de moi / Le temps nous laisse en ruines / Nous laisse en ruines / Je veux renaitre en toi</p>
              <p>Viens, écris sur ma peau / Les mots que je n’ose pas / ouh oh oh oh oh / oh ouh oh oh oh oh oh oh / Même au bord du chaos / Si je tombe, retiens-moi</p>
              <p>Et j’entends ton écho / Oh oh oh oh oh / C’est la voix d’un jour nouveau / Le plus beau des flambeaux</p>
              <p>
              Viens, écris sur ma peau / Les mots que je n’ose pas / Même au bord du chaos / Si je tombe, retiens-moi</p>
              <p>Viens, écris sur ma peau / Les mots que je n’ose pas / ouh oh oh oh oh / oh ouh oh oh oh oh oh oh / Même au bord du chaos / Si je tombe, retiens-moi</p>
              <p>Viens, écris sur ma peau / Les mots que je n’ose pas / ouh oh oh oh oh / oh ouh oh oh oh oh oh oh / Même au bord du chaos / Si je tombe, retiens-moi</p>
              `,
              credits: `
                Auteurs : Nazim Khaled – Manon Romiti<br />
                Compositeurs : Amir Haddad – Nazim Khaled – Manon Romiti – Silvio Lisbonne<br />
                Éditions : Bukowski publishing – Tick Tone Music – Sash Productions<br />
                Réalisé & arrangé par Dudu Tassa 
              `
      }, {
        title: "Ma vie, ma ville, mon monde",
        slug: "ma-vie-ma-ville-mon-monde",
        album: "au-coeur-de-moi-standard",
        lyric: `
              <p>Ma vie, ma ville, mon monde / Etaient bien trop petits pour moi / Parler, crier, partir / Ou bien se sentir à l'étroit / Mes amis ne m'en veuillez pas / Mais quand viendra l'aube du jour / Je prendrai le chemin qui mène à l'amour / A l'amour de soi</p>
              <p>Comme elle et comme toi / Comme elle et comme toi / Je suis né quelque part / Comme elle et comme toi / Comme elle et comme toi / Je cherche dans le noir / Et j'espère</p>
              <p>Qu'on se rencontrera / Qu'on se rencontrera / Au détour d'un hasard / Si on va jusque là / On se rencontrera, on se rencontrera / Je serai sous ta fenêtre / Et je tomberai sur toi …</p>
              <p>Oh oh oh oh oh oh oh oh oh oh oh / Oh oh oh oh oh oh on se rencontrera / Oh oh oh oh oh oh oh oh oh oh oh / Oh oh oh oh oh oh</p>
              <p>Ma vie, ma ville, mon monde / Tout ça ne me suffisait pas / Longtemps le manque, se planque / Surgit quand le coeur a trop froid / Un soleil noir me regardait / Ses yeux brulants semblaient me dire / Je me lève avec toi / Si tu veux partir / D'où on n'revient pas</p>
              <p>Comme elle et comme toi / Comme elle et comme toi / Je suis né quelque part / Comme elle et comme toi / Comme elle et comme toi / Je cherche sans te voir / Et j'espère</p>
              <p>Qu'on se rencontrera / Qu'on se rencontrera / Au détour d'un hasard / Si on va jusque là / On se rencontrera, on se rencontrera / Je s'rai sous ta fenêtre / Et je tomberai sur toi …</p>
              <p>Oh oh oh oh oh oh oh oh oh oh oh / Oh oh oh oh oh oh on se rencontrera / Oh oh oh oh oh oh oh oh oh oh oh / Oh oh oh oh oh oh on se rencontrera</p>
              <p>Qu'on se rencontrera / Qu'on se rencontrera / Au détour d'un hasard / Si on va jusque là / On se rencontrera, on se rencontrera / Je s'rai sous ta fenêtre / Et je tomberai sur toi …</p>
              <p>Ma vie, ma ville, mon monde / Etaient bien trop petits pour moi / Je partirai demain, peut-être / En attendant je reste là</p>
              `,
              credits: `
                Auteur: Nazim Khaled<br />
                Compositeurs : Nazim Khaled – Jerôme Kerio<br />
                Editeurs : Bukowski publishing - Sash Productions
              `
      }, {
        title: "À ta manière",
        slug: "a-ta-maniere",
        album: "au-coeur-de-moi-standard",
        lyric: `
                <p>S'il faut tout se dire / J'ai manqué d'un sourire quelques fois / Je t'ai vu partir / L'enfant que j'étais ne savait pas / Il faut du temps pour se comprendre / J’ai tellement d’amour à te rendre / Que cent vies ne suffiraient pas / Je veux t’aimer dans cette vie là</p>
                <p>Sans un mot, tu m'apprends à être moi / Tu reprends mes défauts mes mauvais choix / De moi tu es fier, à ta manière / Tu me prends sous ton aile, pas dans tes bras / Tu ne me dis pas je t'aime, mais je le vois / Je te vénère… Comme un fils admire son père</p>
                <p>J’ai peur de l’écrire / Ton silence m’a assourdi parfois / Le meilleur, le pire / J’ai tout fait pour qu’enfin tu me voies / J’ai passé des jours et des heures / A la fenêtre de ton coeur / Je sais que tu me regardais / Mieux que personne tu me connais</p>
                <p>Sans un mot, tu m'apprends à être moi / Tu reprends mes défauts mes mauvais choix / De moi tu es fier, à ta manière / Tu me prends sous ton aile, pas dans tes bras, / Tu n'me dis pas je t'aime, mais je le vois / Je te vénère…</p>
                <p>Comme un fils admire son père / Comme un fils admire son père / C’est juste la déclaration / D’un petit garçon / Qui ne veut pas lâcher ta main / Qui a encore tant de chemin / Avant d’être aussi fort que toi / Papa…</p>
                <p>Sans un mot, tu m'apprends à être moi, / Tu reprends mes défauts mes mauvais choix / De moi tu es fier… à ta manière / Tu me prends sous ton aile, pas dans tes bras, / Tu n'me dis pas je t'aime, mais je le vois / Je te vénère… comme un fils admire son père </p>
              `,
              credits: `
                Auteur: Nazim Khaled<br />
                Compositeurs: Amir Haddad – Davide Esposito<br />
                Editeurs : Bukowski Publishing - Sash Productions - Peer Music 
              `
      }, {
        title: "À ta manière",
        slug: "a-ta-maniere-r3ssources",
        album: "r3ssources",
        lyric: `
                <p>S'il faut tout se dire / J'ai manqué d'un sourire quelques fois / Je t'ai vu partir / L'enfant que j'étais ne savait pas / Il faut du temps pour se comprendre / J’ai tellement d’amour à te rendre / Que cent vies ne suffiraient pas / Je veux t’aimer dans cette vie là</p>
                <p>Sans un mot, tu m'apprends à être moi / Tu reprends mes défauts mes mauvais choix / De moi tu es fier, à ta manière / Tu me prends sous ton aile, pas dans tes bras / Tu ne me dis pas je t'aime, mais je le vois / Je te vénère… Comme un fils admire son père</p>
                <p>J’ai peur de l’écrire / Ton silence m’a assourdi parfois / Le meilleur, le pire / J’ai tout fait pour qu’enfin tu me voies / J’ai passé des jours et des heures / A la fenêtre de ton coeur / Je sais que tu me regardais / Mieux que personne tu me connais</p>
                <p>Sans un mot, tu m'apprends à être moi / Tu reprends mes défauts mes mauvais choix / De moi tu es fier, à ta manière / Tu me prends sous ton aile, pas dans tes bras, / Tu n'me dis pas je t'aime, mais je le vois / Je te vénère…</p>
                <p>Comme un fils admire son père / Comme un fils admire son père / C’est juste la déclaration / D’un petit garçon / Qui ne veut pas lâcher ta main / Qui a encore tant de chemin / Avant d’être aussi fort que toi / Papa…</p>
                <p>Sans un mot, tu m'apprends à être moi, / Tu reprends mes défauts mes mauvais choix / De moi tu es fier… à ta manière / Tu me prends sous ton aile, pas dans tes bras, / Tu n'me dis pas je t'aime, mais je le vois / Je te vénère… comme un fils admire son père </p>
              `,
              credits: `
              Auteur : Nazim Khaled<br />
              Compositeurs : Amir Haddad – Davide Esposito<br />
              Éditions : Bukowski Publishing – Sash Productions – Peer Music<br />
              Réalisé & arrangé par Dudu Tassa 
              `
      }, {
        title: "I know",
        slug: "i-know",
        album: "au-coeur-de-moi-standard",
        lyric: `
                <p>L’euphorie des jours / Est passée par nous déjà / On a fait le tour / On a franchi le delta / Y’a-t-il un antidote / Au poison des détails ?  / Nos coeurs qui s’érodent / C’est le temps qui les entaille, entaille, entaille ...</p>
                <p>Plus rien ne m’agite / Une étincelle ou je m’éteins sans toi / Plus rien ne m’agite / Si ta peau hésite / Rends moi la flamme et je brûle avec toi / Si ta peau hésite</p>
                <p>And I know / It's fire pouring over me / But what I feel is ecstasy / And I know / That when it's gone I'll wake to find / Crossing rivers left me blind</p>
                <p>Vient le temps des songes ,  / Qui nous ramène en arrière / On goûte au mensonge / La vérité est amère, amère, amère</p>
                <p>Juste à la limite / Entre l’Adieu et les envies d’encore / Juste à la limite / Si ta peau me quitte / Que reste-t-il pour faire chanter mon corps ?  / Si ta peau me quitte</p>
                <p>And I know / It's fire pouring over me / But what I feel is ecstasy / And I know / That when it's gone I'll wake to find / Crossing rivers left me blind</p>
                <p>And I know / It's fire pouring over me / But what I feel is ecstasy / And I know / That when it's gone I'll wake to find / Crossing rivers left me blind</p>
                <p>And I know / It's fire pouring over me / But what I feel is ecstasy / And I know / That when it's gone I'll wake to find / Crossing rivers left me blind</p>
                <p>And I know / It's fire pouring over me / But what I feel is ecstasy / And I know / That when it's gone I'll wake to find / Crossing rivers left me blind</p>
              `,
              credits: `
                Auteurs: Nazim Khaled – Amir Haddad<br />
                Compositeurs: Fred Savio - Felipe Saldivia - Amir Haddad<br />
                Editeurs : Bukowski publishing - Sash Productions - Ear print - Warner Chappell - Universal Music Publishing - Together records 
              `
      }, {
        title: "Très haut",
        slug: "tres-haut",
        album: "au-coeur-de-moi-standard",
        lyric: `
                <p>Bien sur, bien sûr / On avance à la dure / On se trompe parfois / On revient sur nos pas / On va jusqu’au sommet / On se pense arrivé / On se penche un peu trop / Et on tombe, tombe de haut OH ! / Tout le long de la chute / On prend des uppercuts / Des coups-bas et des bleus / On s’défend comme on peut / Quitte à frapper le sol / Presque dans le linceul,  / On se relève et on s'en sort seul</p>
                <p>Viser très haut, très haut / Il y'a une place pour nos idées et nos idéaux / (déohohoho) / Laisser une trace, recommencer / Je suis prêt à perdre le sens / Esquiver l'évidence / Pour enfin trouver ma voie / Viser très haut, très haut...</p>
                <p>Bien sur, bien sûr / On avance, on rature / Entre cris et murmures / On écrit nos blessures / Et la chaleur nous manque / Et le bonheur se planque / Comment trouver les mots / Quand on tombe, tombe de haut OH ! / Tout le long de la route / À l'amour on se shoote / Pour soigner la névrose / De nos cœurs qui explosent / La mort nous ensorcelle / L'amour est juste au seuil / On s’relève et on s'en sort seul</p>
                <p>Viser très haut, très haut / Il y'a une place pour nos idées et nos idéaux / (déohohoho) / Laisser une trace, recommencer / Je suis prêt à perdre le sens / Esquiver l'évidence / Pour enfin trouver ma voie / Viser très haut, très haut...</p>
                <p>On poursuivra cette course folle / Et jusqu’à s’égarer, être seuls et se marrer / Bruler nos camisoles / Se fabriquer un destin plus grand / Pour tenir a deux dedans</p>
                <p>Viser très haut, très haut / Il y'a une place pour nos idées et nos idéaux / (déohohoho) / Laisser une trace, recommencer / Je suis prêt à perdre le sens / Esquiver l'évidence / Pour enfin trouver ma voie / Viser très haut, très haut...</p>
                <p>Viser très haut, très haut / Il y'a une place pour nos idées et nos idéaux / (déohohoho) / Laisser une trace, recommencer / Je suis prêt à perdre le sens / Esquiver l'évidence / Pour enfin trouver ma voie…</p>
              `,
              credits: `
                Auteur: Nazim Khaled<br />
                Compositeurs: Nazim Khaled – Amir Haddad – John Mamann – Silvio Lisbonne - Skalpovich<br />
                Editeurs : Bukowski publishing - Sash Productions - Tick Tone Music - Universal Music Publishing - Remark - Universkalp 
              `
      }, {
        title: "Je reviendrai",
        slug: "je-reviendrai",
        album: "au-coeur-de-moi-standard",
        lyric: `
                <p>Si mes rêves sont ailleurs / Je suis prêt à m'en aller / Pour aller chercher ce destin qui me ressemble / Je n'ai jamais eu peur / De changer, de voyager / Cent fois j'ai traversé des routes, / Des ponts qui tremblent</p>
                <p>J'ai beau parcourir le monde / Tous mes chemins mènent à toi / Il y'a mille étoiles mais au-dessus un seul ciel / J'ai beau parcourir le monde / Mais mon seul pays c'est toi / Même loin de tes yeux je serai toujours fidèle</p>
                <p>Je reviendrai / Comme on plonge / Enfin dans le bain de la vie / Je te vois-même / Dans mes songes / Comme le jour cherche la nuit / Je trouverai / Enfin tes bras</p>
                <p>Ouh oh oh / Ouh oh oh / Ouh oh oh</p>
                <p>Je suis allé si loin / Pour trouver la liberté / Là où ne vivent que le chagrin et le silence / Sans peur du lendemain / J'ai reçu comme j'ai donné / J'ai perdu souvent / Mais j'ai retenté ma chance</p>
                <p>J'ai beau parcourir le monde / Je ne vois qu'un seul visage / Il y'a mille étoiles et pourtant je ne vois qu'elle / J'ai beau parcourir le monde / Elle est mon seul paysage / Une voix qui s'élève et vient chanter l'essentiel</p>
                <p>Je reviendrai / Comme on plonge / Enfin dans le bain de la vie / Je te vois-même / Dans mes songes / Comme le jour cherche la nuit / Je trouverai / Enfin tes bras</p>
                <p>Ouh oh oh / Ouh oh oh / Ouh oh oh</p>
                <p>Je prendrai tous les tournants,  / Tous les tourments / Pour te revenir / Et sans attendre mon tour / Je prendrai tous les détours / Puisqu'il y'a l'amour à bâtir / 
                <p>Je reviendrai / Comme on plonge / Enfin dans le bain de la vie / Je te vois-même / Dans mes songes / Comme le jour cherche la nuit / Je trouverai / Enfin tes bras</p>
                <p>Je reviendrai / Comme on plonge / Enfin dans le bain de la vie / Je te vois-même / Dans mes songes / Comme le jour cherche la nuit</p>
                <p>Ouh oh oh oh oh ouh oh oh oh / Ouh oh oh oh oh ouh oh oh oh / Ouh oh oh oh oh ouh oh oh oh / Ouh oh oh oh oh ouh oh oh oh</p>
                <p>Comme le jour cherche la nuit / Je trouverai / Enfin tes bras</p>
                <p>Ouh oh oh / Ouh oh oh / Ouh oh oh</p>
              `,
              credits: `
                Auteur: Nazim Khaled<br />
                Compositeurs : Nazim Khaled – Amir Haddad<br />
                Editeurs : Bukowski publishing - Sash Productions - Rachid Mir
              `
      }, {
        title: "Lost",
        slug: "lost",
        album: "au-coeur-de-moi-standard",
        lyric: `
                <p>J’suis lost / J’suis lost / On est dans l’ile des paradoxes / Et je me donne de toutes mes forces / J’suis lost</p> 
                <p>Si tu savais, si tu savais / Tout c’que j’aimerais te donner / Pour te faire rire, te faire rêver</p> 
                <p>Si tu savais, si tu voyais / Ces choses que je suis prêt à faire / Pour éclairer ton univers</p> 
                <p>Mais je me trompe si souvent / Je me trompe et je n’fais pas / C’que tu attends</p> 
                <p>Mais qu’est-ce que tu attends ? / J’suis lost / J’suis lost / On est dans l’ile des paradoxes / Et je me donne de toutes mes forces / Mais j’suis lost / J’suis lost / J’veux t’aimer grand, j’veux t’aimer fort / On se rend fou, on se renforce / J’suis lost</p> 
                <p>Je cherche ici, je cherche encore / Les endroits où tu veux aller / Les attentions pour te combler</p> 
                <p>Je cherche ici, je cherche encore / Quelles sont les poudres et les colliers / Les quelques mots improvisés</p> 
                <p>Mais je tombe tellement à coté / Je tombe de ne pas deviner / Tes étrangetés / Si belles étrangetés</p> 
                <p>Lost / J’suis lost / On est dans l’ile des paradoxes / Et je me donne de toutes mes forces / J’suis lost / J’suis lost / J’veux t’aimer grand, j’veux t’aimer fort / On se rend fou, on se renforce / J’suis lost</p> 
                <p>Et puis qu’importe / Et puis qu’importe / Toutes ces fois où l’on se désarme / Et puis qu’importe / Et puis qu’importe / C’est peut-être ça qui fait le charme</p> 
                <p>J’suis lost / J’suis lost</p> 
                <p>Lost / J’suis lost / On est dans l’ile des paradoxes / Et je me donne de toutes mes forces / J’suis lost / J’suis lost / J’veux t’aimer grand, j’veux t’aimer fort / On se rend fou, on se renforce / J’suis lost</p>
              `,
              credits: `
                Auteur: François Welgryn<br />
                Compositeurs : Amir Haddad – Benoit Leclerq – Julien Loris<br />
                Editeurs : Sash Productions - BMG - Intense - ARE Music
              `
      }, {
        title: "Oasis",
        ytId: "aMiasQVZrH4",
        slug: "oasis",
        album: "au-coeur-de-moi-standard",
        lyric: `
                <p>Comme un soleil incandescent / Comme un astre qu’on descend / Seule et sublime / Je t’imagine / Danse, tu bouleverses tous mes sens / Toi ma divine évidence / Le jour décline / Et déjà je te devine</p>
                <p>Et lentement, lentement / Comme poussé par un vent brûlant / Jusqu’à toi j’ai navigué / Trop longtemps, trop longtemps / J'ai attendu cet instant / Ca y est, je t’ai trouvé</p>
                <p>Comme un oasis / Dans le désert de mes désirs / Comme un oasis / La plus belle source de plaisir / C’est toi, mon horizon / Mon phare / Toi mon oasis</p>
                <p>Comme deux saisons qui se mélangent / En moi j’ai le diable et l’ange / Je te dessine / Quand ma peau est orpheline / Transe, d’un plaisir en abondance / Tu es l’amour par essence / Mon héroïne / Et je m’incline</p>
                <p>Et lentement, lentement / Comme poussé par un vent brûlant / Jusqu’à toi j’ai navigué / Trop longtemps, trop longtemps / J'ai attendu cet instant / Ca y est, je t’ai trouvé</p>
                <p>Comme un oasis / Dans le désert de mes désirs / Comme un oasis / La plus belle source de plaisir / C’est toi, mon horizon / Mon phare / Toi mon oasis</p>
                <p>Dans une mer de sable / Toi l’insaisissable / Ouh oh oh / Ouh ouh oh oh / Je vois ton visage / Mais n’es-tu qu’un mirage ?  / Ouh oh oh / Ouh ouh oh oh</p>
                <p>Comme un oasis… / Comme un oasis / Dans le désert de mes désirs / Comme un oasis / La plus belle source de plaisir / C’est toi, mon horizon / Mon phare / Toi mon oasis</p>
                <p>Toi mon oasis</p>
            `,
            credits: `
                Auteur: Nazim Khaled<br />
                Compositeurs: Amir Haddad – Nazim Khaled – Manon Romiti – Silvio Lisbonne<br />
                Editeurs : Universal Music publishing - Sash Productions - Tick Tone Music
            `
      }, {
        title: "Broken Heart",
        slug: "broken-heart",
        album: "au-coeur-de-moi-standard",
        lyric: `
                <p>Not before the day you said goodbye / Did I even know that I could cry / And how I have cried / All those reckless games inside my mind / Leaving this tortured soul to find / Somewhere to hide</p> 
                <p>When I told you that / I loved you / I believed you felt / That way too</p> 
                <p>Maybe I fell head over heels / Maybe I just got it all wrong / All "feeling hopeless" - not what I dreamed of / Maybe if you knew how it feels / Maybe you'd be singing this song / Where does my broken heart belong?</p> 
                <p>Days are long and nights are filled with fear / I'm falling apart cuz you're not here / And losing control / All that's left of who I used to be / You've taken it all away from me / I gave you my soul</p> 
                <p>Maybe I fell head over heels / Maybe I just got it all wrong / All "feeling hopeless" - not what I dreamed of / Maybe if you knew how it feels / Maybe you'd be singing this song / Where does my broken heart belong?</p> 
                <p>When I told you that / I loved you / I believed you felt / That way too</p> 
                <p>And I must find a new  / Tomorrow / If I am to lose / This sorrow</p> 
                <p>Maybe I fell head over heels / Maybe I just got it all wrong / All "feeling hopeless" - not what I dreamed of / Maybe if you knew how it feels / Maybe you'd be singing this song / Where does my broken heart belong?</p> 
                <p>Maybe I fell head over heels / Maybe I just got it all wrong / All "feeling hopeless" - not what I dreamed of / Maybe if you knew how it feels / Maybe you'd be singing this song / Where does my broken heart belong?</p> 
                <p>Maybe I fell head over heels / Maybe I just got it all wrong / All "feeling hopeless" - not what I dreamed of / Maybe if you knew how it feels / Maybe you'd be singing this song / Where does my broken heart belong?</p>
              `,
              credits: `
                Auteur: Donnie Demers aka Donald G Dermers<br />
                Compositeur: Benoit Poher<br />
                Editeurs : Musical wheels publishing - Sony-ATV Music Publishing (France) - Kyosphère Productions 
              `
      }, {
        title: "Il est temps qu'on m'aime",
        slug: "il-est-temps-qu-on-m-aime",
        album: "au-coeur-de-moi-standard",
        lyric: `
                <p>Chaque jour le chant du réveil / Chaque jour sa sirène m’appelle / Chaque jour je transperce la ville / Aux rayons du soleil / Comme une fourmi dans la foule / J’ai le tournis mais je roule / Chaque jour je traverse ma vie / Comme un homme qui se saoule</p>
                <p>Le coeur en dilettante / On me dit qu’il est temps / De gagner / Des trophées, des amantes / Il est l'heure d’avancer sans penser</p>
                <p>On me dit qu’il est temps / De chercher, de m’trouver / D’arrêter de rêver, de rentrer dans le rang / D’oublier,  / Non</p>
                <p>Oooooh il est temps qu’on m’aime / Oooooh il est temps qu’on m’aime</p>
                <p>Chaque soir j’essuie vos regards / Chaque soir je rentre plus tard / Et je transperce mes insomnies / A la lueur des phares / Chaque soir je sens dans ma chair / La lame du temps qui opère / Chaque soir je traverse mes nuits / En marin solitaire</p>
                <p>L’aventure qui me tente / On me dit qu’il temps de lâcher / Mes folies, mes attentes / Et mes envies latentes / Sont gâchées / Moi je sais qu’il est temps / De chercher, de m’trouver / D’arrêter de rester dans le rang / Et de vivre caché / Non</p>
                <p>Oooooh il est temps qu’on m’aime / Oooooh il est temps qu’on m’aime</p>
                <p>La vie défile, en histoire ou en idylle et tant qu’on s’aime,  / Rien n’est facile mais on a le coeur tranquille et tant qu’on s’aime / Et même si les gens disent que c’est inutile et tant qu’on s’aime / Tant qu’on s’aime…</p>
                <p>Oooooh il est temps qu’on m’aime / Oooooh il est temps qu’on m’aime</p>
                <p>Oooooh il est temps qu’on m’aime  / Oooooh il est temps qu’on m’aime</p>
              `,
              credits: `
                Auteurs : Amir Haddad – Nazim Khaled<br />
                Compositeurs: Amir Haddad – Nazim Khaled – Rachid Mir – Christian Dessart<br />
                Editeurs : Bukowski publishing - Sash Productions - Rachid Mir - Christian Dessart - Universal Music Publishing
              `
      },
    {
        title: "Yo busque",
        slug: "yo-busque",
        album: "au-coeur-de-moi-deluxe",
        lyric: `
            <p>Yo busqué sin respiro una ambición / ¿Por qué una motivación? / Busqué el sentido del amor, lo busqué / ¿Cuál es mi verdadero yo? / Si alguien escuchara la voz / Que se esconde en mi corazón </p>
            <p>Tú me has dado ganas de continuar / Tú me haces dejar de dudar / Tú venciste el miedo / Que en mis brazos no dormirán nunca más </p>
            <p>You / You're the one that' making me strong / I'll be looking, looking for you / Like the melody of my song / You </p>
            <p>Yo busqué un punto de inflexión / A mi mundo partido en dos</p>
            <p>Sin saber que hay un error / Yo lancé ambas botellas de licor / Fueron tantas tardes de ardor / En mis labios tengo el sabor </p>
            <p>Tú me has dado ganas de continuar / Tú me haces dejar de dudar / Tú venciste el miedo / Que en mis brazos no dormirán nunca más </p>
            <p>You / You're the one that' making me strong / I'll be looking, looking for you / Like the melody of my song / You </p>
            <p>You / You're the one that' making me strong / I'll be looking, looking for you / Like the melody of my song / You </p>
            <p>Más allá de tu dolor / Y de tu desilusión / Es cuando no te esperas tú / Que encontraras un paraíso azul / Oh, you, you, you, you </p>
            <p>You / You're the one that' making me strong / I'll be looking, looking for you / Like the melody of my song / You </p>
            <p>Tú me has dado ganas de continuar / Tú me haces dejar de dudar / Tú venciste el miedo / Que en mis brazos no dormirán nunca más </p>
            <p>You / You're the one that' making me strong / I'll be looking, looking for you / Like the melody of my song / You     </p>
        `,
        credits: `
            Auteurs : Amir Haddad – Nazim Khaled<br />
            Compositeurs: Amir Haddad – Nazim Khaled – Johan Errami<br />
            Adaptation : Amador Camuñas Moreno<br />
            Editeurs : Universal Music Publishing - Sash Productions - Tick Tone Music - Universkalp
        `
    }, {
        title: "Looking for You",
        slug: "looking-for-you",
        album: "au-coeur-de-moi-deluxe",
        lyric: `
            <p>You, you </p>
            <p>I looked up for a way out of the dark / Let go of my defensive heart / Started again without a scar </p>
            <p>Was looking 'cause you know I had paid the price / Even my heart was playing nice / No one to listen to those cries </p>
            <p>But now I'm apologising for the last time / I want you and I'm gonna keep trying / Nothing to regret now / Sorry, I'm not sorry, not anymore </p>
            <p>You / You're the one that's making me strong / I'll be looking, looking for you / Like the melody of my song </p>
            <p>You / You're the one that's making me strong / I'll be looking, looking for you / Like the melody of my song / I looked down to a point of no return / Nothing to lose, nothing to learn / All I could do was feel your burn </p>
            <p>I was hoping that you would be ready at last / To leave the worst and take the best / Sing me the verse, I'll do the rest </p>
            <p>'Cause now I'm apologising for the last time / I want you and I'm gonna keep trying / Nothing to regret now / Sorry, I'm not sorry, not anymore </p>
            <p>You / You're the one that's making me strong / I'll be looking, looking for you / Like the melody of my song </p>
            <p>You / You're the one that's making me strong / I'll be looking, looking for you / Like the melody of my song </p>
            <p>I've been carried to shore / Had my soul ready to soar / When I was lost I know it's true / That you were somewhere out there waiting too / Oh, you, you, you, you </p>
            <p>You / You're the one that's making me strong / I'll be looking, looking for you / Like the melody of my song / Like the melody of my song </p>
            <p>'Cause now I'm apologising for the last time / I want you and I'm gonna keep trying (You) / Nothing to regret now / Sorry, I'm not sorry, not anymore </p>
            <p>You / You're the one that's making me strong / I'll be looking, looking, looking for you / Like the melody of my song </p>
            <p>You</p>
        `,
        credits: `
            Auteurs : Amir Haddad – Nazim Khaled<br />
            Compositeurs: Amir Haddad – Nazim Khaled – Johan Errami<br />
            Adaptation : Tina Harris – Amir Haddad<br />
            Editeurs : Universal Music Publishing - Sash Productions - Tick Tone Music - Universkalp - Words & Trouble
        `
      }
]