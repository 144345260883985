import React from 'react'
import "./index.scss"

const AlbumIntro = ({ album, vertical }) => {
    return (
        <div className={`albumMainInfos ${vertical ? "vertical": ""}`}>
            <img src={album.pochette} alt="" />
            <div className="texts">
                <h2>{album.title}</h2>
                <p>{album.subtitle}</p>
            </div>
        </div>
    )
}

export default AlbumIntro