import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import AlbumIntro from '../../components/albumIntro'
import Footer from '../../components/footer'
import Header from '../../components/header'
import Title from '../../components/title'
import { albums } from '../../data/albums'
import { sendData } from '../../utils'
import { Helmet } from 'react-helmet';
import LogoAmir from '../../assets/img/mainLogo.png'
import './index.scss'

const Album = ({ setIsOpenMenu }) => {
    const { id } = useParams()
    const [album, setAlbum] = useState(albums.find(album => album.slug === id))

    useEffect(() => {
        setAlbum(albums.find(album => album.slug === id))
    }, [id])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        window.reloadOTBanner()
    }, [])

    useEffect(() => {
        if (album) {
            const objectDigitalData = {				
                settings:{			
                    reportSuites:"wmg,wmgfr"		
                },			
                page:{			
                    pageInfo:{		
                        pageName:`Amir:Music:${album.title}`,	
                        server:"Amir:Site",	
                        platform:"Static HTML",	
                        devTeam:"Warner Music France"	
                    },		
                    category:{		
                        primaryCategory:"Amir:Music",	
                        pageType:"music:album details"	
                    }		
                },			
                content:{			
                    artist:"Amir",		
                    label:"Warner Music International",		
                    sublabel:"Warner Music France"		
                }			
            }			
            sendData(objectDigitalData)
        }
    }, [album])

    return (
        <main className="album">
            <Helmet>
                <title>Amir-Paroles | {album?.title}</title>
            </Helmet>
                
            <Header setIsOpenMenu={setIsOpenMenu} backUrl={"/"} />
            <Title text={`<img src='${LogoAmir}' alt='' /> <span>paroles</span>`} />
            <div className="content">
                <>
                    {album && <AlbumIntro album={album} />}
                    <div className="tracklist">
                        <ul>
                            {
                                album?.tracklist?.map((track, index) => (
                                    <Link custom-link-name={`${track.title} Track`} key={track.slug} to={"/track/"+track.slug}>
                                        <li className={index < album.tracklist.length/2 ? "right" : "left"} dangerouslySetInnerHTML={{ __html: track.title }} />
                                    </Link>
                                ))
                            }
                        </ul>
                    </div>
                </>
            </div>
            <Footer />
        </main>
    )
}

export default Album