import React from 'react'
import { Link } from 'react-router-dom'
import backIcon from '../../assets/icons/back.png'
import listIcon from '../../assets/icons/list.png'
import './index.scss'

const Header = ({ backUrl, setIsOpenMenu }) => {
    return (
        <header className={`${backUrl ? "full": ""}`}>
            {
                backUrl && (
                    <Link to={backUrl}>
                        <img src={backIcon} alt="Revenir en arrière" />
                    </Link>
                )
            }
            <img onClick={() => setIsOpenMenu(true)} className="toggleMenu" src={listIcon} alt="Ouvrir le menu" />
        </header>
    )
}

export default Header