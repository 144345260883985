import { useEffect, useState } from "react";
import "./index.scss";

const FormWall = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (typeof window.WMGConnect !== "undefined") {
      var connect_button = new window.WMGConnect("", {
        opt_in_form_target: "#formulaire",
        opt_in_thank_you_enabled: false,
      });

      connect_button.setCallback(function (connect_data) {
        setIsOpen(false);

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });

        window.localStorage.setItem("isset", true);
      });
    }

    setTimeout(() => {
      if (window.localStorage.getItem("isset") !== "true") {
        setIsOpen(true);
      }
    }, 30000);
  }, []);

  return (
    <div className={`formWallContainer ${isOpen ? "active" : ""}`}>
      <button className="close" onClick={() => setIsOpen(false)}>
        x
      </button>
      <p className="souhaite">
        Tu souhaite suivre l'actualité d'Amir&nbsp;?
        <br />
        Inscris-toi à la newsletter ci-dessous&nbsp;!
      </p>
      <div id="formulaire"></div>
    </div>
  );
};

export default FormWall;
